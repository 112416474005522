import { Route, Routes } from "react-router-dom";
import { ProtectedRouteComponent } from "./protected_routes";
import { isAuthenticated, isGuest } from "../conditions";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import Logout from "../components/auth/Logout";
import Dashboard from "../components/account/Dashboard";
import { BlankAuthCenterCanvas } from "../components/global/graphical/BlankAuthCenterCanvas";
import { VPSReportContainer } from "../components/general/features/vps/report/Container";
import ProfileView from "../components/account/ProfileView";
import VerifyCode from "../components/auth/VerifyCode";

export class GlobalRoutes {
  static get pathDefinitions() {
    return {
      account: {
        login: "/",
        login2: "/login",
        register: "/register",
        verifyCode: "/verify_code",
        forgotPassword: "/forgot_password",
        resetPassword: "/reset_password",
        logout: "/logout",
        dashboard: "/account/dashboard",
        profileSettings: "/account/profile",
      },
      feature: {
        vps: {
          viewReport: "/vps/report/:id",
        },
      },
      misc: {
        noSubscription: "/no-subscription",
        accountIncomplete: "/account-incomplete",
        accountNoProject: "/account-no-project",
      },
    };
  }

  static Mapping() {
    return (
      <Routes>
        <Route
          path={GlobalRoutes.pathDefinitions.account.login}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<Login />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.login2}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<Login />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.register}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<Register />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.forgotPassword}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<ForgotPassword />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.verifyCode}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<VerifyCode />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.resetPassword}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.dashboard]: isGuest,
              }}
              elementToRenderOnPass={<ResetPassword />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.logout}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
              }}
              elementToRenderOnPass={<Logout />}
              profileRequired={false}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.account.dashboard}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
              }}
              elementToRenderOnPass={<Dashboard />}
            />
          }
        />

        <Route
          path={GlobalRoutes.pathDefinitions.misc.noSubscription}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
              }}
              elementToRenderOnPass={
                <BlankAuthCenterCanvas pageTitle={"No Subscription"}>
                  You need an active subscription to access page content
                </BlankAuthCenterCanvas>
              }
            />
          }
        />
        <Route
          path={GlobalRoutes.pathDefinitions.misc.accountIncomplete}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
              }}
              elementToRenderOnPass={
                <BlankAuthCenterCanvas pageTitle={"Incomplete Profile"}>
                  You have to complete your profile to access page content
                </BlankAuthCenterCanvas>
              }
            />
          }
        />
        <Route
          path={GlobalRoutes.pathDefinitions.misc.accountNoProject}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
              }}
              elementToRenderOnPass={
                <BlankAuthCenterCanvas pageTitle={"No Project"}>
                  You have no project created on your account
                </BlankAuthCenterCanvas>
              }
            />
          }
        />
        <Route
          path={GlobalRoutes.pathDefinitions.feature.vps.viewReport}
          element={<VPSReportContainer />}
        />
        <Route
          path={GlobalRoutes.pathDefinitions.account.profileSettings}
          element={<ProfileView />}
        />
        {/*<Route path="/analytics/user/:id/events/report" */}
        {/*       element={<LivestreamNotificationReportContainer/>}*/}
        {/*/>*/}
      </Routes>
    );
  }
}
