import "../../../../styles/camera.css";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { GlobalUserProfileContext } from "../../../../App";
import { APPProductionEnvironmentType } from "../../../../config/enums/system";
import APIEndpoints from "../../../../config/api/endpoints";
import { APIUtils } from "../../../../helpers/utils/api";

import { FrameDimensionContainer } from "./FrameDimensionContainer";
import { CameraStreamGridContainer } from "./CameraStreamsGridContainer";
import { GeneralLiveStreamPaginationContainer } from "./PaginationContainer";
import styled from "styled-components";
import { AppConfig } from "../../../../config";

import Spinner from "../../../Spinner";
import { MainWrapper } from "../../../MainWrapper";
import { useDispatch, useSelector } from "react-redux";
import { projectsList } from "../../../../config/actions";
import { FilterContainer } from "../../../global/filters/Container";
import axiosInstance from "../../../../config/axios";

const StyledWrapper = styled.div`
  .fullscreen-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 !important;
    margin: 0 !important;
    z-index: 1000;
    background-color: rgb(247, 248, 250);

    .tab-content-header {
      background-color: rgb(247, 248, 250);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      :hover {
        opacity: 1;
      }
    }

    .stream-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      .stream-grid-wrapper {
        height: 100vh;
        grid-gap: 0.1rem !important;

        .grid-item {
          border-radius: 0.5rem !important;

          .descriptionWrapper {
            display: none;
          }
        }
      }
    }

    .pagination-wrapper {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: rgb(247, 248, 250);
      align-self: flex-end;
      width: initial;
      border-radius: 0.5rem;
      opacity: 0;

      :hover {
        opacity: 1;
      }
    }
  }

  #livestreams-wrapper {
    position: relative;
  }
  .liveStreamVideosSection {
    min-width: 100%;
  }

  .liveStreamNotificationBox {
    width: 250px;
    transition: left all 2s linear;
    position: absolute;
    min-height: -webkit-fill-available;
    min-width: 250px;
    background-color: #fff;
    border-radius: 15px;
    /* z-index: 2; */
    .notifyBtn {
      transition: all 2s linear;
      position: absolute;
      background-color: #254478;
      writing-mode: vertical-rl;
      padding: 0.8rem 0.2rem;
      left: -11%;
      top: 0;
      bottom: 0;
      margin: auto 0;
      cursor: pointer;
      height: 100%;
      max-height: fit-content;
      border-radius: 1rem 0 0 1rem;
      border: 1px solid #254478;
      border-right: 0;
      color: #fff;
    }
  }
`;

export const GlobalLiveStreamContext = createContext({});

const GlobalLiveStreamContainer = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const streamIdsRef = useRef(new Set());
  const { projectsList: projects } = useSelector(
    (state) => state.projectsListReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    profile && dispatch(projectsList(profile.id));
  }, [profile]);

  const { theme } = useSelector((state) => state.themeReducer);
  const [streams, setStreams] = useState({});
  const [selectedFrameDimension, setSelectedFrameDimension] = useState(null);
  const [paginationLevel, setPaginationLevel] = useState(1);
  const [isPlayingSlideShow, setIsPlayingSlideShow] = useState(false);
  const [hasNextSlideShow, setHasNextSlideShow] = useState(false);
  const [hasPrevSlideShow, setHasPrevSlideShow] = useState(false);

  const [filterData, setFilterData] = useState({});

  const [slideShowIntervalSecs, setSlideShowIntervalSecs] = useState(5);

  const [isInFullScreenMode, setIsInFullScreenMode] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);

  useEffect(() => {
    const getStreamEndpoint = () => {
      const params = {
        owner:
          filterData?.SubUser?.length >= 1
            ? `${filterData?.SubUser?.map((item) => item.label).join(",")}`
            : profile?.id,
        ordering: "project",
      };

      if (filterData?.Site?.length >= 1) {
        params.projects = `${filterData?.Site?.map((item) => item.label).join(
          ","
        )}`;
      }

      if (filterData?.Camera?.length >= 1) {
        params.streams = `${filterData?.Camera?.map((item) => item.label).join(
          ","
        )}`;
      }

      if (
        AppConfig.APP_PRODUCTION_ENVIRONMENT ===
        APPProductionEnvironmentType.WEB
      ) {
        return APIEndpoints.instance.stream.main.getAllInternetAvailable(
          params
        );
      } else {
        return APIEndpoints.instance.stream.main.getAll(params);
      }
    };

    if (profile) {
      APIUtils.fetchItemData({
        endpoint: getStreamEndpoint(),
        item: streams,
        setItem: setStreams,
      });
    }
  }, [profile, filterData]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", (e) => {
      setIsInFullScreenMode(!isInFullScreenMode);
    });
  }, [isInFullScreenMode]);

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  useEffect(() => {
    const clearHlsSessions = async () => {
      // Create an array of promises to clear each session

      Array.from(streamIdsRef.current)?.map(async (streamId) => {
        const endpoint =
          APIEndpoints.instance.stream.main.clearHlsUrl(streamId);
        (await axiosInstance())
          .post(endpoint)
          .then((response) => {
            console.log("live session cleared");
          })
          .catch((err) => {
            console.log("session cleared error");
          });
      });
    };
    return () => {
      clearHlsSessions(); // Clear HLS sessions when component unmounts
    };
  }, []);

  return (
    <GlobalLiveStreamContext.Provider
      value={{
        setSelectedFrameDimension,
        selectedFrameDimension,
        streams: streams,
        paginationLevel,
        setPaginationLevel,
        isPlayingSlideShow,
        setIsPlayingSlideShow,
        setHasNextSlideShow,
        setHasPrevSlideShow,
        hasNextSlideShow,
        hasPrevSlideShow,
        slideShowIntervalSecs,
        setSlideShowIntervalSecs,
        streamIdsRef,
      }}
    >
      <MainWrapper
        parent='livestream'
        isFetching={isFetching}
        profile={profile}
        pageTitle='LiveStream'
      >
        <StyledWrapper>
          <div id='body-camera'>
            <div className='main-frame-camera'>
              <div
                id='livestreams-wrapper'
                onClick={() => {
                  setNotifyOpen(false);
                }}
                className={`tab-content h-100 py-1 px-3 ${
                  isInFullScreenMode && "fullscreen-wrapper"
                }`}
              >
                <div
                  className={`mt-2 tab-content-header ${
                    theme === "dark" &&
                    "tab-content-header-dark-text tab-content-header-dark-bg"
                  } d-flex justify-content-between`}
                >
                  <div className='filters-content'>
                    <FilterContainer
                      componentName={"FilterByUserSiteCamera"}
                      setterCallback={handleFilterData}
                      liveSearch={true}
                    />
                    <div className='search-add'>
                      {streams?.isFetching !== null &&
                      !streams?.isFetching &&
                      streams?.data?.length ? (
                        <FrameDimensionContainer />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-flex stream-wrapper mt-4 flex-grow-1'>
                  <div className='liveStreamVideosSection'>
                    {!streams?.isFetching ? (
                      streams?.data?.length ? (
                        selectedFrameDimension && (
                          <CameraStreamGridContainer projects={projects} />
                        )
                      ) : (
                        "You currently have no camera"
                      )
                    ) : (
                      <Spinner size={1.5} />
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-end w-100 px-3 my-2 gap-5 align-items-center pagination-wrapper'>
                  {!streams?.isFetching &&
                    selectedFrameDimension &&
                    streams?.data?.length && (
                      <GeneralLiveStreamPaginationContainer />
                    )}
                  {!isInFullScreenMode && (
                    <div
                      className={`add-camera_btn add-camera_btn_full_scr ${
                        theme === "dark" && "add-camera_btn-dark"
                      }`}
                      onClick={() => {
                        const element = document.getElementById(
                          "livestreams-wrapper"
                        );
                        if (
                          !document.fullscreenElement &&
                          !isInFullScreenMode
                        ) {
                          element
                            .requestFullscreen()
                            .catch((err) => {
                              alert(
                                `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                              );
                            })
                            .then(() => {
                              setIsInFullScreenMode(isInFullScreenMode);
                            });
                        } else {
                          document.exitFullscreen();
                        }
                      }}
                    >
                      {"Fullscreen View"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </StyledWrapper>
      </MainWrapper>
    </GlobalLiveStreamContext.Provider>
  );
};

export default GlobalLiveStreamContainer;
