import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import axiosInstance from '../../../../../../config/axios'
import displayError from '../../../../../../helpers/displayError'
import moment from 'moment'
import { SizedBox } from '../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../Spinner'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    padding: 2rem;
    font-family: 'Poppins';
    color: ${props => (props.theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 1)')};
    .rectification_header {
        text-align: center;
        h3 {
            margin-top: 0;
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
        }
    }
    .rectification_content {
        display: flex;
        flex-direction: column;
        ${'' /* align-items: center; */}
        height: 50vh;
        min-height: 600px;
        overflow-y: scroll;
        li {
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid
                ${props =>
                    props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : '#ccc'};

            .reportNameArea,
            .dateArea {
                h6 {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 700;
                }
                p {
                    font-weight: 100;
                    font-size: 12px;
                    line-height: 24px;
                    font-family: 'Poppins';
                }
            }

            .add-camera_btn {
                font-family: 'Poppins';
                text-transform: none;
                text-align: center;
                height: max-content;
                width: 100px;
            }
        }
    }
`
export const GenerateReportModalContainer = ({ callBack, callbackCancel }) => {
    const [isFetchingVPSReport, setIsFetchingVPSReport] = useState(false)
    const [vpsReportList, setVPSReportList] = useState([])
    const nextReportEndpoint = useRef(null)
    const navigate = useNavigate()
    const { theme } = useSelector(state => state.themeReducer)
    const fetchReportList = useCallback(
        async (queryParams = '', next = false) => {
            let endpoint
            if (next) {
                endpoint = nextReportEndpoint.current
            } else {
                endpoint = `/instance/vps/report/${queryParams}`
            }
            setIsFetchingVPSReport(true)
            await (
                await axiosInstance()
            )
                .get(endpoint)
                .then(response => {
                    if (next) {
                        setVPSReportList([...vpsReportList, ...response.data])
                    } else {
                        setVPSReportList(response.data)
                    }
                    nextReportEndpoint.current = response.extraData?.next
                })
                .catch(err => {
                    displayError(
                        'Error in fetching available vps reports',
                        theme
                    )
                })
            setIsFetchingVPSReport(false)
        },
        [isFetchingVPSReport, vpsReportList]
    )

    useEffect(() => {
        fetchReportList('?is_completed=true&ordering=-date_last_modified')
    }, [])

    return (
        <Wrapper theme={theme}>
            <div className='rectification_header'>
                <h3>Reports</h3>
            </div>

            <ul
                className='rectification_content'
                onScroll={async e => {
                    if (
                        e.target.scrollTop +
                            e.target.getBoundingClientRect().height >=
                        e.target.scrollHeight - 10
                    ) {
                        if (
                            !isFetchingVPSReport &&
                            nextReportEndpoint.current
                        ) {
                            fetchReportList(
                                '?is_completed=true&ordering=-date_last_modified',
                                true
                            )
                        }
                    }
                }}
            >
                {vpsReportList.map((item, i) => {
                    return (
                        <li key={i}>
                            <div className='reportNameArea'>
                                <h6>Report Name :</h6>
                                <p>{item.name || 'Unnamed'}</p>
                            </div>
                            <div className='dateArea'>
                                <h6>Date :</h6>
                                <p>
                                    {moment(item?.date_added).format(
                                        'DD-MM-YYYY'
                                    )}
                                </p>
                            </div>
                            <Link
                                className={`orange-text add-camera_btn ${
                                    theme === 'dark' && 'add-camera_btn-dark'
                                }`}
                                to='/'
                                onClick={e => {
                                    e.preventDefault()
                                    navigate(`/vps/report/${item?.id}`)
                                }}
                            >
                                View
                            </Link>
                        </li>
                    )
                })}
                {isFetchingVPSReport && (
                    <div className='spinnerWrapper'>
                        <SizedBox height={3} />
                        <Spinner size={1.5} />
                    </div>
                )}
            </ul>
        </Wrapper>
    )
}
