import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoElement = ({ liveHLS, reload }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Utility function to detect Safari browser
    const isSafari = () => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    // Initialize the video.js player
    if (videoRef.current) {
      if (isSafari()) {
        // If Safari, use the native player
        videoRef.current.src = liveHLS;
      } else {
        playerRef.current = videojs(videoRef.current, {
          autoplay: true,
          controls: true,
          muted: true,
          sources: [
            {
              src: liveHLS,
              type: "application/x-mpegURL",
            },
          ],
          html5: {
            hls: {
              debug: false,
              maxBufferLength: 30,
              maxMaxBufferLength: 60,
              maxBufferSize: 60 * 1000 * 1000,
              maxBufferHole: 0.5,
              liveSyncDurationCount: 3,
              fragLoadingTimeOut: 20000,
              fragLoadingMaxRetry: 6,
              fragLoadingRetryDelay: 2000,
              startLevel: -1,
              initialLiveManifestSize: 3,
              liveSyncDuration: 10, // Sync live stream to be 10 seconds behind live edge
            },
          },
        });
      }
      // Clean up the video.js player on component unmount
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [liveHLS]);

  return !liveHLS ? (
    <div
      className='d-flex flex-column gap-3 align-items-center justify-content-center w-100 h-100'
      style={{ color: "white" }}
    >
      Error in playing stream
      <i
        className='fas fa-redo'
        onClick={() => reload()}
        style={{ fontSize: "1.5rem" }}
      ></i>
    </div>
  ) : (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className='video-js vjs-default-skin'
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};
