import cryptoRandomString from "crypto-random-string";
import {AppConfig} from "../../../../config";
import CryptoJS from 'crypto-js'
import {Buffer} from "buffer";

export class CryptoAESCBCHelperUtils {
    static encrypt(plainText, key, iv) {
        iv = iv || cryptoRandomString({length: AppConfig.CRYPTO_AES_IV_LENGTH}).toUpperCase()
        let encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC
        });
        return {
            cipherText: encrypted.toString(),
            iv: Buffer.from(iv, "utf8").toString("base64")
        }

    }


    static decrypt(cipherText, key, iv) {
        let decrypted = CryptoJS.AES.decrypt(cipherText, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(Buffer.from(iv, "base64").toString("utf8")),
            mode: CryptoJS.mode.CBC
        });
        return {
            plainText: decrypted.toString(CryptoJS.enc.Utf8)
        }

    }
}