import {Strings} from "../../../common/strings";
import {ResponseStatusCode} from "../../../../../config/enums/api";

export class ServerErrorResponseHelperParser {
    _nonAllowedKeys = ['detail', 'message']

    constructor(statusCode, data) {
        this.data = data
        this.statusCode = statusCode
    }

    _parsedData = []

    get parsedData() {
        if (this.data) {
            this._parseObjectValue(this.data)
            if (this._parsedData) {
                return this._parsedData
            }
        }
        switch (this.statusCode) {
            case ResponseStatusCode.BAD_REQUEST:
                return [{
                    "message": "An error was found in your request payload, kindly check your request and try again; contact support if issue persists"
                }]
            case ResponseStatusCode.FORBIDDEN:
                return [{
                    "message": "You have no authorization/permission to access the resource attempted"
                }]
            case ResponseStatusCode.UNAUTHORIZED:
                return [{
                    "message": "You have no authentication to access the resource attempted"
                }]
            default:
                return [{
                    "message": "An unknown error was encountered while processing your, kindly try again; contact support if issue persists"
                }]

        }
    }


    _parseObjectValue(data, key = null) {
        if (typeof data !== "object") {
            const messageData = {
                message: data
            }
            if (key && !this._nonAllowedKeys.includes(key)) {
                messageData['title'] = Strings.convertSnakeCaseToTitleCase(key)
            }
            this._parsedData.push(messageData)
        } else {
            let data_ = Array.isArray(data) ? data : [data]
            for (let item of data_) {
                if (typeof item === "object") {
                    this._parseObject(item, (key && !this._nonAllowedKeys.includes(key)) && key)
                } else {
                    this._parseObjectValue(item, key)
                }
            }

        }
    }

    _parseObject(data, parentKey = null) {
        for (let name of Object.getOwnPropertyNames(data)) {
            this._parseObjectValue(data[name], parentKey ? `${parentKey}_-_${name}` : name)
        }
    }
}