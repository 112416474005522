import { APIEndpointsHelper } from '../../../..'

export class APIEndpointInstanceStreamAnalyticsEventNotification {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/notifications/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/notifications/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/notifications/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/notifications/${id}/`
    }

    static batchDestroy(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/notifications/batch_destroy/`,
            params
        )
    }
}

export class APIEndpointInstanceStreamAnalyticsEventNotificationAsGuest {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/notifications/guest/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/notifications/${id}/guest/`
    }
}
