import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { toggleTheme } from '../../../config/actions'
import DarkTheme from '../../svgTojsx/DarkTheme'
import LightTheme from '../../svgTojsx/LightTheme'

export const ThemeComponent = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const dispatch = useDispatch()

    const handleToggle = () => {
        const themeVal = theme === 'light' ? 'dark' : 'light'
        dispatch(toggleTheme(themeVal))
        localStorage.setItem('theme', themeVal) // Update local storage
    }

    return (
        <StyledComponent>
            <div className='light-dark-mode' onClick={handleToggle}>
                {theme === 'light' && <LightTheme />}
                {theme === 'dark' && <DarkTheme />}
            </div>
        </StyledComponent>
    )
}

const StyledComponent = styled.div`
    .light-dark-mode {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        background: transparent;
        border-radius: 0.375rem;
        cursor: pointer;
        :hover {
            background: rgb(208, 208, 209);
        }
    }
`
