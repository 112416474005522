import Modal from 'react-modal'
import { useSelector } from 'react-redux'

const customStyles = theme => {
    return {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '40%',
            position: 'relative',
            // maxWidth: 'fit-content',
            borderRadius: '27px',
            marginRight: '-50%',
            inset: '50% auto auto 50',
            // boxShadow: '0px 0px 30px -10px #bfbfbf',
            border: '0',
            transform: 'translate(-50%, -50%)',
            // background: 'var(--custom-primary-bg)',
            padding: '2rem 2rem',
            maxHeight: '70vh',
            overflowY: 'auto',
            backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : '#fff',
            color: theme === 'dark' ? 'white' : '#fff',
            zIndex: '2',
        },
    }
}

const customStyles2 = theme => {
    return {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '60%',
            position: 'relative',
            // maxWidth: 'fit-content',
            borderRadius: '27px',
            marginRight: '-50%',
            inset: '50% auto auto 50',
            // boxShadow: '0px 0px 30px -10px #bfbfbf',
            border: '0',
            transform: 'translate(-50%, -50%)',
            // background: 'var(--custom-primary-bg)',
            padding: '2rem 2rem',
            maxHeight: '60vh',
            overflowY: 'auto',
            backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : '#fff',
            color: theme === 'dark' ? 'white' : '#fff',
            zIndex: '2',
        },
    }
}

const customStylesUser = theme => {
    return {
        overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.4)',
        },
        content: {
            position: 'static',
            borderRadius: '27px',
            margin: '2rem',
            inset: '50% auto auto 50',
            // boxShadow: '0px 0px 30px -10px #bfbfbf',
            border: 'none',
            // background: 'var(--custom-primary-bg)',
            padding: '2rem 2rem',
            width: '100%',
            height: '90%',
            overflowY: 'auto',
            backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : '#fff',
            color: theme === 'dark' ? 'white' : '#fff',
            zIndex: '2',
        },
    }
}

Modal.setAppElement('#root')

export const ModalComponent = ({
    children,
    isOpen,
    callbackOnAfterOpen,
    callbackCloseModal,
    modalLabel = '',
    ClassName = '',
    customStyling = false,
    onOverlayClose = true,
    customStyling2 = false,
}) => {
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={callbackOnAfterOpen}
            onRequestClose={callbackCloseModal}
            style={
                !customStyling && !customStyling2
                    ? customStyles(theme)
                    : (customStyling && customStylesUser(theme)) ||
                      (customStyling2 && customStyles2(theme)) ||
                      customStyles(theme)
            }
            contentLabel={modalLabel}
            shouldCloseOnOverlayClick={onOverlayClose}
            className={ClassName}
        >
            {children}
        </Modal>
    )
}
