import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../../Spinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { VPSContext } from '../../../MainWrapper'
import { ImagePickerComponent } from '../../../../../../global/graphical/form_inputs/ProfilePicturePickerComponent'
import APIEndpoints from '../../../../../../../config/api/endpoints'
import { FileUploadPurpose } from '../../../../../../../config/enums/file'
import { handleFileUploadSingle } from '../../../../../../../helpers/utils/api/fileManagement/handler'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
        margin-top: 0;
        padding-bottom: 0.5rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 500;
        color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(37, 68, 120, 1)'};
    }

    input {
        border: 2px solid #cacaca !important;
        color: #000;
        text-indent: 0.5rem !important;
        height: 30px !important;
    }
    .add-camera_btn {
        padding: 0.4rem 1.2rem;
    }
`
export const ConfigureVPSLogoComponent = ({ theme }) => {
    const [isUpdatingVPSLogo, setIsUpdatingVPSLogo] = useState(false)
    const [logo, setLogo] = useState(null)
    const { VPSInstance, fetchVPSInstance } = useContext(VPSContext)

    useEffect(() => {
        setLogo(VPSInstance?.data?.logo?.file)
    }, [VPSInstance])

    const handleOnSubmit = useCallback(async () => {
        if (!logo || typeof logo === 'string') {
            displayError('Kindly upload an image', theme)
            return
        }
        setIsUpdatingVPSLogo(true)
        const fileUploadData = {
            to_be_deleted_file_id: VPSInstance?.data?.logo?.id,
            to_be_uploaded_buffer: logo,
            purpose: FileUploadPurpose.VPS_INSTANCE_LOGO,
        }

        const fileUploadResponse = await handleFileUploadSingle(fileUploadData)
        if (fileUploadResponse) {
            await (
                await axiosInstance()
            )
                .patch(
                    APIEndpoints.instance.vps.main.update(
                        VPSInstance?.data?.id
                    ),
                    {
                        logo: fileUploadResponse?.id,
                    }
                )
                .then(response => {
                    fetchVPSInstance()
                })
                .catch(err => {
                    displayError(err, theme)
                })
        }
        setIsUpdatingVPSLogo(false)
    }, [logo, VPSInstance, isUpdatingVPSLogo])

    return (
        <Wrapper theme={theme}>
            <h5>Add Logo</h5>
            <ImagePickerComponent
                image={logo}
                setImage={setLogo}
                inputUploadText='Upload Logo'
            />
            <SizedBox height={1} />
            {VPSInstance?.isFetching === true || isUpdatingVPSLogo ? (
                <Spinner size={1.5} />
            ) : (
                VPSInstance?.isFetching === false && (
                    <Link
                        className={`orange-text add-camera_btn ${
                            theme === 'dark' && 'add-camera_btn-dark'
                        }`}
                        to=''
                        onClick={e => {
                            e.preventDefault()
                            handleOnSubmit()
                        }}
                    >
                        Upload
                    </Link>
                )
            )}
        </Wrapper>
    )
}
