export class APIEndpointInstanceStreamAnalyticsVideoBackupInstance {
  static getForStream(id) {
    return `/instance/stream/analytic/video_backup/stream/${id}/instance/`;
  }

  static get(id) {
    return `/instance/stream/analytic/video_backup/instance/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/video_backup/instance/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/video_backup/instance/${id}/`;
  }
}
