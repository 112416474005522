import {APIEndpointsHelper} from "../../index";

export class APIEndpointInstanceVPSStreamSequence {
    static add() {
        return `/instance/vps/add_stream/`
    }

    static fetch(id) {
        return `/instance/vps/stream_sequence/${id}/`
    }

    static remove(id) {
        return `/instance/vps/stream_sequence/${id}/`
    }

    static fetchAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/stream_sequence/`, params)
    }


    static fetchAllInternetAvailable(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/stream_sequence/internet_available/`, params)
    }


    static fetchAllStreamsAvailable(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/streams_available_for_vps_sequence/`, params)
    }
}