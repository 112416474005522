import {APIEndpointsHelper} from "../../index";

export class APIEndpointInstanceVPSReportEntity {
    static fetchOrCreate() {
        return `/instance/vps/report/entity/fetch_or_create/`
    }

    static fetch(id) {
        return `/instance/vps/report/entity/${id}/`
    }

    static update(id) {
        return `/instance/vps/report/entity/${id}/`
    }

    static fetchAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/report/entity/`, params)
    }

    static fetchAllAsGuest(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/guest/report/entity/`, params)
    }
}