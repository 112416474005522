import { useCallback, useEffect, useState } from "react";
import APIEndpoints from "../../../../../config/api/endpoints";
import axiosInstance from "../../../../../config/axios";
import { VideoElement } from "../video_element/VideoElement";
import Spinner from "../../../../Spinner";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const StreamPlayerCanvasContainer = ({ stream }) => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [liveHLS, setLiveHLS] = useState(null);

  // Function to fetch the new HLS URL
  const getLiveHlsUrl = useCallback(async (streamId) => {
    const endpoint = APIEndpoints.instance.stream.main.getHlsUrl(streamId);
    const maxRetries = 3;
    let attempts = 0;

    const fetchUrl = async () => {
      try {
        setIsLoading(true);
        const response = await (await axiosInstance()).get(endpoint);
        if (response.data.url) {
          setLiveHLS(response.data.url);
          setIsLoading(false);
        }
      } catch (err) {
        attempts++;
        if (attempts < maxRetries) {
          fetchUrl();
        } else {
          setLiveHLS(null);
          setIsLoading(false);
        }
      }
    };

    if (streamId) {
      fetchUrl();
    } // Initial call to fetch the URL
  }, []);

  // Function to clear the HLS session
  const clearHlsSession = useCallback(async (streamId) => {
    const endpoint = APIEndpoints.instance.stream.main.clearHlsUrl(streamId);
    try {
      await (await axiosInstance()).post(endpoint);
      console.log("Live session cleared");
    } catch (err) {
      console.log("Session cleared error", err);
    }
  }, []);

  // Effect to handle the stream change and refetch the HLS URL
  useEffect(() => {
    setIsLoading(true);

    const handleStreamChange = async () => {
      if (liveHLS) {
        await clearHlsSession(stream?.id);
      }
      await getLiveHlsUrl(stream?.id);
    };

    handleStreamChange();

    // Set up timeout to refresh the video URL before it expires (e.g., after 58 minutes)
    const timeoutId = setTimeout(() => {
      getLiveHlsUrl(stream?.id);
    }, 58 * 60 * 1000); // 58 minutes

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [stream, clearHlsSession, getLiveHlsUrl]);

  // Clear HLS session on component unmount
  useEffect(() => {
    return () => {
      if (liveHLS) {
        clearHlsSession(stream?.id);
      }
    };
  }, [stream?.id, liveHLS, clearHlsSession]);

  return (
    <>
      {isLoading ? (
        <StyledWrapper theme={theme}>
          <div className='d-flex justify-content-center align-items-center w-100 h-100'>
            <Spinner size={1.5} color='white' />
          </div>
        </StyledWrapper>
      ) : (
        <StyledWrapper theme={theme}>
          <VideoElement
            liveHLS={liveHLS}
            reload={() => getLiveHlsUrl(stream?.id)}
          />
        </StyledWrapper>
      )}
    </>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;
