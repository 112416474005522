import React from 'react'

function StreamsIcon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 24 24'
        >
            <path
                stroke='#05A431'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                d='M6.25 6.25l11.5 11.5m0-11.5l-11.5 11.5m14.7-3.2l-6.39 6.39c-1.4 1.4-3.7 1.4-5.11 0l-6.39-6.39c-1.4-1.4-1.4-3.7 0-5.11l6.39-6.39c1.4-1.4 3.7-1.4 5.11 0l6.39 6.39c1.4 1.41 1.4 3.71 0 5.11z'
            ></path>
        </svg>
    )
}

export default StreamsIcon
