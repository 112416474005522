import { APIEndpointsHelper } from "../../../..";

export class APIEndpointInstanceStreamAnalyticsEventReport {
  static create(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/reports/email_report`,
      params
    );
  }

  static createNew() {
    return `/instance/stream/analytic/reports/`;
  }
  static getReports(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/reports/get_analytic_reports`,
      params
    );
  }

  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/reports/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/reports/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/reports/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/reports/${id}/`;
  }
}

export class APIEndpointInstanceStreamAnalyticsEventReportAsGuest {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/reports/guest/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/reports/guest/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/reports/guest/${id}/`;
  }
}
