import { useParams } from 'react-router-dom'
import { StreamAnalyticRoutingConfig } from '../../../../../../../routes/instance/stream/analytic'
import { BlankAuthCenterCanvas } from '../../../../../../global/graphical/BlankAuthCenterCanvas'

export const StreamAnalyticConfigEntryComponent = () => {
    const { analytic_type: streamAnalyticShortCode } = useParams()

    const AnalyticComponent =
        StreamAnalyticRoutingConfig.analyticComponentMapping[
            streamAnalyticShortCode
        ]

    return AnalyticComponent ? (
        <AnalyticComponent />
    ) : (
        <BlankAuthCenterCanvas pageTitle={'Non-Existing Page'}>
            The page you are trying to view does not exist
        </BlankAuthCenterCanvas>
    )
}
