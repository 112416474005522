import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import dummyImage from "../../../../../static/img/screenshot.png";
import moment from "moment";
import { SizedBox } from "../../../../../helpers/utils/SizedBox";
import { LivestreamNotificationContext } from "./Container";
import { notificationRemarkStateColorMapping } from "./config";
import { StreamAnalyticInstanceConfig } from "../../../../../config/instance/stream/analytics";
import { StreamAnalyticInstancesAvailableTypes } from "../../../../../config/enums/instance/stream/analytic";
import { ProjectColorScheme } from "../../../../../helpers/ParseProjectColors";
import { useSelector } from "react-redux";

const StyledComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  position: relative;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .closeBtn {
    position: absolute;
    right: -0.5rem;
    top: -1.8rem;
    font-size: 15px;
    cursor: pointer;
    color: rgba(152, 152, 152, 1);
  }
  .detailSection {
    width: 70%;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    background-color: ${({ decorator }) => (decorator && decorator) || "#000"};
    color: white;
    @media (max-width: 900px) {
      width: 100%;
    }
    .imagebox {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      max-height: 35vh;
      border-radius: 1.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        ${"" /* // object-fit: cover; */}
      }
    }
    .featureEnabled {
      display: flex;
      padding: 0.7rem;

      border-radius: 1.5rem;

      @media (max-width: 600px) {
        flex-direction: column;
      }
      .cameraName {
        width: 100%;
        max-width: fit-content;
        min-width: fit-content;
        border-right: 1px solid #fff;
        padding: 0 1rem 0 0;
        @media (max-width: 600px) {
          border-right: 0;
          border-bottom: 3px solid #000;
          margin: 0 auto;
          padding-bottom: 0.5rem;
          width: 100%;
          text-align: center;
        }
        h4 {
          font-size: 1rem;
          line-height: 1.2rem;
          margin: 0.3rem 0;
        }
        p {
          font-size: 0.9rem;
          line-height: 1rem;
          margin: 0;
        }
      }
      .featuresOuter {
        display: flex;
        flex-direction: column;

        padding: 0 1rem;
        .features {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .imgOuter {
          width: 1.5rem;
          height: 1.3rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .featureDetail {
          font-size: 0.9rem;
          line-height: 1.2rem;
          margin: 0;
        }
      }
    }
  }
  .detailSidebar {
    width: 30%;
    border-radius: 0.7rem;
    ${
      "" /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%),
            0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    .ShowDetails {
      margin: 0.9rem;
      background-color: ${({ decorator }) =>
        (decorator && decorator) || "#000"};
      padding: 0.8rem 1rem;
      border-radius: 27px;
      color: #fff;
      .title {
        font-size: 0.8rem;
        font-weight: 700;
        color: #fff;
        line-height: 1rem;
        letter-spacing: 0.3px;
        margin: 0;
      }
      .detailOuter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .discription {
          font-size: 0.7rem;
          color: #fff;
        }
        .time {
          color: #fff;
          font-size: 0.7rem;
        }
      }
    }
    .DetailList {
      margin: 0.9rem;
      h2 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.2rem;
        margin: 0.3rem 0;
        color: #000;
      }
      ul {
        list-style: none;
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        .listData {
          font-size: 0.8rem;
          display: flex;
          justify-content: space-between;
          padding: 0.2rem 0.5rem;
          align-items: center;
          color: ${(props) => (props.theme === "dark" ? "white" : "black")};
          h6 {
            font-weight: 600;
            font-size: 11px;
          }
        }
      }

      .remarkMainWrapper {
        .addRemarkBtn {
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        .descWrapper {
          .remarkDescHeaderWrapper {
            display: flex;
            justify-content: space-between;
          }

          .remarkTypeWrapper {
            padding: 5px 10px;
          }
        }
      }
    }
  }
`;

export const ViewDetailScreen = ({
  featureData_,
  decoration,
  callbackCancel,
  fetchSingleNotificationByID,
}) => {
  const [featureData, setFeatureData] = useState(featureData_);
  const viewData = featureData?.analytic_instance?.stream;
  const { setModalAddRemarkToNotificationState } = useContext(
    LivestreamNotificationContext
  );
  const { projectsList: projects } = useSelector(
    (state) => state.projectsListReducer
  );
  const { theme } = useSelector((state) => state.themeReducer);

  const remarkBadgeColorBG = featureData?.remark
    ? notificationRemarkStateColorMapping[featureData?.remark?.type].bg
    : "transparent";
  const remarkBadgeColorFG = featureData?.remark
    ? notificationRemarkStateColorMapping[featureData?.remark?.type].fg
    : "initial";

  const callBackUpdateFeatureData = useCallback(async () => {
    if (featureData && featureData?.id) {
      const responseData = await fetchSingleNotificationByID(featureData?.id);
      responseData && setFeatureData(responseData);
    }
  }, [featureData]);

  const callBackManageRemark = useCallback(() => {
    setModalAddRemarkToNotificationState((prevState) => ({
      ...(prevState || {}),
      isOpen: true,
      notificationInstance: featureData,
      callBackUpdateFeatureData,
    }));
  }, []);

  useEffect(() => {
    callBackUpdateFeatureData();
  }, []);

  return (
    <StyledComponent
      decoration={decoration}
      decorator={ProjectColorScheme.parseHue(
        featureData?.analytic_instance?.stream.project?.color,
        projects
      )}
      theme={theme}
    >
      <div
        className='closeBtn'
        onClick={() => {
          callbackCancel(false);
        }}
      >
        x
      </div>
      <div className='detailSection'>
        <div className='imagebox'>
          <img src={featureData?.event_image?.file || dummyImage} alt='' />
        </div>
        <div className='featureEnabled'>
          <div className='cameraName'>
            <h4>{viewData?.project?.name}</h4>
            <p> {viewData?.label_name}</p>
          </div>
          <div className='featuresOuter'>
            <div className='features'>
              {/* <div className='imgOuter'>
                                <img src={featureIcon} alt='' />
                            </div> */}
              <span className='featureDetail'>
                {
                  StreamAnalyticInstanceConfig.Definitions[
                    StreamAnalyticInstancesAvailableTypes[featureData?.type]
                  ]?.label
                }
                <br />
                <span
                  style={{
                    fontWeight: "300",
                    fontSize: "10px",
                  }}
                >
                  {
                    StreamAnalyticInstanceConfig.Definitions[
                      StreamAnalyticInstancesAvailableTypes[featureData?.type]
                    ]?.message
                  }
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='detailSidebar'>
        <div className='ShowDetails'>
          <h4 className='title'> {viewData?.project_name}</h4>
          <div className='detailOuter'>
            <span className='discription'>{viewData?.label_name}</span>
            <span className='time'>
              {moment(viewData?.date_added).format("DD-MM-YYYY h:mmA")}
            </span>
          </div>
        </div>

        <div className='DetailList'>
          <ul>
            <li className='listData'>
              <h6>Date</h6>
              <span>
                {moment(featureData?.event_time).format("DD-MM-YYYY")}
              </span>
            </li>
            <li className='listData'>
              <h6>Time</h6>
              <span>{moment(featureData?.event_time).format("HH:mm A")}</span>
            </li>
            {featureData?.event_object_id && (
              <li className='listData'>
                <h6>Object ID</h6>
                <span>{featureData?.event_object_id}</span>
              </li>
            )}
          </ul>
          <SizedBox height={2} />
          <div className='remarkMainWrapper'>
            {!featureData?.remark?.type ? (
              <div className='addRemarkBtn'>
                <button
                  className={`orange-text add-camera_btn ${
                    theme === "dark" && "add-camera_btn-dark"
                  }`}
                  onClick={() => {
                    callBackManageRemark();
                  }}
                >
                  Add Remark
                </button>
              </div>
            ) : (
              <div className='descWrapper'>
                <div className='remarkDescHeaderWrapper'>
                  <h2>Remark :</h2>
                  <button
                    className={`orange-text add-camera_btn ${
                      theme === "dark" && "add-camera_btn-dark"
                    }`}
                    onClick={() => {
                      callBackManageRemark();
                    }}
                  >
                    UPDATE REMARK
                  </button>
                </div>
                <SizedBox height={0.5} />
                <ul>
                  <li className='listData'>
                    <span>Type</span>
                    <div
                      className='remarkTypeWrapper'
                      style={{
                        backgroundColor: remarkBadgeColorBG,
                        color: remarkBadgeColorFG,
                      }}
                    >
                      {featureData?.remark?.type}
                    </div>
                  </li>
                  {featureData?.remark?.type === "Custom" ? (
                    <li className='listData'>
                      <span>
                        {featureData?.remark?.message}
                        {featureData?.remark?.message}
                        {featureData?.remark?.message}
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
