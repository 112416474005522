import { toast } from 'react-toastify'

export const notifyToast = ({ content, type, noAutoClose = false }) => {
    let toast_
    switch (type) {
        case 'success':
            toast_ = toast.success
            break
        case 'error':
            toast_ = toast.error
            break
        case 'info':
            toast_ = toast.info
            break
        default:
            toast_ = toast
            break
    }
    toast_(content, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}
