import React, { createContext, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../App";
import { MainWrapper } from "../../../MainWrapper";
import { FilterContainer } from "../../../global/filters/Container";
import { useSelector } from "react-redux";
import { CameraStreamGridContainer } from "./CameraStreamsGridContainer";
import { InputDates } from "./InputDates";
import PlaybackSection from "./PlaybackSection";
import { VideoControlProvider } from "./VideoControlProvider";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  #livestreams-wrapper {
    position: relative;
  }
  .fieldsWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 4;

    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
    }
  }

  .filters-content {
    justify-content: flex-start !important;
    gap: 1rem !important;
  }

  .stream-wrapper {
    width: 100%;
    height: fit-content;
  }

  .video-container-grid {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template: "navigator content" 100% / 300px 1fr;
    padding: 20px;

    @media (max-width: 1200px) {
      grid-template:
        "navigator"
        "content" minmax(10px, 1fr) / auto;
      padding: 10px 5px;
    }
  }

  .liveStreamVideosSection {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(0px, 1fr) auto;
    grid-template-columns: 100%;
  }

  @media (max-width: 1431px) {
    .filters-content {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
`;

export const GlobalVideoBackupContext = createContext({});

export const GlobalVideoBackupContainer = () => {
  const [playing, setPlaying] = useState(false);

  const [isBuffering, setIsBuffering] = useState(false);

  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const streamIdsRef = useRef(new Set());

  const [filterData, setFilterData] = useState({});
  const { theme } = useSelector((state) => state.themeReducer);
  const [duration, setDuration] = useState(15 * 60);

  const handleFilterData = (data) => {
    setFilterData((prev) => ({
      ...prev,
      SubUser: data.SubUser,
      Site: data.Site,
      Camera: data.Camera,
    }));
  };

  return (
    <GlobalVideoBackupContext.Provider
      value={{
        streamsData: filterData?.Camera || [],
        setFilterData,
        filterData,
        streamIdsRef,
        playing,
        setPlaying,
        isBuffering,
        setIsBuffering,
      }}
    >
      <MainWrapper
        parent='videobackup'
        isFetching={isFetching}
        profile={profile}
        pageTitle='VideoBackup'
      >
        <StyledWrapper theme={theme}>
          <div className='video-container-grid d-flex flex-column'>
            <div
              className={`mt-2 tab-content-header ${
                theme === "dark" &&
                "tab-content-header-dark-bg tab-content-header-dark-text"
              }`}
              style={{ gridArea: "navigator" }}
            >
              <div className='filters-content'>
                <InputDates />

                <FilterContainer
                  componentName={"FilterByUserSiteCamera"}
                  setterCallback={handleFilterData}
                  liveSearch={true}
                  backup={true}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                gridArea: "content",
              }}
            >
              <VideoControlProvider>
                {/* <div className='d-flex stream-wrapper mt-4 flex-grow-1'> */}
                <div className='liveStreamVideosSection'>
                  <CameraStreamGridContainer />
                  <PlaybackSection
                    duration={duration}
                    setDuration={setDuration}
                    filterData={filterData}
                  />
                </div>
                {/* </div> */}
              </VideoControlProvider>
            </div>
          </div>
        </StyledWrapper>
      </MainWrapper>
    </GlobalVideoBackupContext.Provider>
  );
};
