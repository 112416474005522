import {BaseEnum} from "../../index";

export class StreamOriginType extends BaseEnum {
    static LOCAL_NETWORK = "Local Network"
    static PUBLIC_INTERNET = "Public Internet"
}


export class StreamEnvironmentType extends BaseEnum {
    static LOCAL_NETWORK = "Local Network"
    static PUBLIC_INTERNET = "Public Internet"
}