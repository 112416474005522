import { GlobalUserProfileContext } from '../../../../App'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { APIUtils } from '../../../../helpers/utils/api'
import APIEndpoints from '../../../../config/api/endpoints'
import ProjectForm from '../ProjectForm'
import { AuthPagePreloaderComponent } from '../../../global/graphical/AuthPagePreloaderComponent'
import { MainWrapper } from '../../../MainWrapper'

const UpdateProject = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const [data, setData] = useState({})
    const { id: projectID } = useParams()

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint:
                    APIEndpoints.user.account.project.getProject(projectID),
                item: data,
                setItem: setData,
            })
        }
    }, [])

    return (
        <MainWrapper
            parent='project'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Edit Site'
        >
            <div id='body-camera'>
                <div className='main-frame-camera position-relative'>
                    <div className='tab-content h-100 py-3 px-3'>
                        {data?.isFetching === false ? (
                            data?.data && <ProjectForm obj={data?.data} />
                        ) : (
                            <AuthPagePreloaderComponent />
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default UpdateProject
