import '../../../styles/camera.css'
import SideBarLeft from '../../SidebarLeft'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../App'
import { UpdateUser } from '../../user/update/UpdateUser'
import { MainWrapper } from '../../MainWrapper'

const UpdateSubUser = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )

    return (
        <MainWrapper
            parent='sub_user'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Update Sub-User'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    {/* <SideBarLeft
                        parent='sub_user'
                        isFetching={isFetching}
                        profile={profile}
                    /> */}
                    <div className='tab-content h-100 py-3 px-3'>
                        <UpdateUser />
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default UpdateSubUser
