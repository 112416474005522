import '../../styles/account_auth.css'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axiosInstance from '../../config/axios'
import displayError from '../../helpers/displayError'
import { notifyFailure } from '../../helpers/notifications/notifyFailure'
import RegisterIcon from '../../static/svg/register.png'
import { useSelector } from 'react-redux'
import { ThemeComponent } from '../global/toggletheme'
import { DarkThemeAuth } from '../global/graphical/DarkThemeAuth'

const Register = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const handleRegister = async () => {
        if (password1 !== password2) {
            notifyFailure('Your password fields does not match each other')
            return
        }
        ;(await axiosInstance())
            .post('/auth/register', {
                username,
                email,
                password: password1,
            })
            .then(response => {
                //
                if (
                    window.confirm(
                        'Account Creation Successful.\nProceed to login?'
                    )
                ) {
                    navigate('/login', { replace: true })
                }
            })
            .catch(err => {
                displayError(err, theme)
            })
    }
    return (
        <div
            id='body-2'
            style={
                theme === 'dark'
                    ? {
                          background: `linear-gradient(
to right bottom,
rgb(32, 45, 66),
rgb(22, 33, 49),
rgb(20, 45, 49)`,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
                    : {
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
        >
            <div className='toggleBtnAuth'>
                <ThemeComponent />
            </div>
            <DarkThemeAuth enableStyle={true}>
                <div
                    className={`main-frame ${
                        theme === 'dark' && 'main-frame-dark'
                    }`}
                    style={{ width: '500px' }}
                >
                    <div className='form-wrapper' style={{ width: '85%' }}>
                        <form className='d-flex flex-column align-items-start w-100'>
                            <div
                                className='desktop mt-5'
                                style={{ width: '100px', height: '100px' }}
                            >
                                <img
                                    src={RegisterIcon}
                                    alt=''
                                    className='w-100 h-100,'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-5'>
                                <span
                                    className={`highlight-heading ${
                                        theme === 'dark' &&
                                        'highlight-heading-dark'
                                    } text-auth-title`}
                                >
                                    nettbox
                                </span>
                            </div>

                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='username'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Username
                                </label>
                                <input
                                    type='text'
                                    onChange={e => setUsername(e.target.value)}
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='username'
                                    aria-describedby='emailHelp'
                                    placeholder='Your username here...'
                                />
                            </div>
                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='email'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Email Address
                                </label>
                                <input
                                    type='email'
                                    onChange={e => setEmail(e.target.value)}
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='email'
                                    aria-describedby='emailHelp'
                                    placeholder='Your email here...'
                                />
                            </div>
                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='password1'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Password
                                </label>
                                <input
                                    type='password'
                                    onChange={e => setPassword1(e.target.value)}
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='password1'
                                    placeholder='Enter password here...'
                                />
                            </div>
                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='password2'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Confirm Passwrd
                                </label>
                                <input
                                    type='password'
                                    onChange={e => setPassword2(e.target.value)}
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='password2'
                                    placeholder='Enter password again...'
                                />
                            </div>

                            <div className='d-flex justify-content-center my-3'>
                                <button
                                    type='submit'
                                    onClick={e => {
                                        e.preventDefault()
                                        handleRegister()
                                    }}
                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                        theme === 'dark' &&
                                        'btn-custom-orange-dark'
                                    }`}
                                >
                                    Update
                                </button>
                            </div>
                            <div className='d-flex align-items-center justify-content-center text-center w-100 flex-column mt-3'>
                                <div
                                    style={{
                                        color: theme === 'dark' && 'white',
                                    }}
                                >
                                    Already have account?
                                </div>
                                <Link
                                    className={`orange-text text-small mx-1 ${
                                        theme === 'dark' && 'orange-text-dark'
                                    }`}
                                    to='/login'
                                >
                                    Login to your account
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </DarkThemeAuth>
        </div>
    )
}

export default Register
