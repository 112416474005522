import React from 'react'
import styled from 'styled-components'

export const RightSideContent = ({ ...props }) => {
    return <StyledComponent {...props}>{props.children}</StyledComponent>
}

const StyledComponent = styled.div`
    ${'' /* transition: width 0.7s ease; */}
    position: absolute;
    top: -33px;
    right: -7px;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    background: rgba(18, 46, 91, 0.6);
    backdrop-filter: blur(2.5px);
    overflow-wrap: break-word;
    display: flex;
    justify-content: end;
    color: ${props => (props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : '')};

    .dashpanel {
        background-color: transparent !important;
        border: none;
        border-radius: 0px;

        .title {
            color: ${props => (props.theme === 'dark' ? 'white' : '')};
        }

        .content {
            color: ${props =>
                props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : ''};
        }
    }

    .main-content {
        flex-grow: 0;
        display: flex;
        width: 585px;
        flex-direction: column;

        background: ${props =>
            props.theme === 'dark' ? 'rgba(38, 56, 84, 1)' : ' white'};
        overflow: scroll;
        padding: 20px;
    }

    .close-profile {
        font-size: 20px;
        color: ${props =>
            props.theme === 'dark' ? 'rgba(38, 56, 84, 1)' : ' #989898'};
        font-weight: 300;
        cursor: pointer;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Poppins';
    }
    @media screen and (max-width: 630px) {
        .main-content {
            width: 300px;
        }
        .enableonlyinabovesm {
            display: none;
        }
    }

    label {
        color: ${props => (props.theme === 'dark' ? 'white' : '')};
    }
`
