import {APPProductionEnvironmentType} from "./enums/system";


export class AppConfig {
    static IS_IN_PRODUCTION_ENVIRONMENT = process.env.NODE_ENV === "production"
    static MAIN_HTTP_SERVER_URL = AppConfig.IS_IN_PRODUCTION_ENVIRONMENT ? process.env.REACT_APP_MAIN_HTTP_SERVER_URL_PRODUCTION : process.env.REACT_APP_MAIN_HTTP_SERVER_URL_DEVELOPMENT
    static MAIN_WS_SERVER_URL = AppConfig.IS_IN_PRODUCTION_ENVIRONMENT ? process.env.REACT_APP_MAIN_WS_SERVER_URL_PRODUCTION : process.env.REACT_APP_MAIN_WS_SERVER_URL_DEVELOPMENT
    static APP_PRODUCTION_ENVIRONMENT = AppConfig.IS_IN_PRODUCTION_ENVIRONMENT && (process.env.REACT_APP_APP_PRODUCTION_ENVIRONMENT && APPProductionEnvironmentType.values().includes(process.env.REACT_APP_APP_PRODUCTION_ENVIRONMENT) ? process.env.REACT_APP_APP_PRODUCTION_ENVIRONMENT : APPProductionEnvironmentType.WEB)
    static STREAM_RELAY_SERVER_URL = AppConfig.IS_IN_PRODUCTION_ENVIRONMENT ? (this.APP_PRODUCTION_ENVIRONMENT === APPProductionEnvironmentType.WEB ? process.env.REACT_APP_STREAM_RELAY_SERVER_URL_PRODUCTION_WEB : process.env.REACT_APP_STREAM_RELAY_SERVER_URL_PRODUCTION_DESKTOP) : process.env.REACT_APP_STREAM_RELAY_SERVER_URL_DEVELOPMENT
    static MAIN_SERVER_SECRET_KEY = AppConfig.IS_IN_PRODUCTION_ENVIRONMENT ? process.env.REACT_APP_MAIN_SERVER_SECRET_KEY_PRODUCTION : process.env.REACT_APP_MAIN_SERVER_SECRET_KEY_DEVELOPMENT
    static CRYPTO_AES_IV_LENGTH = Number.parseInt(process.env.REACT_APP_CRYPTO_AES_IV_LENGTH) || 16
    static CRYPTO_AES_KEY = process.env.REACT_APP_CRYPTO_AES_KEY || "*******"
}