import { AppConfig } from '../../../../config'
import { retrieveFromLocalStorage } from '../../../localStorage'
import { CryptoAESCBCHelperUtils } from '../../crypto/symmetric/aes'

export class WSUtilsHelperConnectionManager {
    connInstance
    endpoint
    callBack
    reconnectInterval

    constructor(endpoint, callBack) {
        this.endpoint = endpoint
        this.callBack = callBack
        // this.reconnectInterval = 3000
    }

    get connURL() {
        return `${AppConfig.MAIN_WS_SERVER_URL}${this.endpoint}`
    }

    bindEvents() {
        if (this.connInstance) {
            this.connInstance.onopen = e => {
                this.callBack(this)
                this.authenticate()
            }

            this.connInstance.onclose = e => {
                this.connInstance = null
                this.callBack(null)
                // Automatically attempt to reconnect
                // setTimeout(() => {
                //     this.initiate()
                // }, this.reconnectInterval)
            }
        }
    }

    authenticate() {
        const authPayload = {
            type: 'authenticate',
            data: CryptoAESCBCHelperUtils.encrypt(
                retrieveFromLocalStorage('access_token'),
                AppConfig.CRYPTO_AES_KEY
            ),
        }
        this.send(authPayload)
    }

    initiate() {
        this.connInstance = new WebSocket(this.connURL)
        this.bindEvents()
    }

    send(data) {
        this.connInstance.send(JSON.stringify(data))
    }

    close() {
        if (this.connInstance) {
            this.connInstance.close()
        }
    }
}
