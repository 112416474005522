import { useCallback, useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../config/axios'
import displayError from '../../../helpers/displayError'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Spinner'
import APIEndpoints from '../../../config/api/endpoints'
import { SubAdminSubUserRoutes } from '../../../routes/SubAdmin&SubUserRoutes'
import { HandleOnChangeInput } from '../../../helpers/utils/formInput/HandleOnChangeInput'
import { GlobalUserProfileContext } from '../../../App'
import { useLoadDependenciesBeforeRender } from '../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { APIUtils } from '../../../helpers/utils/api'
import { SELECT_INPUT_CLASS_NAME } from '../../../config/constants'
import { FormInputUtils } from '../../../helpers/utils/formInput'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const StreamForm = ({ redirectOnSuccess, obj }) => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [projects, setProjects] = useState({})
    const [streamEnvironmentTypes, setStreamEnvironmentTypes] = useState({})
    const [streamOriginTypes, setStreamOriginTypes] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: projects?.isFetching,
            c: streamEnvironmentTypes?.isFetching,
            d: streamOriginTypes?.isFetching,
        },
    })

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault()
            setIsProcessing(true)
            const isUpdate = obj && obj?.id
            await (
                await axiosInstance()
            )
                [isUpdate ? 'patch' : 'post'](
                    isUpdate
                        ? APIEndpoints.instance.stream.main.update(obj.id)
                        : APIEndpoints.instance.stream.main.create(),
                    formData
                )
                .then(response => {
                    navigate(
                        redirectOnSuccess ||
                            SubAdminSubUserRoutes.pathDefinitions.stream
                                .viewStreams,
                        { replace: true }
                    )
                })
                .catch(err => {
                    displayError(err, theme)
                })
            setIsProcessing(false)
        },
        [formData, isProcessing]
    )

    const handleKeyPress = e => {
        if (e.charCode == 13 && !isProcessing) {
            handleSubmit(e)
        }
    }

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.user.account.project.getAllProjects({
                    owner: profile?.id,
                }),
                item: projects,
                setItem: setProjects,
            })
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.utils.types.get(
                    'stream_stream_environment'
                ),
                item: streamEnvironmentTypes,
                setItem: setStreamEnvironmentTypes,
            })
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.utils.types.get('stream_stream_origin'),
                item: streamOriginTypes,
                setItem: setStreamOriginTypes,
            })
        }
    }, [profile])

    return (
        <StyledComponent theme={theme}>
            <div className='w-100 d-flex'>
                {!isProcessing ? (
                    <button
                        onClick={handleSubmit}
                        type='submit'
                        className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                            theme === 'dark' && 'btn-custom-orange-dark'
                        }`}
                    >
                        Save
                    </button>
                ) : (
                    <Spinner size={1.5} />
                )}
            </div>
            {canRender !== true ? (
                <div className='w-100 h-100 d-flex justify-content-center align-items-center my-5'>
                    {canRender === false && <Spinner size={1.5} />}
                </div>
            ) : (
                <div className='grid-form-camera my-3 mt-5'>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='camera_label'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Your preferred name for the stream
                        </label>
                        <input
                            required={true}
                            value={formData?.label_name || obj?.label_name}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'label_name',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='camera_label'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='camera_project'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Camera Project
                        </label>
                        <select
                            value={formData?.project || obj?.project?.id}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'project',
                                    setFormData,
                                    formData
                                )
                            }
                            id='camera_project'
                            className={SELECT_INPUT_CLASS_NAME}
                            style={
                                theme === 'dark'
                                    ? {
                                          color: 'white',
                                      }
                                    : null
                            }
                        >
                            {FormInputUtils.parseArrayValuesToSelectFields(
                                projects?.data
                            ).map(project => (
                                <option
                                    value={project?.id}
                                    selected={
                                        (formData?.project ||
                                            obj?.project?.id) === project?.id
                                    }
                                >
                                    {project?.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='camera_origin_address'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            The origin address of the stream
                        </label>
                        <input
                            required={true}
                            value={formData?.origin_address || ''}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'origin_address',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='camera_origin_address'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='camera_environment_type'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            The environment of the stream
                        </label>
                        <select
                            value={
                                formData?.environment_type ||
                                obj?.environment_type
                            }
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'environment_type',
                                    setFormData,
                                    formData
                                )
                            }
                            id='camera_environment_type'
                            className={SELECT_INPUT_CLASS_NAME}
                        >
                            {FormInputUtils.parseArrayValuesToSelectFields(
                                streamEnvironmentTypes?.data
                            ).map(item => (
                                <option
                                    value={item}
                                    selected={
                                        (formData?.environment_type ||
                                            obj?.environment_type) === item
                                    }
                                >
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='camera_origin_type'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            The origin of the stream
                        </label>
                        <select
                            value={formData?.origin_type || obj?.origin_type}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'origin_type',
                                    setFormData,
                                    formData
                                )
                            }
                            id='camera_origin_type'
                            className={SELECT_INPUT_CLASS_NAME}
                        >
                            {FormInputUtils.parseArrayValuesToSelectFields(
                                streamOriginTypes?.data
                            ).map(item => (
                                <option
                                    value={item}
                                    selected={
                                        (formData?.origin_type ||
                                            obj?.origin_type) === item
                                    }
                                >
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </StyledComponent>
    )
}

const StyledComponent = styled.div`
    ul {
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
        border: none !important;
        border-radius: 15px;
    }

    ${props =>
        props.theme === 'dark' &&
        `
        .select-dropdown:focus, .select-dropdown, .select-wrapper {
            color: white !important;
            font-size: 0.8rem !important;
        }
        .select-dropdown:focus,
        .select-dropdown,
        .select-wrapper {
            border: 0.5px solid rgba(94, 132, 195, 1);
            border-radius: 27px;
        }
 input {
            border-bottom: 2px rgba(94, 132, 195, 1) solid !important;
            box-shadow: none;
        }
        input.select-dropdown {
            margin: 5px 0 8px 9px;
             border-bottom: none !important;
            box-shadow: none;
        }
        

       
        .select-dropdown:focus,
        .select-dropdown:active,
        .select-dropdown.active {
            border: none !important;
        }

        ul {
            background: rgba(38, 56, 84, 1);
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
            border: none !important;

            li span {
                color: white !important;
            }
        }

        .caret {
            fill: rgba(94, 132, 195, 1) !important;
        }
    `};
`
