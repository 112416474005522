import "../../../styles/camera.css";
import Spinner from "../../Spinner";
import { useContext, useEffect, useState } from "react";
import { GlobalUserProfileContext } from "../../../App";
import { APIUtils } from "../../../helpers/utils/api";
import APIEndpoints from "../../../config/api/endpoints";
import {
  InstancesAvailableFullNameMapping,
  InstancesAvailableGVariableKey,
} from "../../../config/enums/instance";
import moment from "moment";
import { useLoadDependenciesBeforeRender } from "../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { UserAccountType } from "../../../config/enums/user";
import { MainWrapper } from "../../MainWrapper";
import { useSelector } from "react-redux";

const UserPlan = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const { theme } = useSelector((state) => state.themeReducer);

  const [instanceStatsData, setInstanceStatsData] = useState({});
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: instanceStatsData?.isFetching,
    },
  });

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.user.account.instance.statistics.getPersonal(),
        item: instanceStatsData,
        setItem: setInstanceStatsData,
      });
    }
  }, []);

  return (
    <MainWrapper
      parent='account_plan'
      isFetching={isFetching}
      profile={profile}
      pageTitle='License Plan'
    >
      <div id='body-camera'>
        <div className='main-frame-camera'>
          <div className='tab-content h-100 py-3 px-3'>
            <h6
              className={`mt-3 card-heading ${
                theme === "dark" && "card-heading-dark"
              }`}
            >
              Functions
            </h6>
            {canRender !== true ? (
              <div className='d-flex justify-content-center w-100 mt-5'>
                {canRender === false && <Spinner size={1.8} />}
              </div>
            ) : (
              <>
                <div className='grid-region my-3'>
                  {InstancesAvailableGVariableKey.keys().map(
                    (instance, index) => {
                      const { isFetching, data } = instanceStatsData || {};
                      const fullName =
                        InstancesAvailableFullNameMapping[instance];
                      return (
                        (isFetching === false ||
                          (isFetching &&
                            isFetching !== true &&
                            isFetching !== instance)) && (
                          <div
                            className={`dashpanel p-2 p-md-3 ${
                              theme === "dark" && "dashpanel-dark"
                            }`}
                            key={index}
                          >
                            <div
                              className='title'
                              style={{
                                color:
                                  theme === "dark" && "rgba(94, 132, 195, 1)",
                              }}
                            >
                              {fullName} Functions
                            </div>

                            <div className='content px-3 mt-3'>
                              Total Functions :{" "}
                              {data?.assigned[instance.toLowerCase()]}
                            </div>
                            {profile?.account_type ===
                              UserAccountType.SUB_ADMINISTRATOR && (
                              <>
                                <div className='content px-3'>
                                  Granted :{" "}
                                  {data?.granted[instance.toLowerCase()]}
                                </div>
                              </>
                            )}

                            <div className='content px-3'>
                              Deployed :{" "}
                              {data?.deployed[instance.toLowerCase()]}
                            </div>
                          </div>
                        )
                      );
                    }
                  )}
                </div>
                {profile?.account_type ===
                  UserAccountType.SUB_ADMINISTRATOR && (
                  <>
                    <h6
                      className={`mt-3  card-heading ${
                        theme === "dark" && "card-heading-dark"
                      }`}
                    >
                      Subscription
                    </h6>
                    <div className='grid-region my-3'>
                      <div
                        className={`dashpanel p-2 p-md-3 ${
                          theme === "dark" && "dashpanel-dark"
                        }`}
                      >
                        <div className='title d-flex justify-content-between px-3'>
                          <div
                            style={{
                              color:
                                theme === "dark" && "rgba(94, 132, 195, 1)",
                            }}
                          >
                            Started On :{" "}
                            {moment(profile.subscription?.date_added)
                              .local()
                              .format("YYYY-MM-DD")}
                          </div>
                        </div>

                        <div className='content px-3 d-flex justify-content-start mt-3'>
                          Expires On :{" "}
                          {profile.subscription?.date_of_expiration}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default UserPlan;
