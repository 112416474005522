import {
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_START,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROJECTS_FAILED,
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  REFRESH_PROFILE_SUCCESS,
  TOGGLE_THEME,
} from "./constants";
import axiosInstance from "./axios";
import APIEndpoints from "./api/endpoints";
import { accessTokenCheckExistence } from "../conditions";
import { ResponseStatusCode } from "./enums/api";
import { APIAuthUtils } from "../helpers/utils/api/auth";
import moment from "moment";

export const fetchProfile = async (dispatch, getState) => {
  const state = getState();
  if (await accessTokenCheckExistence()) {
    const { profile, lastTimeFetched } = state.profileReducer;
    dispatch({ type: FETCH_PROFILE_START, payload: null });
    if (
      lastTimeFetched &&
      lastTimeFetched >= moment().subtract(10, "seconds")
    ) {
      dispatch({ type: REFRESH_PROFILE_SUCCESS, payload: profile });
    } else {
      (await axiosInstance())
        .get(APIEndpoints.auth.me.fetchMyProfile())
        .then((response) => {
          const profile_data = response.data;
          dispatch({
            type: FETCH_PROFILE_SUCCESS,
            payload: profile_data,
          });
        })
        .catch((err) => {
          if (err?.response?.status === ResponseStatusCode.UNAUTHORIZED) {
            APIAuthUtils.clearSessionAndMoveToLoginPage();
          } else {
            dispatch({ type: FETCH_PROFILE_FAILED, payload: null });
          }
          // displayError(err)
        });
    }
  } else {
    APIAuthUtils.clearSessionAndMoveToLoginPage();
  }
};
export const toggleTheme = (theme) => (dispatch) => {
  dispatch({ type: TOGGLE_THEME, payload: theme });
};

export const projectsList = (profile_id) => async (dispatch) => {
  if (!profile_id) {
    dispatch({ type: FETCH_PROJECTS_FAILED, payload: 0 });
  } else {
    dispatch({ type: FETCH_PROJECTS_START, payload: 0 });
    (await axiosInstance())
      .get(
        APIEndpoints.user.account.project.getAllProjects({
          owner: profile_id,
          count: true,
        })
      )
      .then((response) => {
        const projects = response.data;
        dispatch({
          type: FETCH_PROJECTS_SUCCESS,
          payload: projects?.count,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_PROJECTS_FAILED, payload: 0 });
      });
  }
};
