import { Route, Routes } from "react-router-dom";
import SubAdminList from "../components/admin/subadmin/SubAdminList";
import AddSubAdmin from "../components/admin/subadmin/AddSubAdmin";
import Settings from "../components/admin/Settings";
import { ProtectedRouteComponent } from "./protected_routes";
import { isAccountTypes, isAuthenticated } from "../conditions";
import { UserAccountType } from "../config/enums/user";
import { UpdateSubAdminMain } from "../components/admin/subadmin/update/Main";
import { GlobalRoutes } from "./GlobalRoutes";

export class AdminRoutes {
  static get pathDefinitions() {
    return {
      viewSubAdmins: "/sub_admins",
      addSubAdmin: "/sub_admins/add",
      viewSingleSubAdmin: "/sub_admin/:id",
      viewAdminSettings: "/admin/settings",
    };
  }

  static Mapping() {
    return (
      <Routes>
        <Route
          path={AdminRoutes.pathDefinitions.viewSubAdmins}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.ADMINISTRATOR]),
              }}
              elementToRenderOnPass={<SubAdminList />}
            />
          }
        />
        <Route
          path={AdminRoutes.pathDefinitions.addSubAdmin}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.ADMINISTRATOR]),
              }}
              elementToRenderOnPass={<AddSubAdmin />}
            />
          }
        />
        <Route
          path={AdminRoutes.pathDefinitions.viewSingleSubAdmin}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.ADMINISTRATOR]),
              }}
              elementToRenderOnPass={<UpdateSubAdminMain />}
            />
          }
        />
        <Route
          path={AdminRoutes.pathDefinitions.viewAdminSettings}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.ADMINISTRATOR]),
              }}
              elementToRenderOnPass={<Settings />}
            />
          }
        />
      </Routes>
    );
  }
}
