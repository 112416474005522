import '../../../styles/camera.css'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../App'
import { MainWrapper } from '../../MainWrapper'
import { useSelector } from 'react-redux'

export const BlankAuthCenterCanvas = ({ pageTitle, pageParent, children }) => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <MainWrapper
            parent={pageParent}
            isFetching={isFetching}
            profile={profile}
            pageTitle={pageTitle}
        >
            <div id='body-camera'>
                <div className='main-frame-camera position-relative'>
                    <div
                        className={`tab-content h-100 py-3 px-3 ${
                            theme === 'dark' && 'tab-content-dark'
                        }`}
                    >
                        <div className='mt-5'>
                            <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}
