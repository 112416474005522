import styled, { css } from 'styled-components'

import { Link, useNavigate } from 'react-router-dom'
import { GlobalRoutes } from '../../../../routes/GlobalRoutes'

const StyledComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width || '100%'};
    padding: 1rem 0;
    height: 100px;
    margin: 0;
    background-color: ${props =>
        props.theme === 'light' ? 'white' : '#263754'} !important;
    a {
        text-decoration: none;
    }

    span {
        font-family: 'Poppins';
        font-size: 44px;
        font-weight: 700;
        color: ${props => (props.theme === 'light' ? '#0A2758' : 'white')};

        ${({ desktopToggle }) =>
            desktopToggle &&
            css`
                font-size: 20px;
            `}
    }
`

export const NavigationBrandContainer = ({ ...props }) => {
    const navigate = useNavigate()
    const handleNavigate = id => {
        localStorage.setItem('activeNavigationKey', id.toString())
        navigate(GlobalRoutes.pathDefinitions.account.profileSettings)
    }
    return (
        <StyledComponent {...props}>
            <Link
                to={GlobalRoutes.pathDefinitions.account.dashboard}
                onClick={() => handleNavigate(1)}
            >
                <span>nettbox</span>
            </Link>
        </StyledComponent>
    )
}
