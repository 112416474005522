import "../../../styles/camera.css";
import { GlobalUserProfileContext } from "../../../App";
import { useContext, useEffect, useState } from "react";
import { APIUtils } from "../../../helpers/utils/api";
import Spinner from "../../Spinner";
import { UserListViewPanel } from "./Object";
import { Link } from "react-router-dom";
import { UserAccountType } from "../../../config/enums/user";
import { AdminRoutes } from "../../../routes/AdminRoutes";
import { SubAdminRoutes } from "../../../routes/SubAdminRoutes";
import { useLoadDependenciesBeforeRender } from "../../../helpers/hooks/UseLoadDependenciesBeforeRender";

export const UserListViewContainer = ({ endpoint, panelChildren, theme }) => {
  const { profile } = useContext(GlobalUserProfileContext);
  const [users, setUsers] = useState({});
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: users?.isFetching,
    },
  });

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: endpoint,
        item: users,
        setItem: setUsers,
      });
    }
  }, [profile]);

  return (
    <>
      <div
        className={`mt-2 tab-content-header d-flex justify-content-end ${
          theme === "dark" &&
          "tab-content-header-dark-bg tab-content-header-dark-text"
        }`}
      >
        <Link
          className={`orange-text add-camera_btn ${
            theme === "dark" && "add-camera_btn-dark"
          }`}
          to={{
            pathname:
              profile?.account_type === UserAccountType.ADMINISTRATOR
                ? AdminRoutes.pathDefinitions.addSubAdmin
                : SubAdminRoutes.pathDefinitions.addSubUser,
            state: { reload: true },
          }}
        >
          + Add{" "}
          {profile?.account_type === UserAccountType.ADMINISTRATOR
            ? "Sub-Administrators"
            : "Sub-Users"}
        </Link>
      </div>
      {canRender !== true ? (
        <div className='d-flex justify-content-center w-100 mt-5'>
          {canRender === false && <Spinner size={1.8} />}
        </div>
      ) : users?.data !== null && !users.data.length ? (
        <div className='d-flex justify-content-center w-100 mt-5 text-center'>
          You currently have no sub-administrator registered
        </div>
      ) : (
        <div className='grid-region my-3'>
          {users.data.map((user) => (
            <UserListViewPanel
              obj={user}
              fetchAllUsers={() => {
                APIUtils.fetchItemData({
                  endpoint: endpoint,
                  item: users,
                  setItem: setUsers,
                });
              }}
              panelChildren={panelChildren}
            />
          ))}
        </div>
      )}
    </>
  );
};
