import { FilterByUserSiteCamera } from "../FilterUserSiteCamera/FilterByUserSiteCamera";
import { FilterByUserSiteCameraSingle } from "../FilterUserSiteCamera/FilterByUserSiteCameraSingle";

export const Filters = ({
  setterCallback,
  componentName,
  liveSearch,
  backup,
  isMulti,
  btnText,
}) => {
  const ComponentsArray = {
    FilterByUserSiteCamera: (
      <FilterByUserSiteCamera
        setterCallback={setterCallback}
        liveSearch={liveSearch}
        backup={backup}
      />
    ),
    FilterByUserSiteCameraSingle: (
      <FilterByUserSiteCameraSingle
        setterCallback={setterCallback}
        liveSearch={liveSearch}
        backup={backup}
        isMulti={isMulti}
        btnText={btnText}
      />
    ),
  };

  const SelectedComponent = ComponentsArray[componentName];

  return { SelectedComponent };
};
