import {APIEndpointsHelper} from "../../index";

export class APIEndpointInstanceVPSReport {
    static create() {
        return `/instance/vps/report/`
    }

    static fetch(id) {
        return `/instance/vps/report/${id}/`
    }

    static fetchAsGuest(id) {
        return `/instance/vps/guest/report/${id}/`
    }

    static update(id) {
        return `/instance/vps/report/${id}/`
    }

    static delete(id) {
        return `/instance/vps/report/${id}/`
    }

    static fetchAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/report/`, params)
    }
}