import "../../../styles/camera.css";
import { useContext } from "react";
import { GlobalUserProfileContext } from "../../../App";
import APIEndpoints from "../../../config/api/endpoints";
import { UserAccountType } from "../../../config/enums/user";
import { UserListViewContainer } from "../../user/listView/Container";
import { MainWrapper } from "../../MainWrapper";
import { useSelector } from "react-redux";

const SubUserList = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );

  const { theme } = useSelector((state) => state.themeReducer);

  return (
    <MainWrapper
      parent='sub_user'
      isFetching={isFetching}
      profile={profile}
      pageTitle={`Manage ${
        profile?.account_type === UserAccountType.ADMINISTRATOR
          ? "Sub-Administrators"
          : "Sub-Users"
      }`}
    >
      <div id='body-camera'>
        <div className='main-frame-camera'>
          <div
            className={`tab-content h-100 py-3 px-3 ${
              theme === "dark" && "tab-content-header-dark-text"
            }`}
          >
            <UserListViewContainer
              endpoint={APIEndpoints.auth.relation.getAllRelations({
                account_type: UserAccountType.SUB_USER,
              })}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default SubUserList;
