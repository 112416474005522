import { APIEndpointsHelper } from "../../../../../../index";

export class APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReport {
  static create() {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/`;
  }

  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/${id}/`;
  }
}

export class APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReportAsGuest {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/guest/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/report/guest/${id}/`;
  }
}
