import { useState } from 'react'
import '../../../../../../styles/analytic.css'
import { StreamPlayerCanvasContainer } from '../../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer'

const CanvasStream = ({ stream }) => {
    const [pauseStream, setPauseStream] = useState(null)

    return (
        <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
            <div className='people-counter-canvas-width-only text-analytic-header d-flex justify-content-start'>
                Safety Equipment Detector
            </div>
            <div className='people-counter-canvas'>
                <div className='wrapper'>
                    <StreamPlayerCanvasContainer
                        stream={stream}
                        pauseStream={pauseStream}
                        clearWGLOnExit={false}
                    />
                </div>
                <div
                    className='wrapper'
                    id={'people-counter-canvas-boundary-line'}
                ></div>
            </div>
        </div>
    )
}

export default CanvasStream
