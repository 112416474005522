import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppConfig } from '../../../config'
import { APPProductionEnvironmentType } from '../../../config/enums/system'
import {
    StreamEnvironmentType,
    StreamOriginType,
} from '../../../config/enums/instance/stream'
import displayError from '../../displayError'
import { SubAdminSubUserRoutes } from '../../../routes/SubAdmin&SubUserRoutes'
import { useSelector } from 'react-redux'

const useValidateStreamStreamingConfigWithCurrentEnvironmentBeforeRender = ({
    stream,
}) => {
    const { theme } = useSelector(state => state.themeReducer)
    const [isValidating, setIsValidating] = useState(null)
    const navigate = useNavigate()
    const handleFallBackOnFailure = useCallback(message => {
        displayError(message, theme)
        navigate(SubAdminSubUserRoutes.pathDefinitions.stream.viewStreams, {
            replace: false,
        })
    }, [])

    useEffect(() => {
        if (stream) {
            if (!stream.broadcast_address) {
                const message =
                    "You currently can't view the media of this stream as its yet available for streaming"
                handleFallBackOnFailure(message)
                return
            } else if (
                AppConfig.APP_PRODUCTION_ENVIRONMENT ===
                APPProductionEnvironmentType.WEB
            ) {
                if (
                    stream.origin_type === StreamOriginType.LOCAL_NETWORK &&
                    stream.environment_type ===
                        StreamEnvironmentType.LOCAL_NETWORK
                ) {
                    const message =
                        "You currently can't view the media of this stream on a website as its configuration does not support streaming over the public internet"
                    handleFallBackOnFailure(message)
                    return
                }
            }
            setIsValidating(false)
        }
    }, [stream])
    return isValidating
}

export const StreamInstanceHooks = {
    useValidateStreamStreamingConfigWithCurrentEnvironmentBeforeRender,
}
