import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {AlertContentStyled} from "./alertBaseConfig";


export const fireAlertConfirmDeletion = ({content}) => {
    const MySwal = withReactContent(Swal)
    let alertContent;
    alertContent = (
        <AlertContentStyled>
            {content}
        </AlertContentStyled>
    )
    return MySwal.fire({
        title: <p className="alert-title">Confirm Deletion</p>,
        html: alertContent,
        icon: "warning",
        confirmButtonText: "Yes",
        denyButtonText: "No",
        cancelButtonText: "Cancel",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true
    })
}