import styled from 'styled-components'
import { useContext, useEffect, useRef } from 'react'
import { GlobalLiveStreamContext } from './LiveStream'
import { CameraStreamGridObject } from './CameraStreamGridObject'
import { motion, AnimatePresence } from 'framer-motion'

const StyledWrapper = styled(motion.div)`
    display: grid;
    height: 70vh;
    width: 100%;
    grid-template-columns: ${({ gridConfig }) =>
        gridConfig.base.templateColumns};
    grid-template-rows: ${({ gridConfig }) => gridConfig.base.templateRows};
    grid-template-areas: ${({ gridConfig }) => gridConfig.base.templateAreas};
    grid-gap: 1rem;

    @media (min-width: 600px) {
        grid-template-columns: ${({ gridConfig }) =>
            gridConfig.main.templateColumns};
        grid-template-rows: ${({ gridConfig }) => gridConfig.main.templateRows};
        grid-template-areas: ${({ gridConfig }) =>
            gridConfig.main.templateAreas};
    }
`

const alphabets = 'abcdefghijklmnopqrstuvwxyz'

export const CameraStreamGridContainer = ({ projects }) => {
    const {
        streams,
        selectedFrameDimension,
        paginationLevel,
        isPlayingSlideShow,
        setPaginationLevel,
        hasNextSlideShow,
        hasPrevSlideShow,
        slideShowIntervalSecs,
    } = useContext(GlobalLiveStreamContext)
    const lastTimeoutSlideshow = useRef(0)

    const parseTemplateArea = config => {
        return `${config.map(item => `"${item.join(' ')}"`).join(' ')}`
    }

    const fetchGridConfig = () => {
        let baseCount = selectedFrameDimension[0] ** 2
        if (baseCount > streams?.data?.length) {
            baseCount = streams?.data?.length
        }
        let baseConfigTemplateRows = `repeat(${baseCount}, 1fr)`
        let baseConfigTemplateColumns = '1fr'
        let baseConfigTemplateAreas = []
        let count = 1
        while (count <= baseCount) {
            baseConfigTemplateAreas.push([
                alphabets.substring(count - 1, count),
            ])
            count++
        }
        let baseGridConfig = {
            templateAreas: parseTemplateArea(baseConfigTemplateAreas),
            templateRows: baseConfigTemplateRows,
            templateColumns: baseConfigTemplateColumns,
        }

        let mainConfigTemplateRows = `repeat(${selectedFrameDimension[0]}, 1fr)`
        let mainConfigTemplateColumns = `repeat(${selectedFrameDimension[0]}, 1fr)`
        let mainConfigTemplateAreas = []
        count = 0
        while (count < selectedFrameDimension[0]) {
            let tempData = []
            let tempCount = 1
            while (tempCount <= selectedFrameDimension[1]) {
                let index = selectedFrameDimension[1] * count + tempCount
                tempData.push(alphabets.substring(index - 1, index))
                tempCount++
            }
            mainConfigTemplateAreas.push(tempData)
            count++
        }
        let mainGridConfig = {
            templateAreas: parseTemplateArea(mainConfigTemplateAreas),
            templateRows: mainConfigTemplateRows,
            templateColumns: mainConfigTemplateColumns,
        }
        return {
            base: baseGridConfig,
            main: mainGridConfig,
        }
    }

    useEffect(() => {
        clearTimeout(lastTimeoutSlideshow.current)
        if (isPlayingSlideShow) {
            if (hasNextSlideShow) {
                lastTimeoutSlideshow.current = setTimeout(() => {
                    setPaginationLevel(paginationLevel + 1)
                }, slideShowIntervalSecs * 1000)
            } else if (hasPrevSlideShow) {
                lastTimeoutSlideshow.current = setTimeout(() => {
                    setPaginationLevel(paginationLevel - 1)
                }, slideShowIntervalSecs * 1000)
            }
        }
    }, [
        isPlayingSlideShow,
        hasNextSlideShow,
        hasPrevSlideShow,
        paginationLevel,
        slideShowIntervalSecs,
    ])

    return (
        <AnimatePresence>
            <StyledWrapper
                className={'stream-grid-wrapper'}
                gridConfig={fetchGridConfig()}
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '-100%' }}
                key={paginationLevel}
                transition={{
                    duration: 1,
                }}
            >
                {streams?.data
                    .slice(
                        (paginationLevel - 1) * selectedFrameDimension[0] ** 2,
                        paginationLevel * selectedFrameDimension[0] ** 2
                    )
                    .map((stream, index_) => (
                        <CameraStreamGridObject
                            key={index_}
                            index={index_}
                            gridArea={alphabets.substring(index_, index_ + 1)}
                            stream={stream}
                            frameDimension={selectedFrameDimension}
                            projects={projects}
                        />
                    ))}
            </StyledWrapper>
        </AnimatePresence>
    )
}
