import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ModalComponent } from "../../../../../helpers/utils/Modal";
import { SizedBox } from "../../../../../helpers/utils/SizedBox";
import axiosInstance from "../../../../../config/axios";
import displayError from "../../../../../helpers/displayError";
import Spinner from "../../../../Spinner";
import { VPSContext } from "../MainWrapper";
import { SessionEntity } from "./objects/SessionEntity";
import { GenerateReportModal } from "./objects/GenerateReportModal";
import APIEndpoints from "../../../../../config/api/endpoints";
import { APIUtils } from "../../../../../helpers/utils/api";
import { useLoadDependenciesBeforeRender } from "../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { SubAdminSubUserRoutes } from "../../../../../routes/SubAdmin&SubUserRoutes";
import { StreamPlayerCanvasContainer } from "../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer";
import { useSelector } from "react-redux";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 2rem;
  @media (max-width: 920px) {
    padding-bottom: 6rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .configureVpsOuter {
    padding: 2rem;
    display: flex;
    gap: 2rem;
    .vertical-line {
      background-color: ${(props) =>
        props.theme === "dark"
          ? "rgba(94, 132, 195, 1)"
          : "rgba(228, 228, 228, 1)"};
      width: 2px;
      @media (max-width: 600px) {
        .vertical-line {
          display: none;
        }
      }
    }
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }

    .imageOuter {
      width: 100%;
      height: 550px;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      background-color: ${(props) =>
        props.theme === "dark"
          ? "rgba(38, 56, 84, 1)"
          : "rgba(30, 30, 30, 0.7)"};
      @media (max-width: 600px) {
        max-width: 100%;
      }
      .selectedCameraNumber {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 2.3rem;
        height: 2.3rem;
        background-color: ${(props) =>
          props.theme === "dark"
            ? "rgba(94, 132, 195, 1)"
            : "rgb(37, 68, 120)"};
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
  .VirtualCheckListOuter {
    max-width: 300px;
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .add-camera_btn {
      padding: 0.6rem 0.3rem;
      width: 140px;
      border-radius: 17px;
      font-family: "Poppins";
    }

    .next-generate-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 920px) {
      max-width: 100%;
      text-align: center;
    }
    .VirtualCheckListMain {
      height: 350px;
      flex: auto;
      overflow: hidden;
      width: 100%;

      @media (max-width: 600px) {
        text-align: center;
      }

      .VirtualCheckListHeader {
        font-size: 16px;
        line-height: 1.6rem;
        color: ${(props) =>
          props.theme === "dark"
            ? "rgba(94, 132, 195, 1)"
            : "rgba(37, 68, 120, 1)"};
        font-weight: 700;
        margin: 0;
        text-align: center;
        font-family: "Poppins";
      }
      .VirtualCheckListContentOuter {
        padding: 0.9rem;
        overflow-y: scroll;
        height: 85%;
        ::-webkit-scrollbar {
          width: 2px;
        }
        ::-webkit-scrollbar-track {
          background-color: ${(props) =>
            props.theme === "dark"
              ? "rgba(141, 141, 141, 1)"
              : "rgba(209, 209, 209, 1)"};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${(props) =>
            props.theme === "dark"
              ? "rgba(94, 132, 195, 1)"
              : "rgba(37, 68, 120, 1)"};
          border-radius: 6px;
        }

        .VirtualCameraTitle {
          color: ${(props) => (props.theme === "dark" ? "white" : "black")};
          padding: 0.6rem 0;

          h4 {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.2rem;
            text-align: center;
          }
        }

        .VirtualCheckListOptions {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          margin: 1rem auto 1rem;

          li {
            border-radius: 27px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 3.4rem;
            background-color: ${(props) =>
              props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "white"};

            .optionName {
              margin-bottom: 1rem;
              color: ${(props) => (props.theme === "dark" ? "white" : "black")};
            }
            .AuthRadioMode {
              display: flex;
              justify-content: space-around;
              width: 100%;
              .radioOuter {
                display: flex;
                gap: 0.5rem;
              }
            }
            label {
              position: relative;
              .label {
                margin-left: 1.5rem;
                color: ${(props) =>
                  props.theme === "dark" ? "white" : "#5a5a5a"};
              }
              input {
                opacity: 0;
                &:checked ~ .checkmark {
                  border: 1px solid #324b98;
                  background: #324b98;

                  &.negative {
                    background: red;
                  }
                }
              }

              [type="radio"]:not(:checked) + span,
              [type="radio"]:checked + span {
                padding: 0;
              }
              [type="radio"]:not(:checked) + span:before {
                border-radius: 0 !important;
                margin: 0;
                border: 0;
              }
              .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                ${(props) =>
                  props.theme === "dark"
                    ? "border: 2px solid rgba(94, 132, 195, 1)"
                    : "border: 2px solid #5a5a5a"};
                border-radius: 0%;
                transition: all 0.4s;
                -webkit-transition: all 0.4s;
                &:after {
                  content: "";
                  position: absolute;
                  display: none;
                  width: 0;
                  height: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .AllOptionBtn {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 0.7rem;
    color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    .pointCompleted {
      background-color: #2cbb22 !important;
      color: #fff !important;
    }
    .pointNotCompleted {
      background-color: red !important;
      color: #fff !important;
    }

    .add-camera_btn {
      padding: 0.6rem 0.7rem;
      border-radius: 4px;
      background-color: rgba(37, 68, 120, 1);
    }
    .add-camera_btn-dark {
      background-color: transparent;
    }
    .orange-text {
      color: white !important;
    }
    .specificOption {
      &:hover {
        .add-camera_btn {
          background-color: white;
        }
        .orange-text {
          color: rgba(37, 68, 120, 1) !important;
        }
      }
    }
    .active-page {
      background-color: ${(props) =>
        props.theme === "dark" ? "rgba(94, 132, 195, 1)" : "white"};
      color: rgba(37, 68, 120, 1) !important;
    }
  }
`;

export const VPSSessionContext = createContext({});
export const VPSSessionContainer = () => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [currentCameraSequenceIndex, setCurrentCameraSequenceIndex] =
    useState(0);
  const [VPSReportInstance, setVPSReportInstance] = useState(null);
  const [hasLoadedAllCachedState, setHasLoadedAllCachedState] = useState(false);
  const [activeVPSCameraSequence, setActiveVPSCameraSequence] = useState(null);
  const [VPSChecklistItems, setVPSChecklistItems] = useState(null);
  const [vpsCurrentVpsSessionId, setVpsCurrentVpsSessionId] = useState(null);

  const [isGenerateReportModalOpened, setIsGenerateReportModalOpened] =
    useState(false);
  const { VPSCameraSequence, fetchVPSCameraSequenceInstance } =
    useContext(VPSContext);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: VPSReportInstance?.isFetching,
      b: !hasLoadedAllCachedState,
      c: VPSCameraSequence?.isFetching,
    },
  });

  const createVPSReportInstance = useCallback(async () => {
    await (
      await axiosInstance()
    )
      .post(APIEndpoints.instance.vps.report.create())
      .then((response) => {
        APIUtils.fetchItemData({
          endpoint: APIEndpoints.instance.vps.report.fetch(response?.data?.id),
          item: VPSReportInstance,
          setItem: setVPSReportInstance,
        });
      })
      .catch((err) => {
        displayError(err, theme);
      });
  }, [VPSReportInstance]);

  const handleOnClickNext = async () => {
    if (currentCameraSequenceIndex + 1 < activeVPSCameraSequence.length) {
      setCurrentCameraSequenceIndex(currentCameraSequenceIndex + 1);
      setVpsCurrentVpsSessionId(VPSReportInstance?.data?.id);
    }
  };

  const handleGenerateReport = () => {
    if (VPSReportInstance) {
      let isStatesTrue = Object.values(
        VPSReportInstance?.data?.camera_sequences_completed_states
      ).some((value) => value === false);
      if (isStatesTrue) {
        displayError(
          'Please ensure all checkboxes are marked "Yes" before generating the report.',
          theme
        );
      } else {
        setIsGenerateReportModalOpened(true);
      }
    }
  };

  useEffect(() => {
    if (VPSCameraSequence?.isFetching === false) {
      setActiveVPSCameraSequence(
        VPSCameraSequence?.data.filter((item) => item?.checklist_items_count)
      );
    }
  }, [VPSCameraSequence]);

  useEffect(() => {
    fetchVPSCameraSequenceInstance({ ordering: "index_no" });
  }, []);

  useEffect(() => {
    setCurrentCameraSequenceIndex(0);
  }, [activeVPSCameraSequence]);

  useEffect(() => {
    if (activeVPSCameraSequence && hasLoadedAllCachedState) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.instance.vps.checklistItem.fetchAll({
          stream_sequence:
            activeVPSCameraSequence[currentCameraSequenceIndex]?.id,
          ordering: "index_no",
        }),
        item: VPSChecklistItems,
        setItem: setVPSChecklistItems,
      });
    }
  }, [currentCameraSequenceIndex, hasLoadedAllCachedState]);

  useEffect(() => {
    if (activeVPSCameraSequence) {
      if (vpsCurrentVpsSessionId) {
        APIUtils.fetchItemData({
          endpoint: APIEndpoints.instance.vps.report.fetch(
            vpsCurrentVpsSessionId
          ),
          item: VPSReportInstance,
          setItem: setVPSReportInstance,
        });
      } else {
        createVPSReportInstance();
      }
      setHasLoadedAllCachedState(true);
    }
  }, [activeVPSCameraSequence]);

  return (
    <>
      <ButtonWrapper>
        <Link
          className={`orange-text add-camera_btn ${
            theme === "dark" && "add-camera_btn-dark"
          }`}
          to={SubAdminSubUserRoutes.pathDefinitions.feature.vps.entry}
        >
          BACK
        </Link>
      </ButtonWrapper>
      <SizedBox height={1} />
      <VPSSessionContext.Provider
        value={{
          fetchVPSReportInstance: () => {
            APIUtils.fetchItemData({
              endpoint: APIEndpoints.instance.vps.report.fetch(
                VPSReportInstance?.data?.id
              ),
              item: VPSReportInstance,
              setItem: setVPSReportInstance,
            });
          },
        }}
      >
        <Wrapper theme={theme}>
          {canRender !== true ? (
            <>
              <SizedBox height={1} />
              <div className='spinnerWrapper'>
                {canRender === false && <Spinner size={2} />}
              </div>
            </>
          ) : (
            <>
              <div className='configureVpsOuter'>
                {(activeVPSCameraSequence || []).length && (
                  <div className='imageOuter'>
                    <StreamPlayerCanvasContainer
                      stream={
                        activeVPSCameraSequence[currentCameraSequenceIndex]
                          ?.stream
                      }
                    />
                    <span className='selectedCameraNumber'>
                      {currentCameraSequenceIndex + 1}
                    </span>
                  </div>
                )}
                <div className='vertical-line'></div>
                {VPSReportInstance?.isFetching !== false ? (
                  <div className='spinnerWrapper'>
                    {VPSReportInstance?.isFetching === true && (
                      <>
                        <SizedBox height={3} />
                        <Spinner size={2} />
                      </>
                    )}
                  </div>
                ) : (
                  <div className='VirtualCheckListOuter'>
                    <div className='VirtualCheckListMain'>
                      <h2 className='VirtualCheckListHeader'>
                        Virtual Check List
                      </h2>
                      <div className='VirtualCheckListContentOuter'>
                        <div className='VirtualCameraTitle'>
                          {VPSCameraSequence?.isFetching !== false ? (
                            <>
                              <SizedBox height={1} />
                              <div className='spinnerWrapper'>
                                {VPSCameraSequence?.isFetching === true && (
                                  <Spinner size={1.5} />
                                )}
                              </div>
                            </>
                          ) : (
                            (activeVPSCameraSequence || []).length && (
                              <h4>
                                {`Site ${currentCameraSequenceIndex + 1} : ${
                                  activeVPSCameraSequence[
                                    currentCameraSequenceIndex
                                  ]?.stream?.label_name
                                }`}
                              </h4>
                            )
                          )}
                        </div>

                        <ul className='VirtualCheckListOptions'>
                          {VPSChecklistItems?.isFetching !== false ? (
                            <>
                              <SizedBox height={1} />
                              <div className='spinnerWrapper'>
                                {VPSChecklistItems?.isFetching === true && (
                                  <Spinner size={1.5} />
                                )}
                              </div>
                            </>
                          ) : (
                            (VPSChecklistItems?.data || []).length &&
                            (activeVPSCameraSequence || []).length &&
                            VPSReportInstance?.data &&
                            (VPSChecklistItems?.data || []).map((item, id) => {
                              return (
                                <SessionEntity
                                  key={id}
                                  checkList={item}
                                  cameraSequence={
                                    activeVPSCameraSequence[
                                      currentCameraSequenceIndex
                                    ]
                                  }
                                  report={VPSReportInstance?.data}
                                />
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                    {(activeVPSCameraSequence || []).length && (
                      <div className='next-generate-btn'>
                        <button
                          className={`orange-text add-camera_btn d-block text-center ${
                            theme === "dark" && "add-camera_btn-dark"
                          } ${
                            currentCameraSequenceIndex + 1 <
                            activeVPSCameraSequence.length
                              ? ""
                              : VPSReportInstance?.data?.is_completed
                              ? ""
                              : "disabled-button"
                          }`}
                          // to=''
                          onClick={
                            VPSReportInstance?.data &&
                            currentCameraSequenceIndex + 1 >=
                              activeVPSCameraSequence.length &&
                            VPSReportInstance?.data?.is_completed
                              ? handleGenerateReport
                              : handleOnClickNext
                          }
                        >
                          {VPSReportInstance?.isFetching !== false ? (
                            <>
                              {/* <SizedBox height={1} />
                              <div className='spinnerWrapper'> */}
                              {VPSReportInstance?.isFetching === true && (
                                <Spinner size={2} />
                              )}
                              {/* </div> */}
                            </>
                          ) : (
                            VPSReportInstance?.data &&
                            (currentCameraSequenceIndex + 1 >=
                              activeVPSCameraSequence.length &&
                            VPSReportInstance?.data?.is_completed
                              ? "GENERATE REPORT"
                              : "NEXT")
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='AllOptionBtn'>
                Virtual Point Completed:
                {VPSCameraSequence?.isFetching !== false ? (
                  <>
                    <SizedBox height={1} />
                    <div className='spinnerWrapper'>
                      {(VPSReportInstance?.isFetching === true ||
                        VPSCameraSequence?.isFetching === true) && (
                        <Spinner size={2} />
                      )}
                    </div>
                  </>
                ) : (
                  VPSReportInstance?.data &&
                  (activeVPSCameraSequence || []).length &&
                  activeVPSCameraSequence?.map((item, i) => {
                    const state = (VPSReportInstance?.data
                      ?.camera_sequences_completed_states || {})[
                      (item?.id).toString()
                    ];
                    return (
                      <span
                        key={i}
                        className={`specificOption ${state ? "active" : ""}`}
                      >
                        <button
                          className={`orange-text add-camera_btn
                                                    
                                                    ${
                                                      theme === "dark" &&
                                                      "add-camera_btn-dark"
                                                    }
                                                   ${
                                                     currentCameraSequenceIndex ===
                                                       i && "active-page"
                                                   } 
                                                  ${
                                                    typeof state == "boolean"
                                                      ? state
                                                        ? "pointCompleted"
                                                        : "pointNotCompleted"
                                                      : ""
                                                  }`}
                          to=''
                          onClick={() => {
                            setCurrentCameraSequenceIndex(i);
                            setVpsCurrentVpsSessionId(
                              VPSReportInstance?.data?.id
                            );
                          }}
                        >
                          {i + 1}
                        </button>
                      </span>
                    );
                  })
                )}
              </div>
              <ModalComponent
                modalLabel={"Generate Report"}
                isOpen={isGenerateReportModalOpened}
                ClassName={"rectification_model"}
                callbackCloseModal={() => {
                  setIsGenerateReportModalOpened(false);
                }}
              >
                <GenerateReportModal
                  VPSReportInstance={VPSReportInstance?.data}
                />
              </ModalComponent>
            </>
          )}
        </Wrapper>
      </VPSSessionContext.Provider>
    </>
  );
};
