import '../../styles/account_auth.css'
import LoginIcon from '../../static/svg/login.png'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axiosInstance from '../../config/axios'
import displayError from '../../helpers/displayError'
import Spinner from '../Spinner'
import { fetchProfile } from '../../config/actions'
import { useDispatch } from 'react-redux'
import APIEndpoints from '../../config/api/endpoints'
import { AuthUtilsHelper } from '../../helpers/utils/auth'
import { useSelector } from 'react-redux'
import { ThemeComponent } from '../global/toggletheme'
import { DarkThemeAuth } from '../global/graphical/DarkThemeAuth'

const Login = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()

    const handleLogin = async () => {
        setProcessing(true)
        const requestPayload = {
            login: username,
            password,
        }
        ;(await axiosInstance())
            .post(APIEndpoints.auth.me.login(), requestPayload)
            .then(response => {
                const { refresh, access } = response.data
                window.localStorage.setItem('access_token', access)
                window.localStorage.setItem('refresh_token', refresh)
                AuthUtilsHelper.clearRelationAuth()
                setProcessing(false)
                dispatch(fetchProfile)
                navigate('/account/dashboard', { replace: true })
            })
            .catch(err => {
                displayError(err, theme)
                setProcessing(false)
            })
    }

    return (
        <div
            id='body-2'
            style={
                theme === 'dark'
                    ? {
                          background: `linear-gradient(
to right bottom,
rgb(32, 45, 66),
rgb(22, 33, 49),
rgb(20, 45, 49)`,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
                    : {
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
        >
            <div className='toggleBtnAuth'>
                <ThemeComponent />
            </div>

            <DarkThemeAuth>
                <div
                    className={`main-frame ${
                        theme === 'dark' && 'main-frame-dark'
                    }`}
                >
                    <div className='form-wrapper'>
                        <form className='d-flex flex-column align-items-start w-100'>
                            <div
                                className='desktop'
                                style={{ width: '100px', height: '100px' }}
                            >
                                <img
                                    src={LoginIcon}
                                    alt=''
                                    className='w-100 h-100,'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-5'>
                                <span
                                    className={`highlight-heading ${
                                        theme === 'dark' &&
                                        'highlight-heading-dark'
                                    } text-auth-title`}
                                >
                                    nettbox
                                </span>
                            </div>

                            <div className='my-4 w-100'>
                                <label
                                    htmlFor='username'
                                    className={`form-label form-label-custom highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Email or Username
                                </label>
                                <input
                                    disabled={processing}
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    type='text'
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='username'
                                    aria-describedby='emailHelp'
                                    placeholder='Your email here...'
                                />
                            </div>
                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='password'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Password
                                </label>
                                <input
                                    disabled={processing}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type='password'
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='password'
                                    placeholder='Your password here...'
                                />
                            </div>
                            <div className='d-flex my-3'>
                                <button
                                    disabled={processing}
                                    type='submit'
                                    onClick={e => {
                                        e.preventDefault()
                                        handleLogin()
                                    }}
                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                        theme === 'dark' &&
                                        'btn-custom-orange-dark'
                                    }`}
                                >
                                    Login
                                </button>
                            </div>

                            <div className='mx-5 mb-5'>
                                {processing && (
                                    <Spinner size={1.2} rot_time={0.5} />
                                )}
                            </div>
                        </form>
                    </div>
                    <div
                        className={`divider ${
                            theme === 'dark' && 'divider-dark'
                        }`}
                    ></div>
                    <div
                        className='flex-properties py-2'
                        style={{
                            gap: '0.5rem',
                        }}
                    >
                        <div className='d-flex flex-column align-items-start mb-4'>
                            <span
                                className={`orange-text text-small d-block ${
                                    theme === 'dark' && 'orange-text-dark'
                                }`}
                            >
                                Forgot Password?
                            </span>
                            <span
                                className={`text-small mobile ${
                                    theme === 'dark' && 'text-small-dark'
                                }`}
                            >
                                Click the button below
                            </span>
                        </div>
                        {!processing && (
                            <Link to='/forgot_password' className='mobile'>
                                <button
                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                        theme === 'dark' &&
                                        'btn-custom-orange-dark'
                                    }`}
                                >
                                    click here
                                </button>
                            </Link>
                        )}
                        {!processing && (
                            <Link
                                to='/forgot_password'
                                className={`black-text text-small desktop ${
                                    theme === 'dark' && 'black-text-dark'
                                }`}
                            >
                                click here
                            </Link>
                        )}
                    </div>
                </div>
            </DarkThemeAuth>
        </div>
    )
}

export default Login
