import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { GlobalUserProfileContext } from "../../../../../App";
import APIEndpoints from "../../../../../config/api/endpoints";
import axiosInstance from "../../../../../config/axios";
import { fireAlertConfirmDeletion } from "../../../../../helpers/alertConfirmDeletion";
import fireSpinnerAlert from "../../../../../helpers/alertSpinner";
import displayError from "../../../../../helpers/displayError";
import { useLoadDependenciesBeforeRender } from "../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { ProjectColorScheme } from "../../../../../helpers/ParseProjectColors";
import { APIUtils } from "../../../../../helpers/utils/api";
import { RouteUtils } from "../../../../../routes";
import { SubAdminSubUserRoutes } from "../../../../../routes/SubAdmin&SubUserRoutes";
import Spinner from "../../../../Spinner";
import EditIcon from "../../../../svgTojsx/EditIcon";
import { ConfigIcon } from "../../../../svgTojsx/ConfigIcon";

export const MapStreams = ({ obj, fetchStreams, theme, projects }) => {
  const { profile } = useContext(GlobalUserProfileContext);
  const [data, setData] = useState(null);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: data?.isFetching,
    },
  });

  const handleDeleteStream = async (e) => {
    e.preventDefault();
    let confirmMessage = `Are you sure you want to delete stream '${data?.data?.label_name}'?`;
    let { isConfirmed } = await fireAlertConfirmDeletion({
      content: confirmMessage,
    });

    if (isConfirmed) {
      const alert = fireSpinnerAlert({ title: "Deleting Stream" });
      (await axiosInstance())
        .delete(APIEndpoints.instance.stream.main.delete(data?.data?.id))
        .then((response) => {
          fetchStreams();
          alert.close();
        })
        .catch((err) => {
          displayError(err, theme);
        });
    }
  };

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.instance.stream.main.get(obj.id),
        item: data,
        setItem: setData,
      });
    }
  }, [profile, obj]);

  return canRender === true ? (
    <tr className='row-spacing'>
      <td
        style={{
          borderLeft: "16px solid",
          borderColor: `${ProjectColorScheme.parseHue(
            data?.data?.project?.color,
            projects
          )}`,
        }}
      >
        <div className='d-flex align-items-center justify-content-center'>
          <h6>{data?.data?.project?.name}</h6>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center justify-content-center'>
          <h6> {data?.data?.label_name}</h6>
        </div>
      </td>

      <td>{data?.data?.owner?.id}</td>
      <td>
        {moment(data?.data?.date_added).local().format("DD-MM-YYYY HH:mm")}
      </td>
      <td>
        <div className='d-flex align-items-center justify-content-center'>
          <Link
            to={RouteUtils.parseKwargsToRoute(
              { id: data?.data?.id },
              SubAdminSubUserRoutes.pathDefinitions.stream.viewAnalytics.list
            )}
            className='camera-control-icon d-flex align-items-center justify-content-center'
          >
            <ConfigIcon theme={theme} />
          </Link>

          <Link
            to={RouteUtils.parseKwargsToRoute(
              { id: data?.data?.id },
              SubAdminSubUserRoutes.pathDefinitions.stream.updateStream
            )}
            className='camera-control-icon d-flex align-items-center justify-content-center'
          >
            <EditIcon theme={theme} />
          </Link>

          <a
            onClick={handleDeleteStream}
            className='material-icons camera-control-icon red-button'
            style={{
              fontFamily: "Material Icons",
              fontSize: "24px",
              marginLeft: "-0.3rem",
              cursor: "pointer",
            }}
          >
            delete
          </a>
        </div>
      </td>
    </tr>
  ) : (
    <div
      className={` ${
        theme === "dark" && "dashpanel-dark"
      } p-2 p-md-3 d-flex flex-column align-items-center justify-content-center`}
      style={{ backgroundColor: "transparent" }}
    >
      {canRender === false && <Spinner size={1.5} />}
    </div>
  );
};
