import '../../../styles/sub_user.css'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useCallback, useContext, useEffect, useState } from 'react'
import { GlobalUserProfileContext } from '../../../App'
import { APIUtils } from '../../../helpers/utils/api'
import axiosInstance from '../../../config/axios'
import APIEndpoints from '../../../config/api/endpoints'
import displayError from '../../../helpers/displayError'
import { fireAlertConfirmDeletion } from '../../../helpers/alertConfirmDeletion'
import fireSpinnerAlert from '../../../helpers/alertSpinner'
import loadash from 'lodash'
import Spinner from '../../Spinner'
import { AdminRoutes } from '../../../routes/AdminRoutes'
import { UserAccountType } from '../../../config/enums/user'
import { SubAdminRoutes } from '../../../routes/SubAdminRoutes'
import { RouteUtils } from '../../../routes'
import {
    InstancesAvailable,
    InstancesAvailableGVariableKey,
} from '../../../config/enums/instance'
import { AuthUtilsHelper } from '../../../helpers/utils/auth'
import { useSelector } from 'react-redux'

export const UserListViewPanel = ({ obj, fetchAllUsers, panelChildren }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { profile } = useContext(GlobalUserProfileContext)
    const [data, setData] = useState(null)
    const [instanceStatsData, setInstanceStatsData] = useState({})
    const fetchInstanceStatsData = useCallback(
        async instance => {
            let oldState = {
                ...instanceStatsData,
            }
            oldState = APIUtils.dataStateMutator(
                oldState,
                { isFetching: instance || true },
                setInstanceStatsData
            )
            await (
                await axiosInstance()
            )
                .get(
                    APIEndpoints.user.account.instance.statistics.getRelation(
                        obj.id
                    )
                )
                .then(response => {
                    oldState = APIUtils.dataStateMutator(
                        oldState,
                        { data: response.data },
                        setInstanceStatsData
                    )
                })
                .catch(err => {
                    displayError(err, theme)
                })
            APIUtils.dataStateMutator(
                oldState,
                { isFetching: false },
                setInstanceStatsData
            )
        },
        [instanceStatsData]
    )

    const fetchData = useCallback(async () => {
        let oldState = {
            ...data,
        }
        oldState = APIUtils.dataStateMutator(
            oldState,
            { isFetching: true },
            setData
        )
        await (
            await axiosInstance()
        )
            .get(APIEndpoints.auth.relation.getRelation(obj.id))
            .then(response => {
                oldState = APIUtils.dataStateMutator(
                    oldState,
                    { data: response.data },
                    setData
                )
            })
            .catch(err => {
                displayError(err, theme)
            })
        APIUtils.dataStateMutator(oldState, { isFetching: false }, setData)
    }, [data])

    const toggleAccountState = useCallback(async () => {
        if (data?.data && data?.isFetching === false) {
            await (
                await axiosInstance()
            )
                .patch(
                    APIEndpoints.auth.relation.editRelation(data?.data?.id),
                    {
                        is_active: !data?.data.is_active,
                    }
                )
                .then(response => {
                    fetchData()
                })
                .catch(err => {
                    displayError(err, theme)
                })
        }
    }, [data])

    const handleDeleteUser = async e => {
        e.preventDefault()
        if (data?.data && data?.isFetching === false) {
            let confirmMessage = `Are you sure you want to delete user '${data?.data?.username}'?`
            let { isConfirmed } = await fireAlertConfirmDeletion({
                content: confirmMessage,
            })
            if (isConfirmed) {
                const alert = fireSpinnerAlert({ title: 'Deleting User' })
                await (
                    await axiosInstance()
                )
                    .delete(
                        APIEndpoints.auth.relation.deleteRelation(
                            data?.data?.id
                        )
                    )
                    .then(response => {
                        fetchAllUsers()
                        alert.close()
                    })
                    .catch(err => {
                        displayError(err, theme)
                    })
            }
        }
    }

    const onClickUser = e => {
        e.preventDefault()
        AuthUtilsHelper.transverseToRelationProfile({
            currentProfile: profile,
            dispatch,
            navigate,
            userID: obj?.id,
        })
    }

    useEffect(() => {
        if (profile) {
            fetchData()
            fetchInstanceStatsData()
        }
    }, [])

    return data?.isFetching === false ? (
        <div
            className={`dashpanel ${
                theme === 'dark' && 'dashpanel-dark'
            } p-2 p-md-3`}
        >
            <div className='title d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                    <Link
                        to={`/`}
                        className={`title clickable ${
                            theme === 'dark' && 'clickable-dark'
                        }`}
                        onClick={onClickUser}
                    >
                        {data?.data?.username}
                    </Link>
                    <div className='sub_admin_picture_wrapper_list m-2'>
                        <img
                            src={data?.data?.picture?.file}
                            alt={'No Profile Pic'}
                        />
                    </div>
                </div>
                <div>
                    <div className='d-flex'>
                        <a
                            href='#'
                            onClick={handleDeleteUser}
                            className='material-icons camera-control-icon red-button'
                        >
                            delete
                        </a>
                        <Link
                            to={RouteUtils.parseKwargsToRoute(
                                { id: data?.data?.id },
                                profile?.account_type ===
                                    UserAccountType.ADMINISTRATOR
                                    ? AdminRoutes.pathDefinitions
                                          .viewSingleSubAdmin
                                    : SubAdminRoutes.pathDefinitions
                                          .viewSingleSubUser
                            )}
                            className='material-icons camera-control-icon'
                            style={{
                                color:
                                    theme === 'dark'
                                        ? 'rgba(94, 132, 195, 1)'
                                        : '',
                            }}
                        >
                            edit
                        </Link>
                    </div>
                </div>
            </div>

            {instanceStatsData?.isFetching === false && (
                <>
                    <div className='content px-3'>
                        Granted Stream Instances :{' '}
                        {instanceStatsData?.data &&
                            loadash.sum(
                                InstancesAvailableGVariableKey.keys()
                                    .filter(
                                        instance =>
                                            instance.toLowerCase() ===
                                            InstancesAvailable.STREAM
                                    )
                                    .map(
                                        instance =>
                                            instanceStatsData?.data?.assigned[
                                                instance.toLowerCase()
                                            ] || 0
                                    )
                            )}
                    </div>

                    <div className='content px-3'>
                        Granted Analytic Instances :{' '}
                        {instanceStatsData?.data &&
                            loadash.sum(
                                InstancesAvailableGVariableKey.keys()
                                    .filter(
                                        instance =>
                                            instance.toLowerCase() !==
                                            InstancesAvailable.STREAM
                                    )
                                    .map(
                                        instance =>
                                            instanceStatsData?.data?.assigned[
                                                instance.toLowerCase()
                                            ] || 0
                                    )
                            )}
                    </div>
                </>
            )}
            {panelChildren &&
                panelChildren({
                    toggleAccountState,
                    data,
                    handleDeleteUser,
                    onClickUser,
                })}
        </div>
    ) : (
        <div
            className={`dashpanel ${
                theme === 'dark' && 'dashpanel-dark'
            } card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center`}
        >
            {data?.isFetching === true && <Spinner size={1.5} />}
        </div>
    )
}
