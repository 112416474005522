import axios from 'axios'
import { AppConfig } from '..'
import { accessTokenCheckExistence } from '../../conditions'
import { APIAuthUtils } from '../../helpers/utils/api/auth'

const axiosInstance = async (customAccessToken, includeSecretKey = false) => {
    if (!customAccessToken && !(await accessTokenCheckExistence)) {
        APIAuthUtils.clearSessionAndMoveToLoginPage()
    } else {
        const axiosInst = axios.create({
            baseURL: `${AppConfig.MAIN_HTTP_SERVER_URL}/api/v1`,
            timeout: 30000,
            headers: {
                'Authorization': customAccessToken
                    ? `Bearer ${customAccessToken}`
                    : window.localStorage.getItem('access_token')
                    ? `Bearer ${window.localStorage.getItem('access_token')}`
                    : null,
                'Content-Type': 'application/json',
                'accept': 'application/json',
                ...(includeSecretKey
                    ? { 'secret-key': AppConfig.MAIN_SERVER_SECRET_KEY }
                    : {}),
                // ...((contentDisposition) ? {'Content-Disposition': contentDisposition} : {})
            },
        })

        axiosInst.interceptors.response.use(
            response => {
                // Any status code from range of 2xx
                // Do something with response data
                if (response.data.results) {
                    response.extraData = { ...response.data }
                    response.data = response.data.results
                }
                return response
            },
            async error => {
                // Any status codes outside range of 2xx
                // Do something with response error
                return Promise.reject(error)
            }
        )

        axiosInst.interceptors.request.use(
            function (config) {
                // If the request contains FormData, set the Content-Type to 'multipart/form-data'
                if (config.data instanceof FormData) {
                    config.headers['Content-Type'] = 'multipart/form-data'
                }

                config.url = config.url.replace(config.baseURL, '')
                return config
            },
            function (error) {
                return Promise.reject(error)
            }
        )

        return axiosInst
    }
}

export default axiosInstance
