import { useContext } from "react";
import { GlobalUserProfileContext } from "../../../../App";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AuthUtilsHelper } from "../../../../helpers/utils/auth";
import { UserProfile } from "../userprofile/UserProfile";
import { ThemeComponent } from "../../toggletheme";

const StyledComponent = styled.div`
  gap: 0.5rem;
  position: relative;
  z-index: 2;
  margin-right: 0.5rem;
  a {
    text-decoration: none;
    color: black;
  }
  .pointer {
    cursor: pointer;
  }

  .light-dark-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    background: transparent;
    border-radius: 0.375rem;
    cursor: pointer;
    :hover {
      background: rgb(208, 208, 209);
    }
  }

  i {
    color: ${(props) =>
      props.theme === "dark" ? "rgba(94, 132, 195, 1)" : "rgb(37, 68, 120)"};
  }

  .username-nav {
    color: ${(props) =>
      props.theme === "dark" ? "rgba(94, 132, 195, 1)" : "rgb(37, 68, 120)"};
  }

  .fa-lg {
    font-size: 1.8rem !important;
  }
`;

export const NavigationComponent = ({ theme }) => {
  const { parentProfile, profile } = useContext(GlobalUserProfileContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickParentButton = (parentID) => {
    AuthUtilsHelper.transverseToRelationProfile({
      currentProfile: profile,
      dispatch,
      navigate,
      userID: parentID,
    });
  };

  return (
    <StyledComponent
      className='d-flex align-items-center justify-content-center crumbs'
      theme={theme}
    >
      {profile && [
        parentProfile &&
          Object.keys(parentProfile).map((key) => {
            const parentData = parentProfile[key];
            return (
              <Link
                to='/'
                className='orange-text d-flex flex-column align-items-center'
                onClick={(e) => {
                  e.preventDefault();
                  onClickParentButton(parentData?.id);
                }}
              >
                <i className='fas fa-sign-out-alt fa-lg'></i>
                <span className='username-nav'>{parentData?.username}</span>
              </Link>
            );
          }),
      ]}

      <div className='header-hide'>
        <ThemeComponent />
      </div>
      <UserProfile theme={theme}></UserProfile>
    </StyledComponent>
  );
};
