export class APIEndpointInstanceStreamAnalyticsFireSmokeDetectionInstance {
  static getForStream(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/stream/${id}/instance/`;
  }

  static get(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/instance/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/instance/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/instance/${id}/`;
  }
}
