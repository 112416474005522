import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import APIEndpoints from "../../../../config/api/endpoints";
import Spinner from "../../../Spinner";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../config/axios";
import { StreamPlayer } from "../livestream/StreamPlayer";
import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import { GlobalVideoBackupContext } from "./VideoBackup";

const StyledWrapper = styled(motion.div)`
  background-color: rgb(255, 255, 255);
  grid-area: ${({ gridArea }) => gridArea};
  border-radius: 10px;
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .streamWrapper {
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(30, 30, 30, 0.7)"};
    flex-grow: 1;
    position: relative;
    border-radius: 10px;
    height: 70%;
    width: 100%;
  }
`;

export const CameraStreamGridObject = ({ stream, gridArea }) => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isLoading, setIsLoading] = useState(true);
  const { streamIdsRef, filterData, setPlaying } = useContext(
    GlobalVideoBackupContext
  );
  const [backupHLS, setBackupHLS] = useState(null);

  const getBackupHlsUrl = useCallback(async () => {
    setIsLoading(true);
    const endpoint = APIEndpoints.instance.stream.main.getBackupHlsUrl(
      stream?.label
    );
    const maxRetries = 3;
    let attempts = 0;
    const payload = {
      from_time: filterData?.from_time,
      to_time: filterData?.to_time,
    };
    const fetchUrl = async () => {
      setPlaying(false);
      (await axiosInstance())
        .post(endpoint, payload)
        .then((response) => {
          // setIsLoading(false)
          if (response.data.url) {
            setBackupHLS(response.data.url);
            streamIdsRef.current.add(stream?.id);
            setIsLoading(false);
            setPlaying(true);
          }
        })
        .catch((err) => {
          attempts++;
          if (attempts < maxRetries) {
            fetchUrl();
          } else {
            setBackupHLS(null);
            setIsLoading(false);
            setPlaying(false);
          }
        });
    };

    fetchUrl(); // Initial call to fetch the URL
  }, []);

  useEffect(() => {
    // Fetch the initial video URL
    getBackupHlsUrl();

    // Set up timeout to refresh the video URL before it expires (e.g., after 55 minutes)
    const timeoutId = setTimeout(() => {
      getBackupHlsUrl();
    }, 58 * 60 * 1000); // 58 minutes

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <AnimatePresence>
      <StyledWrapper
        className={"grid-item livestream-grid-item"}
        gridArea={gridArea}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        theme={theme}
      >
        <div className='streamWrapper'>
          {isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center w-100 h-100'
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "1000",
              }}
            >
              <Spinner size={1.5} color='white' />
            </div>
          ) : (
            <StreamPlayer
              liveHls={backupHLS}
              debug={true}
              reload={getBackupHlsUrl}
              progressControl={false}
              playToggle={false}
            />
          )}
        </div>
      </StyledWrapper>
    </AnimatePresence>
  );
};
