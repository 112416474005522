import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import LogoutIcon from '../../svgTojsx/LogoutIcon'

const StyledComponents = styled.div`
    padding: 1.5rem 0rem;
    font-family: var(--font-1);
    width: 100%;
    background-color: var(--custom-btn-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    justify-self: flex-end;
    .footerLogout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    .footerLogout {
        a {
            letter-spacing: 0.3px;
            color: var(--custom-white);
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            text-transform: uppercase;
        }
    }

    .deutics-link {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.5);
        &:hover {
            color: white;
        }
    }
    .copyrights {
        color: rgba(255, 255, 255, 0.5);
        font-family: 'Poppins';
        text-align: center;
        font-size: 13px;
        font-weight: 200;
        margin-top: 0.5rem;
    }

    ${({ desktopToggle }) =>
        desktopToggle &&
        css`
            .nav-label {
                display: none;
            }
        `}
`

export const FooterContainer = ({ desktopToggle }) => {
    return (
        <StyledComponents desktopToggle={desktopToggle}>
            <div className='footerLogout'>
                <Link
                    to='/logout'
                    className='orange-text text-small side-nav-logout'
                >
                    {LogoutIcon()}
                </Link>
                <small className='nav-label'>Log Out</small>
            </div>
            <span className='copyrights'>
                Powered by{' '}
                <a
                    href='https://deutics.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='deutics-link'
                >
                    Deutics Global
                </a>
            </span>
        </StyledComponents>
    )
}
