export class APIEndpointInstanceStreamAnalyticsHeaMapsInstance {
  static getForStream(id) {
    return `/instance/stream/analytic/heatmap/stream/${id}/instance/`;
  }

  static generate() {
    return `/instance/stream/analytic/heatmap/instance/get_heatmap_frame/`;
  }

  static get(id) {
    return `/instance/stream/analytic/heatmap/instance/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/heatmap/instance/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/heatmap/instance/${id}/`;
  }
}
