import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const SearchComponent = ({ searchParam }) => {
    const [searchInput, setSearchInput] = useState('')
    const { theme } = useSelector(state => state.themeReducer)
    const handleSearchInputChange = e => {
        setSearchInput(e.target.value)
        if (!e.target.value) {
            searchParam('')
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        searchParam(searchInput)
    }
    return (
        <StyledComponent theme={theme}>
            <div className='main-search'>
                <form onSubmit={onSubmit} className='searchForm'>
                    <input
                        type='search'
                        placeholder='Search'
                        className='studentSearchBar'
                        value={searchInput}
                        onChange={handleSearchInputChange}
                    />
                    <button type='submit'></button>
                </form>
            </div>
        </StyledComponent>
    )
}

const StyledComponent = styled.div`
    .main-search {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .searchForm {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        z-index: 0;
        border-bottom: ${props =>
            props.theme === 'dark'
                ? '1.5px solid rgba(94, 132, 195, 1)'
                : '1.5px solid rgb(37, 68, 120)'};

        input {
            border-bottom: none !important;
            box-shadow: none !important;
            height: auto !important;
            font-size: 12px;
            color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
        }
    }

    @media (min-width: 601px) {
        .searchForm {
            margin-left: 0px;
            margin-right: 0px;
            margin-right: 0.5rem;
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .searchForm {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            width: 193px;
        }
    }

    .studentSearchBar {
        width: 100%;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 300;
        font-style: italic;
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

    .studentSearchBar::-moz-placeholder {
        font-weight: 300;
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
    }

    .studentSearchBar::placeholder {
        font-weight: 300;
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
    }

    @media (min-width: 601px) {
        .studentSearchBar::-moz-placeholder {
            font-size: 10px;
        }
        .studentSearchBar::placeholder {
            font-size: 10px;
        }
    }

    button[type='submit'] {
        text-indent: -999px;
        overflow: hidden;
        width: 20px;
        padding: 0;
        margin: 0;
        ${props =>
            props.theme === 'dark'
                ? `background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' class='bi bi-search' viewBox='0 0 16 16' fill='white'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat center;`
                : `background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' class='bi bi-search' viewBox='0 0 16 16' fill='rgb(37, 68, 120)'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat center;`}

        cursor: pointer;
        opacity: 0.7;
        border: none;
    }

    button[type='submit']:hover {
        opacity: 1;
    }

    @media only screen and (max-width: 400px) {
        .searchForm {
            width: 100%;
        }
    }
`
