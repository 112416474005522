export const saveToLocalStorage = (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data));
}


export const retrieveFromLocalStorage = (key) => {
    let item = window.localStorage.getItem(key);
    try {
        return item && JSON.parse(item)
    } catch (e) {
        return item
    }
}


export const deleteFromLocalStorage = (key) => {
    window.localStorage.removeItem(key);
}



