import {APIEndpointsHelper} from "../../index";

export class APIEndpointUtilsTypes {
    static getAll() {
        return `/utils/type/objects/`
    }

    static get(key) {
        const params = {reference: key}
        return APIEndpointsHelper.parseEndpointForQueryParams('/utils/type/object/', params)
    }
}