import '../../../../../../../../styles/analytic.css'
import '../../../../../../../../styles/literallycanvas.css'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import LC from 'literallycanvas/lib/js/literallycanvas-core'
import { ZoneIntrusionDetectorConfigContext } from '../Container'
import { StreamPlayerCanvasContainer } from '../../../../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer'

const ZoneIntrusionDetectorCanvasBoundaryZone = ({ stream }) => {
    const [pauseStream, setPauseStream] = useState(null)
    const canvasInitialized = useRef(false)
    const hasRenderedPreviousOnce = useRef(false)
    const [gCanvas, setgCanvas] = useState(null)
    const { boundaryZoneData, setBoundaryZoneData } = useContext(
        ZoneIntrusionDetectorConfigContext
    )

    const constructZoneBoundary = useCallback(() => {
        if (boundaryZoneData.length >= 0 && gCanvas) {
            setgCanvas(false)
            const canvas = document.querySelector(
                '#people-counter-canvas-boundary-zone'
            )
            const { width, height } = canvas.getBoundingClientRect()
            var points = []
            boundaryZoneData.forEach(item => {
                points.push({
                    x: (item.x_coordinate || 0) * width,
                    y: (item.y_coordinate || 0) * height,
                })
            })
            const polygonShape = LC.createShape('Polygon', {
                points: points,
                color: '#ff0000',
                strokeWidth: 2,
                strokeColor: '#ff0000',
                fillColor: 'transparent',
            })

            gCanvas.saveShape(polygonShape)
        }
    }, [boundaryZoneData, gCanvas])

    useEffect(() => {
        if (gCanvas && boundaryZoneData) {
            gCanvas.clear()
            constructZoneBoundary()
        }
    }, [boundaryZoneData, gCanvas])

    useEffect(() => {
        if (!hasRenderedPreviousOnce.current && boundaryZoneData && gCanvas) {
            constructZoneBoundary()
            hasRenderedPreviousOnce.current = true
        }
    }, [gCanvas, boundaryZoneData])

    useEffect(() => {
        if (
            document.querySelector('#people-counter-canvas-boundary-zone') &&
            !canvasInitialized.current
        ) {
            const canvas = document.querySelector(
                '#people-counter-canvas-boundary-zone'
            )

            const lc = LC.init(canvas, {
                keyboardShortcuts: true,
                primaryColor: '#ff0000',
                tools: [LC.tools.Polygon],
                defaultStrokeWidth: 2,
                secondaryColor: 'transparent',
            })

            setgCanvas(lc)
            const isFirstLastWithinDifference = coordinatesArray => {
                const firstCoordinate = coordinatesArray[0]
                const lastCoordinate =
                    coordinatesArray[coordinatesArray.length - 1]

                const xDifference = Math.abs(
                    firstCoordinate.x - lastCoordinate.x
                )
                const yDifference = Math.abs(
                    firstCoordinate.y - lastCoordinate.y
                )

                return xDifference <= 5 && yDifference <= 5
            }
            let actualAddedPoints = []
            let polygonPoints = []
            canvas.addEventListener('pointerdown', handlePointerDown)

            function handlePointerDown(e) {
                setPauseStream(true)

                const { offsetX, offsetY } = e

                const { width, height } = canvas.getBoundingClientRect()
                const mappedPoint = {
                    x_coordinate: offsetX / width,
                    y_coordinate: offsetY / height,
                }

                if (polygonPoints.length === 0) {
                    lc.clear()
                }
                actualAddedPoints.push(mappedPoint)

                polygonPoints.push({ x: offsetX, y: offsetY })

                if (
                    polygonPoints.length > 3 &&
                    isFirstLastWithinDifference(polygonPoints)
                ) {
                    setPauseStream(false)

                    setBoundaryZoneData(actualAddedPoints)
                    polygonPoints = []
                    actualAddedPoints = []
                }
            }

            canvasInitialized.current = true
        }
    }, [])

    return (
        <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
            <div className='people-counter-canvas-width-only text-analytic-header d-flex justify-content-start'>
                Boundary Zone
            </div>
            <div className='people-counter-canvas'>
                <div className='wrapper'>
                    <StreamPlayerCanvasContainer stream={stream} />
                </div>
                <div
                    className='wrapper'
                    id={'people-counter-canvas-boundary-zone'}
                ></div>
            </div>
        </div>
    )
}

export default ZoneIntrusionDetectorCanvasBoundaryZone
