import {BaseEnum} from "./index";

export class ResponseStatusCode extends BaseEnum {
    static SUCCESS = 200
    static CREATED = 201
    static BAD_REQUEST = 400
    static UNAUTHORIZED = 401
    static FORBIDDEN = 403
    static NOT_FOUND = 404
    static INTERNAL_SERVER_ERROR = 500
}