import { useState } from 'react'
import axiosInstance from '../../../config/axios'
import displayError from '../../../helpers/displayError'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Spinner'
import APIEndpoints from '../../../config/api/endpoints'
import { HandleOnChangeInput } from '../../../helpers/utils/formInput/HandleOnChangeInput'
import { useLoadDependenciesBeforeRender } from '../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { useSelector } from 'react-redux'

export const AddUserForm = ({ redirectOnSuccess }) => {
    const navigate = useNavigate()
    const { theme } = useSelector(state => state.themeReducer)
    const [formData, setFormData] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: isProcessing,
        },
    })

    const handleRegister = async e => {
        e.preventDefault()
        setIsProcessing(true)
        await (
            await axiosInstance()
        )
            .post(APIEndpoints.auth.me.register(), formData)
            .then(response => {
                redirectOnSuccess &&
                    navigate(redirectOnSuccess, { replace: true })
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsProcessing(false)
    }

    const handleKeyPress = e => {
        if (e.charCode == 13 && !isProcessing) {
            handleRegister(e)
        }
    }

    return (
        <>
            <div className='w-100 d-flex'>
                <button
                    disabled={isProcessing}
                    onClick={handleRegister}
                    type='submit'
                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                        theme === 'dark' && 'btn-custom-orange-dark'
                    }`}
                >
                    Save
                </button>
            </div>
            {canRender !== true ? (
                <div className='w-100 h-100 d-flex justify-content-center align-items-center my-5 '>
                    {canRender === false && <Spinner />}
                </div>
            ) : (
                <div className='grid-form-camera my-3 mt-5'>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='username'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Username
                        </label>
                        <input
                            value={formData?.username}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'username',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='username'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='email'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Email Address
                        </label>
                        <input
                            value={formData?.email}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'email',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='email'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='password1'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Password
                        </label>
                        <input
                            value={formData?.password}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'password',
                                    setFormData,
                                    formData
                                )
                            }
                            type='password'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='password1'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='password2'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Confirm Password
                        </label>
                        <input
                            value={formData?.confirm_password}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'confirm_password',
                                    setFormData,
                                    formData
                                )
                            }
                            type='password'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='password2'
                        />
                    </div>
                </div>
            )}
        </>
    )
}
