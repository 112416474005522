import '../../../../../styles/livestream.css'
import '../../../../../styles/archive.css'
import { StreamPlayerCanvasContainer } from '../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer'
import styled from 'styled-components'
import { ProjectColorScheme } from '../../../../../helpers/ParseProjectColors'

import { useDispatch, useSelector } from 'react-redux'
import { GlobalUserProfileContext } from '../../../../../App'
import { useContext, useEffect } from 'react'
import { projectsList } from '../../../../../config/actions'

const WrapperHeader = styled.div`
    .wrapper-header {
        background-color: ${({ decorator }) => decorator};
    }
`

export const StreamMediaLivePanel = ({ stream, liveHls }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )

    const { projectsList: projects } = useSelector(
        state => state.projectsListReducer
    )

    const dispatch = useDispatch()

    useEffect(() => {
        profile && dispatch(projectsList(profile.id))
    }, [profile])

    return (
        <div
            className={`archive-panel card ${
                theme === 'dark' && 'archive-panel-dark'
            }`}
        >
            <div className='wrapper-content'>
                <StreamPlayerCanvasContainer
                    stream={stream}
                    liveHls={liveHls}
                />
            </div>
            <WrapperHeader
                decorator={ProjectColorScheme.parseHue(
                    stream.project.color,
                    projects
                )}
            >
                <div className='p-3 wrapper-header'>
                    <span className='title'>Playback</span>
                </div>
            </WrapperHeader>
        </div>
    )
}
