import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalLiveStreamContext } from "./LiveStream";
import { AnimatePresence, motion } from "framer-motion";
import { APIUtils } from "../../../../helpers/utils/api";
import APIEndpoints from "../../../../config/api/endpoints";
import { ProjectColorScheme } from "../../../../helpers/ParseProjectColors";
import Spinner from "../../../Spinner";
import { useSelector } from "react-redux";
import { StreamPlayer } from "./StreamPlayer";
import axiosInstance from "../../../../config/axios";

const StyledWrapper = styled(motion.div)`
  background-color: rgb(255, 255, 255);
  grid-area: ${({ gridArea }) => gridArea};
  border-radius: 10px;
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .descriptionWrapper {
    padding: 0.2rem 1rem;
    background-color: ${({ decorator }) => decorator};
    font-size: clamp(0.5rem, 9vw, 0.7rem);
    color: white;
    display: none;
    transition: display 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .streamWrapper {
    background-color: ${(props) =>
      props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "rgba(30, 30, 30, 0.7)"};
    flex-grow: 1;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 70%;
    width: 100%;
    border-top: 5px solid ${({ decorator }) => decorator};
  }

  .streamWrapper:hover {
    border-top: none;
    .descriptionWrapper {
      display: block;
    }
  }
`;

export const CameraStreamGridObject = ({
  stream,
  index,
  gridArea,
  frameDimension,
  projects,
}) => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isLoading, setIsLoading] = useState(true);

  const [liveHLS, setLiveHLS] = useState(null);
  const { streamIdsRef } = useContext(GlobalLiveStreamContext);

  const [data, setData] = useState({});

  const getLiveHlsUrl = useCallback(async () => {
    setIsLoading(true);
    const endpoint = APIEndpoints.instance.stream.main.getHlsUrl(stream?.id);
    const maxRetries = 3;
    let attempts = 0;

    const fetchUrl = async () => {
      (await axiosInstance())
        .get(endpoint)
        .then((response) => {
          // setIsLoading(false)
          if (response.data.url) {
            setLiveHLS(response.data.url);
            streamIdsRef.current.add(stream?.id);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          attempts++;
          if (attempts < maxRetries) {
            fetchUrl();
          } else {
            setLiveHLS(null);
            setIsLoading(false);
          }
        });
    };

    fetchUrl(); // Initial call to fetch the URL
  }, []);

  useEffect(() => {
    // Fetch the initial video URL
    getLiveHlsUrl();

    // Set up timeout to refresh the video URL before it expires (e.g., after 55 minutes)
    const timeoutId = setTimeout(() => {
      getLiveHlsUrl();
    }, 58 * 60 * 1000); // 58 minutes

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [getLiveHlsUrl]);

  useEffect(() => {
    APIUtils.fetchItemData({
      endpoint: APIEndpoints.instance.stream.main.get(stream?.id),
      item: data,
      setItem: setData,
    });
  }, []);

  return (
    <AnimatePresence>
      <StyledWrapper
        className={"grid-item livestream-grid-item"}
        gridArea={gridArea}
        frameDimension={frameDimension}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        decorator={ProjectColorScheme.parseHue(
          data?.data?.project?.color,
          projects,
          true
        )}
        theme={theme}
      >
        <div className='streamWrapper'>
          {data?.data && (
            <div className='descriptionWrapper'>
              {data?.data?.project?.name} - {stream?.label_name}
            </div>
          )}
          {isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center w-100 h-100'
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "1000",
              }}
            >
              <Spinner size={1.5} color='white' />
            </div>
          ) : (
            <StreamPlayer liveHls={liveHLS} reload={getLiveHlsUrl} />
          )}
        </div>
      </StyledWrapper>
    </AnimatePresence>
  );
};
