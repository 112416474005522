import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SizedBox } from '../../../../../../helpers/utils/SizedBox'
import { AddCameraSequenceEmail } from './elements/AddCameraSequenceEmail'
import { AddCheckList } from './elements/AddCheckList'
import Spinner from '../../../../../Spinner'
import { fireAlertConfirmDeletion } from '../../../../../../helpers/alertConfirmDeletion'
import axiosInstance from '../../../../../../config/axios'
import displayError from '../../../../../../helpers/displayError'
import { AddCameraSequence } from './elements/AddCameraSequence'
import { VPSContext } from '../../MainWrapper'
import { ConfigureVPSLogoComponent } from './elements/ConfigureVPSLogo'
import { ConfigureVPSLocation } from './elements/ConfigureVPSLocation'
import { APIUtils } from '../../../../../../helpers/utils/api'
import APIEndpoints from '../../../../../../config/api/endpoints'
import { AddCheckListNegativeActionPredefinedMessage } from './elements/AddCheckListNegativeActionPredefinedMessage'
import { useSelector } from 'react-redux'
// import {AddCameraSequence} from "./elements/AddCameraSequence";

const Wrapper = styled.div`
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .close-modal {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .close-svg {
            color: ${props =>
                props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : '#8c8888db'};
            font-size: 2rem;
            font-weight: 300;
            cursor: pointer;
        }
    }

    .CameraSequence_header {
        h3 {
            margin-top: 0;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.6rem;
        }
    }
    .CameraSequence_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        .company-info-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            .vertical-line {
                background-color: ${props =>
                    props.theme === 'dark'
                        ? 'rgba(94, 132, 195, 1)'
                        : 'rgba(228, 228, 228, 1)'};
                width: 1px;
                height: 150px;
            }
            @media (max-width: 700px) {
                flex-wrap: wrap;
                flex-direction: column;
                gap: 2rem;
                align-items: center;
                justify-content: center;
                .vertical-line {
                    display: none;
                }
                .CameraSequence_Section_info {
                    width: 100%;
                    max-width: 300px;
                }
            }
        }
        .camera_sequence-checklists {
            width: 100%;

            display: flex;
            flex-direction: column;

            gap: 1rem;
        }
        .CameraSequence_Section {
            width: 50%;
            max-width: 25rem;
            @media (max-width: 700px) {
                width: 100%;
            }
            .CameraSequence {
                h5 {
                    margin-top: 0;
                    padding-bottom: 0.5rem;
                    font-size: 1.1rem;
                    font-weight: 600;
                    line-height: 1.6rem;
                }
                .listItems {
                    border-bottom: 1px solid #ccc;
                    margin-bottom: 0.3rem;
                    padding-bottom: 0.3rem;
                    display: flex;
                    justify-content: space-between;
                    span {
                        margin-right: 1rem;
                    }

                    .selectable-item-wrapper {
                        flex-grow: 1;
                        cursor: pointer;
                        padding: 3px 5px;
                        transition: 500ms background-color ease-in-out,
                            500ms color ease-in-out;
                        color: ${props =>
                            props.theme === 'dark'
                                ? 'rgb(94, 132, 195)'
                                : 'rgb(37, 68, 120, 1)'};
                        i {
                            color: ${props =>
                                props.theme === 'dark'
                                    ? 'rgb(94, 132, 195)'
                                    : 'rgb(37, 68, 120, 1)'};
                        }
                        &.active,
                        &:hover {
                            background-color: rgb(37, 68, 120);
                            color: #ffffff !important;
                            i {
                                color: #ffffff !important;
                            }
                        }
                    }
                }
                .checkListItems {
                    .listItems {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
`

export const ConfigureVPSContainer = ({ callbackCancel }) => {
    const [VPSChecklistItems, setVPSChecklistItems] = useState(null)
    const { theme } = useSelector(state => state.themeReducer)
    const [
        VPSChecklistItemsPredefinedNegativeActionMessages,
        setVPSChecklistItemsPredefinedNegativeActionMessages,
    ] = useState(null)
    const [selectedCameraSequence, setSelectedCameraSequence] = useState(null)
    const [selectedChecklistItem, setSelectedChecklistItem] = useState(null)
    const { VPSCameraSequence, fetchVPSCameraSequenceInstance } =
        useContext(VPSContext)

    useEffect(() => {
        fetchVPSCameraSequenceInstance({
            ordering: 'index_no',
        })
    }, [])

    useEffect(() => {
        if (selectedCameraSequence) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.instance.vps.checklistItem.fetchAll({
                    stream_sequence: selectedCameraSequence?.id,
                    ordering: 'index_no',
                }),
                item: VPSChecklistItems,
                setItem: setVPSChecklistItems,
            })
        }
    }, [selectedCameraSequence])

    useEffect(() => {
        if (selectedChecklistItem) {
            APIUtils.fetchItemData({
                endpoint:
                    APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.fetchAll(
                        {
                            checklist_item: selectedChecklistItem?.id,
                            ordering: 'date_added',
                        }
                    ),
                item: VPSChecklistItemsPredefinedNegativeActionMessages,
                setItem: setVPSChecklistItemsPredefinedNegativeActionMessages,
            })
        }
    }, [selectedChecklistItem])

    const handleDeleteChecklistPredefinedNegativeActionMessage = useCallback(
        async id => {
            const { isConfirmed, value } = await fireAlertConfirmDeletion({
                content: '',
            })
            if (!isConfirmed) {
                return
            }
            await (
                await axiosInstance()
            )
                .delete(
                    APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.delete(
                        id
                    )
                )
                .then(response => {
                    APIUtils.fetchItemData({
                        endpoint:
                            APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.fetchAll(
                                {
                                    checklist_item: selectedChecklistItem?.id,
                                    ordering: 'date_added',
                                }
                            ),
                        item: VPSChecklistItemsPredefinedNegativeActionMessages,
                        setItem:
                            setVPSChecklistItemsPredefinedNegativeActionMessages,
                    })
                })
                .catch(err => {
                    displayError(err, theme)
                })
        },
        [VPSChecklistItemsPredefinedNegativeActionMessages]
    )

    const handleDeleteChecklist = useCallback(
        async id => {
            const { isConfirmed, value } = await fireAlertConfirmDeletion({
                content: 'Confirm Delete',
            })
            if (!isConfirmed) {
                return
            }
            await (
                await axiosInstance()
            )
                .delete(APIEndpoints.instance.vps.checklistItem.delete(id))
                .then(response => {
                    APIUtils.fetchItemData({
                        endpoint:
                            APIEndpoints.instance.vps.checklistItem.fetchAll({
                                stream_sequence: selectedCameraSequence?.id,
                                ordering: 'index_no',
                            }),
                        item: VPSChecklistItems,
                        setItem: setVPSChecklistItems,
                    })
                })
                .catch(err => {
                    displayError(err, theme)
                })
        },
        [VPSChecklistItems]
    )

    const handleDeleteCameraSequence = useCallback(
        async id => {
            const { isConfirmed } = await fireAlertConfirmDeletion({
                content: 'Confirm Delete',
            })
            if (!isConfirmed) {
                return
            }
            await (
                await axiosInstance()
            )
                .delete(APIEndpoints.instance.vps.streamSequence.remove(id))
                .then(response => {
                    fetchVPSCameraSequenceInstance()
                })
                .catch(err => {
                    displayError(err, theme)
                })
        },
        [VPSCameraSequence, fetchVPSCameraSequenceInstance]
    )

    return (
        <Wrapper theme={theme}>
            <div className='close-modal'>
                <span className='close-svg' onClick={callbackCancel}>
                    x
                </span>
            </div>
            <div className='CameraSequence_header'>
                <h3>Configure VPS</h3>
            </div>
            <SizedBox height={1} />
            <div className='CameraSequence_content'>
                <div className='company-info-container'>
                    <div className=''>
                        <ConfigureVPSLogoComponent theme={theme} />
                    </div>
                    <div className='vertical-line'></div>
                    <div className='CameraSequence_Section_info'>
                        <AddCameraSequenceEmail theme={theme} />
                    </div>
                    <div className='vertical-line'></div>
                    <div className='CameraSequence_Section_info'>
                        <ConfigureVPSLocation theme={theme} />
                    </div>
                </div>
                <div className='camera_sequence-checklists'>
                    <div className='CameraSequence_Section'>
                        <AddCameraSequence theme={theme} />
                    </div>
                    <div className='CameraSequence_Section'>
                        <div className='CameraSequence'>
                            <h5>Camera Sequence</h5>
                            <ul>
                                {VPSCameraSequence?.isFetching !== false
                                    ? VPSCameraSequence?.isFetching ===
                                          true && <Spinner size={1.5} />
                                    : (VPSCameraSequence?.data || []).map(
                                          (item, i) => {
                                              return (
                                                  <li className='listItems'>
                                                      <span
                                                          className={`selectable-item-wrapper ${
                                                              selectedCameraSequence?.id ===
                                                              item?.id
                                                                  ? 'active'
                                                                  : ''
                                                          }`}
                                                          onClick={() => {
                                                              setSelectedCameraSequence(
                                                                  selectedCameraSequence !==
                                                                      item &&
                                                                      item
                                                              )
                                                          }}
                                                      >
                                                          <i className='fas fa-bars'></i>
                                                          &nbsp;&nbsp;
                                                          {
                                                              item?.stream
                                                                  ?.label_name
                                                          }
                                                      </span>
                                                      <span>
                                                          <i
                                                              className='fas fa-times pointer-cursor'
                                                              style={{
                                                                  color: 'rgba(37,68,120,1)',
                                                              }}
                                                              onClick={() => {
                                                                  handleDeleteCameraSequence(
                                                                      item?.id
                                                                  )
                                                              }}
                                                          ></i>
                                                      </span>
                                                  </li>
                                              )
                                          }
                                      )}
                            </ul>
                        </div>
                    </div>

                    {selectedCameraSequence && (
                        <>
                            <div className='CameraSequence_Section'>
                                <AddCheckList
                                    cameraSequence={selectedCameraSequence}
                                    fetchVPSChecklistItems={params => {
                                        APIUtils.fetchItemData({
                                            endpoint:
                                                APIEndpoints.instance.vps.checklistItem.fetchAll(
                                                    {
                                                        stream_sequence:
                                                            selectedCameraSequence?.id,
                                                        ordering: 'index_no',
                                                        ...(params || {}),
                                                    }
                                                ),
                                            item: VPSChecklistItems,
                                            setItem: setVPSChecklistItems,
                                        })
                                    }}
                                />
                            </div>
                            <div className='CameraSequence_Section'>
                                <div className='CameraSequence'>
                                    <h5>Checklist Items</h5>

                                    <ul className='checkListItems'>
                                        {VPSChecklistItems?.isFetching !== false
                                            ? VPSChecklistItems?.isFetching ===
                                                  true && <Spinner size={1.5} />
                                            : (
                                                  VPSChecklistItems?.data || []
                                              ).map((item, i) => {
                                                  return (
                                                      <li className='listItems'>
                                                          <span
                                                              className={`selectable-item-wrapper ${
                                                                  selectedChecklistItem?.id ===
                                                                  item?.id
                                                                      ? 'active'
                                                                      : ''
                                                              }`}
                                                              onClick={() => {
                                                                  setSelectedChecklistItem(
                                                                      selectedChecklistItem !==
                                                                          item &&
                                                                          item
                                                                  )
                                                              }}
                                                          >
                                                              {i + 1}. &nbsp;
                                                              {item.question}
                                                          </span>
                                                          <span>
                                                              <i
                                                                  className='fas fa-times pointer-cursor'
                                                                  style={{
                                                                      color: 'rgba(37,68,120,1)',
                                                                  }}
                                                                  onClick={() => {
                                                                      handleDeleteChecklist(
                                                                          item?.id
                                                                      )
                                                                  }}
                                                              ></i>
                                                          </span>
                                                      </li>
                                                  )
                                              })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {selectedChecklistItem && (
                        <>
                            <div className='CameraSequence_Section'>
                                <AddCheckListNegativeActionPredefinedMessage
                                    checklistItem={selectedChecklistItem}
                                    callBackOnProcessed={params => {
                                        APIUtils.fetchItemData({
                                            endpoint:
                                                APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.fetchAll(
                                                    {
                                                        checklist_item:
                                                            selectedChecklistItem?.id,
                                                        ordering: 'date_added',
                                                    }
                                                ),
                                            item: VPSChecklistItemsPredefinedNegativeActionMessages,
                                            setItem:
                                                setVPSChecklistItemsPredefinedNegativeActionMessages,
                                        })
                                    }}
                                />
                            </div>
                            <div className='CameraSequence_Section'>
                                <div className='CameraSequence'>
                                    <h5>Negative Choice Messages</h5>
                                    <ul className='checkListItems'>
                                        {VPSChecklistItemsPredefinedNegativeActionMessages?.isFetching !==
                                        false
                                            ? VPSChecklistItemsPredefinedNegativeActionMessages?.isFetching ===
                                                  true && <Spinner size={1.5} />
                                            : (
                                                  VPSChecklistItemsPredefinedNegativeActionMessages?.data ||
                                                  []
                                              ).map((item, i) => {
                                                  return (
                                                      <li className='listItems'>
                                                          <span>
                                                              {i + 1}. &nbsp;{' '}
                                                              {item.content}
                                                          </span>
                                                          <span>
                                                              <i
                                                                  className='fas fa-times pointer-cursor'
                                                                  style={{
                                                                      color: 'rgba(37,68,120,1)',
                                                                  }}
                                                                  onClick={() => {
                                                                      handleDeleteChecklistPredefinedNegativeActionMessage(
                                                                          item?.id
                                                                      )
                                                                  }}
                                                              ></i>
                                                          </span>
                                                      </li>
                                                  )
                                              })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    )
}
