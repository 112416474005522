import styled from 'styled-components'

export const DarkThemeAuth = props => {
    return <StyledComponent {...props}>{props.children}</StyledComponent>
}

const StyledComponent = styled.div`
    ${props =>
        !props.enableStyle &&
        `display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    width: 100%;
    
    ${
        '' /* @media screen and (max-width: 450px) {

        

    } */
    }
    
    `}

    .form-control-custom-dark {
        ::placeholder {
            color: white !important;
        }
    }

    @media screen and (max-width: 450px) {
        .main-frame {
            ${'' /* height: 70vh !important; */}
            height: 70% !important;
            min-height: 80vh !important;
            width: auto !important;
            border-radius: 27px;
        }
    }
`
