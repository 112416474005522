import "../../../../../../../styles/camera.css";
import { useParams } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../../../../App";
import { APIUtils } from "../../../../../../../helpers/utils/api";
import APIEndpoints from "../../../../../../../config/api/endpoints";
import { useLoadDependenciesBeforeRender } from "../../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { AuthPagePreloaderComponent } from "../../../../../../global/graphical/AuthPagePreloaderComponent";
import { displayMessage } from "../../../../../../../helpers/displayMessage";
import fireSpinnerAlert from "../../../../../../../helpers/alertSpinner";
import axiosInstance from "../../../../../../../config/axios";
import displayError from "../../../../../../../helpers/displayError";
import { HandleOnChangeInput } from "../../../../../../../helpers/utils/formInput/HandleOnChangeInput";
import { MainWrapper } from "../../../../../../MainWrapper";
import { useSelector } from "react-redux";
import CanvasStream from "./objects/CanvasStream";

const StyledComponent = styled.div`
  .vertical-line {
    background-color: ${(props) =>
      props.theme === "dark"
        ? "rgba(94, 132, 195, 1)"
        : "rgba(228, 228, 228, 1)"};
    width: 2px;
    margin-top: 4.3rem;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .canvasDrawBoxOuter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    color: ${(props) => (props.theme === "dark" ? "white" : "black")};

    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .canvasBox {
      width: auto;
    }
    .people_counter_canvas_width_only {
      padding: 1rem;
      border-radius: 1rem;
      gap: 1rem;
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
      }

      .currentModeOptionWrapper {
        margin: 1rem;
      }

      .boundaryLineDirectionWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inputWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 3px;

          .input {
            position: initial;
            opacity: initial;
            pointer-events: initial;
            cursor: pointer;
          }
        }
      }

      .boundingBoxPrecisionFactorWrapper,
      .timeBoundWrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .input {
          height: 2rem !important;
          width: 100%;
          ${(props) =>
            props.theme === "dark" &&
            `
                          border-bottom: 1px solid rgba(94, 132, 195, 1); 
                          color: white !important;
                        `};
        }
      }

      .timeBoundWrapper {
        gap: 1rem;
      }

      .timeBoundWrapper > .inputWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
      }
    }
    .people_counter_canvas_draw_Box {
      gap: 1rem;
      display: none;
    }
    .people_counter_canvas_draw_Box.Active {
      display: flex;
      flex-direction: column;

      * {
        font-size: 0.6rem;
      }

      button {
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .people_counter_canvas_sidebar {
      width: 18%;
      min-width: 150px;
      max-width: 250px;
      overflow: hidden;
      margin-top: 3vw;
      padding: 0.5rem;
    }
  }
  .saveButton {
    justify-content: flex-end;
    display: flex;
  }
`;

export const FireSmokeDetectionConfigContext = createContext({});

const FireSmokeDetectionConfigView = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const { theme } = useSelector((state) => state.themeReducer);
  const [stream, setStream] = useState(null);
  const { id: streamID } = useParams();
  const [timeBoundMode, setTimeBoundMode] = useState(true);
  const [detectionTypeMode, setDetectionTypeMode] = useState(false);

  const [analyticConfiguration, setAnalyticConfiguration] = useState(null);

  const [detectionType, setDetectionType] = useState(null);
  const [timeBoundData, setTimeBoundData] = useState(null);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: analyticConfiguration?.isFetching,
      b: stream?.isFetching,
    },
  });

  const saveAnalyticConfiguration = async () => {
    setIsUpdatingData(true);
    fireSpinnerAlert({ title: "Saving Configuration" });
    const endpoint =
      APIEndpoints.instance.stream.analytic.fire_and_smoke_detection.main.update(
        analyticConfiguration?.data?.id
      );
    let requestData = {};

    requestData = {
      time_bound_start: timeBoundData?.start,
      time_bound_end: timeBoundData?.end,
      detection_type: detectionType,
    };

    (await axiosInstance())
      .patch(endpoint, requestData)
      .then((response) => {
        setIsUpdatingData(false);
        displayMessage("Success", "Configuration updated successfully");
      })
      .catch((err) => {
        setIsUpdatingData(false);
        displayError(err, theme);
      });
  };

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.instance.stream.main.get(streamID),
        item: stream,
        setItem: setStream,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (stream?.data) {
      APIUtils.fetchItemData({
        endpoint:
          APIEndpoints.instance.stream.analytic.fire_and_smoke_detection.main.getForStream(
            stream?.data?.id
          ),
        item: analyticConfiguration,
        setItem: setAnalyticConfiguration,
      });
    }
  }, [stream]);

  useEffect(() => {
    if (analyticConfiguration?.data) {
      const data = analyticConfiguration.data;

      setTimeBoundData({
        start: data.time_bound_start,
        end: data.time_bound_end,
      });

      setDetectionType(data.detection_type);
    }
  }, [analyticConfiguration]);

  useEffect(() => {
    return async () => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };
  }, []);

  return (
    <MainWrapper
      parent='stream'
      isFetching={isFetching}
      profile={profile}
      pageTitle='Settings'
    >
      <StyledComponent theme={theme}>
        <div id='body-camera'>
          <div className='main-frame-camera'>
            <div className='tab-content h-100 py-3 px-3'>
              {canRender ? (
                <div className='canvasDrawBoxOuter'>
                  <FireSmokeDetectionConfigContext.Provider value={{}}>
                    <div className='canvasBox'>
                      <CanvasStream stream={stream?.data} />
                    </div>
                  </FireSmokeDetectionConfigContext.Provider>
                  <div className='vertical-line'></div>
                  <div className='people_counter_canvas_sidebar'>
                    <div className='people_counter_canvas_width_only '>
                      <div className={`people_counter_canvas_draw_Box Active`}>
                        <button
                          className={`btn btn-custom-orange ${
                            theme === "dark" && "btn-custom-orange-dark"
                          }  waves-effect waves-light px-5 ${
                            timeBoundMode ? "show" : ""
                          }`}
                          onClick={() => {
                            setTimeBoundMode(true);
                          }}
                          z
                        >
                          TIME BOUND
                        </button>
                        {timeBoundMode ? (
                          <div
                            className={
                              "currentModeOptionWrapper timeBoundWrapper"
                            }
                          >
                            <div className='inputWrapper'>
                              <div className={"label"}>Starting Time</div>
                              <input
                                type='time'
                                className={"input"}
                                value={timeBoundData?.start}
                                onChange={(e) =>
                                  HandleOnChangeInput(
                                    e,
                                    "start",
                                    setTimeBoundData,
                                    timeBoundData,
                                    "value",
                                    (data) => {
                                      return data || null;
                                    }
                                  )
                                }
                              />
                            </div>
                            <div className='inputWrapper'>
                              <div className={"label"}>Ending Time</div>
                              <input
                                type='time'
                                className={"input"}
                                value={timeBoundData?.end}
                                onChange={(e) =>
                                  HandleOnChangeInput(
                                    e,
                                    "end",
                                    setTimeBoundData,
                                    timeBoundData,
                                    "value",
                                    (data) => {
                                      return data || null;
                                    }
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}

                        <button
                          className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                            theme === "dark" && "btn-custom-orange-dark"
                          } ${detectionTypeMode ? "" : "show"}`}
                          onClick={() => {
                            setDetectionTypeMode(true);
                            setTimeBoundMode(false);
                          }}
                        >
                          Detection Type
                        </button>

                        {detectionTypeMode && !timeBoundMode ? (
                          <div
                            className={
                              "currentModeOptionWrapper boundaryLineDirectionWrapper"
                            }
                          >
                            <div className='inputWrapper'>
                              <input
                                type='radio'
                                className={"input"}
                                name='detectionType'
                                value='smoke'
                                checked={detectionType === "smoke"}
                                onChange={(e) =>
                                  setDetectionType(e.target.value)
                                }
                              />
                              <div className={"label"}>Smoke</div>
                            </div>
                            <div className='inputWrapper'>
                              <input
                                type='radio'
                                className={"input"}
                                name='detectionType'
                                value='fire'
                                checked={detectionType === "fire"}
                                onChange={(e) =>
                                  setDetectionType(e.target.value)
                                }
                              />
                              <div className={"label"}>Fire</div>
                            </div>
                            <div className='inputWrapper'>
                              <input
                                type='radio'
                                className={"input"}
                                name='detectionType'
                                value='both'
                                checked={detectionType === "both"}
                                onChange={(e) =>
                                  setDetectionType(e.target.value)
                                }
                              />
                              <div className={"label"}>Both</div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='mt-3 people_counter_canvas_width_only'>
                      <div className='saveButton'>
                        <button
                          onClick={() => {
                            saveAnalyticConfiguration();
                          }}
                          type='submit'
                          className={`btn btn-custom-orange ${
                            theme === "dark" && "btn-custom-orange-dark"
                          }  waves-effect waves-light px-5 ${
                            isUpdatingData ? "disabled" : ""
                          }`}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <AuthPagePreloaderComponent />
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </StyledComponent>
    </MainWrapper>
  );
};

export default FireSmokeDetectionConfigView;
