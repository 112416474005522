import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../../Spinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { VPSContext } from '../../../MainWrapper'
import APIEndpoints from '../../../../../../../config/api/endpoints'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
        margin-top: 0;
        padding-bottom: 0.5rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 500;
        color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(37, 68, 120, 1)'};
    }

    input {
        color: ${props => (props.theme === 'dark' ? 'white' : '#8a8888')};
        text-indent: 0.5rem !important;
        height: 30px !important;
        font-weight: 400;
        font-size: 12px !important;
        border-bottom: ${props =>
            props.theme === 'dark'
                ? '1.5px solid rgba(94, 132, 195, 1) !important'
                : '1.5px solid rgb(37, 68, 120) !important'};
    }
    .add-camera_btn {
        padding: 0.4rem 1.2rem;
    }
`
export const AddCameraSequenceEmail = ({ theme }) => {
    const [isUpdatingVPSEmailAddress, setIsUpdatingVPSEmailAddress] =
        useState(false)
    const [data, setData] = useState({})

    const { VPSInstance, fetchVPSInstance } = useContext(VPSContext)

    useEffect(() => {
        setData({
            email_address: VPSInstance?.data?.email_address,
        })
    }, [VPSInstance])

    const handleOnSubmit = useCallback(
        async e => {
            e.preventDefault()
            setIsUpdatingVPSEmailAddress(true)
            await (
                await axiosInstance()
            )
                .patch(
                    APIEndpoints.instance.vps.main.update(
                        VPSInstance?.data?.id
                    ),
                    data
                )
                .then(response => {
                    //
                    fetchVPSInstance()
                })
                .catch(err => {
                    displayError(err, theme)
                })
            setIsUpdatingVPSEmailAddress(false)
        },
        [isUpdatingVPSEmailAddress, fetchVPSInstance]
    )

    return (
        <Wrapper theme={theme}>
            <h5>Add Email Address</h5>
            <h6
                className={`input-tag-upload_btn ${
                    theme === 'dark' && 'input-tag-upload_btn-dark'
                }`}
            >
                Email Here
            </h6>
            <input
                type='email'
                placeholder='guard@gmail.com'
                value={data?.email_address}
                onChange={e =>
                    HandleOnChangeInput(e, 'email_address', setData, data)
                }
            />
            <SizedBox height={1} />
            {VPSInstance?.isFetching !== false ? (
                VPSInstance?.isFetching === true && <Spinner size={1.5} />
            ) : (
                <Link
                    className={`orange-text add-camera_btn ${
                        theme === 'dark' && 'add-camera_btn-dark'
                    }`}
                    to=''
                    onClick={handleOnSubmit}
                >
                    Save
                </Link>
            )}
        </Wrapper>
    )
}
