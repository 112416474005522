import { Link } from 'react-router-dom'
import moment from 'moment'
import { fireAlertConfirmDeletion } from '../../../../helpers/alertConfirmDeletion'
import fireSpinnerAlert from '../../../../helpers/alertSpinner'
import APIEndpoints from '../../../../config/api/endpoints'
import axiosInstance from '../../../../config/axios'
import displayError from '../../../../helpers/displayError'
import { RouteUtils } from '../../../../routes'
import { SubAdminSubUserRoutes } from '../../../../routes/SubAdmin&SubUserRoutes'
import { useSelector } from 'react-redux'

const ProjectPanel = ({ obj, fetchProjects }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const handleDeleteProject = async e => {
        e.preventDefault()
        let confirmMessage = `Are you sure you want to delete project '${obj.name}'?`
        let { isConfirmed } = await fireAlertConfirmDeletion({
            content: confirmMessage,
        })
        if (isConfirmed) {
            const alert = fireSpinnerAlert({ title: 'Deleting Project' })
            ;(await axiosInstance())
                .delete(APIEndpoints.user.account.project.deleteProject(obj.id))
                .then(response => {
                    fetchProjects()
                    alert.close()
                })
                .catch(err => {
                    displayError(err, theme)
                })
        }
    }

    return (
        <div className='dashpanel p-2 p-md-3'>
            <div className='title d-flex justify-content-between'>
                <span className='title'>{obj.name}</span>
                <div className='d-flex'>
                    <a
                        href='#'
                        onClick={handleDeleteProject}
                        className='material-icons camera-control-icon  red-button'
                    >
                        delete
                    </a>
                    <Link
                        to={RouteUtils.parseKwargsToRoute(
                            { id: obj.id },
                            SubAdminSubUserRoutes.pathDefinitions.project
                                .updateProject
                        )}
                        className='material-icons camera-control-icon'
                    >
                        edit
                    </Link>
                </div>
            </div>
            <hr className='text-white mt-0 mb-1' />
            <div className='content px-3'>
                <Link
                    to={RouteUtils.parseKwargsToRoute(
                        { id: obj.id },
                        SubAdminSubUserRoutes.pathDefinitions.project
                            .viewProjectStreams
                    )}
                >
                    No of Cameras : {obj.stream_count}
                </Link>
            </div>
            <hr className='text-white mb-2 mt-2' />
            <div className='content px-2 d-flex justify-content-end'>
                <span className=''>
                    {moment(obj.date_added).local().format('DD-MM-YYYY')}
                </span>
            </div>
        </div>
    )
}

export default ProjectPanel
