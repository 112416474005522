import styled from 'styled-components'
import { FooterContainer } from './global/footer/Container'
import { NavigationBrandContainerMobile } from './global/navigation/mobile/brand/Container'
import { NavigationLinksContainerMobile } from './global/navigation/mobile/links/Container'

const SideBarLeftMobile = ({
    parent,
    isFetching,
    profile,
    setMobileState,
    theme,
}) => {
    return (
        profile && (
            <StyledComponent theme={theme}>
                <div className='main-style'>
                    <NavigationBrandContainerMobile
                        mobileToggle={setMobileState}
                        theme={theme}
                    />
                    <NavigationLinksContainerMobile
                        account_type={profile?.account_type}
                        parent={parent}
                        profile={profile}
                        isFetching={isFetching}
                        theme={theme}
                    />
                    <FooterContainer />
                </div>
            </StyledComponent>
        )
    )
}

export default SideBarLeftMobile

const StyledComponent = styled.div`
    transition: width 0.7s ease;
    position: absolute;
    overflow-wrap: break-word;
    top: 0px;
    left: 0px;
    min-height: 100vh;
    z-index: 100;
    width: 100%;
    background: ${props =>
        props.theme === 'light'
            ? 'rgba(18, 46, 91, 0.6)'
            : 'linear-gradient(180deg, rgba(38, 55, 84, 0.6), rgba(22, 32, 49, 0.6))'};
    backdrop-filter: blur(1.5px);
    .main-style {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        background: ${props =>
            props.theme === 'light'
                ? 'rgba(18, 46, 91, 1)'
                : 'linear-gradient(180deg, rgba(38, 55, 84, 1), rgba(22, 32, 49, 1))'};
        max-width: 200px;
        width: 200px;
        min-height: 100vh;
        overflow: auto;
        color: white;
    }

    @media screen and (min-width: 800px) {
        display: none;
    }
`
