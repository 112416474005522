export const HandleOnChangeInput = (e, dataRef, setData, currentData, inputTargetRef = "value", extraParserCallback) => {
    const tempData = {...currentData};
    if (typeof e === "string") {
        tempData[dataRef] = e;
    } else {
        tempData[dataRef] = e.target[inputTargetRef];
    }
    if (extraParserCallback) {
        tempData[dataRef] = extraParserCallback(tempData[dataRef])
    }
    setData(tempData);
};
