import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { GlobalUserProfileContext } from "../../../../App";
import { SizedBox } from "../../../../helpers/utils/SizedBox";
import { AppConfig } from "../../../../config";
import { APPProductionEnvironmentType } from "../../../../config/enums/system";
import APIEndpoints from "../../../../config/api/endpoints";
import { APIUtils } from "../../../../helpers/utils/api";
import { useLoadDependenciesBeforeRender } from "../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import Spinner from "../../../Spinner";
import { MainWrapper } from "../../../MainWrapper";

export const VPSContext = createContext({});

export const MainVPSContainerWrapper = ({ children }) => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const [VPSInstance, setVPSInstance] = useState(null);
  const [VPSCameraSequence, setVPSCameraSequence] = useState(null);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: VPSInstance?.isFetching,
    },
  });

  const getStreamSequenceEndpoint = useCallback(
    (queryParams) => {
      if (
        AppConfig.APP_PRODUCTION_ENVIRONMENT ===
        APPProductionEnvironmentType.WEB
      ) {
        return APIEndpoints.instance.vps.streamSequence.fetchAllInternetAvailable(
          {
            ...(queryParams || {}),
            owner: profile?.id,
          }
        );
      } else {
        return APIEndpoints.instance.vps.streamSequence.fetchAll({
          ...(queryParams || {}),
          owner: profile?.id,
        });
      }
    },
    [profile]
  );

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.instance.vps.main.fetch(),
        item: VPSInstance,
        setItem: setVPSInstance,
      });
    }
  }, [profile]);

  return (
    <MainWrapper
      parent='vps'
      isFetching={isFetching}
      profile={profile}
      pageTitle='VPS'
    >
      <div id='body-camera'>
        <div className='main-frame-camera'>
          <div className='tab-content h-100 py-3 px-3'>
            <SizedBox height={1} />
            {canRender === true ? (
              <VPSContext.Provider
                value={{
                  VPSInstance,
                  fetchVPSInstance: () => {
                    APIUtils.fetchItemData({
                      endpoint: APIEndpoints.instance.vps.main.fetch(),
                      item: VPSInstance,
                      setItem: setVPSInstance,
                    });
                  },
                  VPSCameraSequence,
                  fetchVPSCameraSequenceInstance: (params) => {
                    APIUtils.fetchItemData({
                      endpoint: getStreamSequenceEndpoint(params),
                      item: VPSCameraSequence,
                      setItem: setVPSCameraSequence,
                    });
                  },
                }}
              >
                {children}
              </VPSContext.Provider>
            ) : (
              canRender === false && (
                <>
                  <SizedBox height={8} />
                  <div className='spinnerWrapper'>
                    <>
                      <SizedBox height={1} />
                      <div className='spinnerWrapper'>
                        <Spinner size={2} />
                      </div>
                    </>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
