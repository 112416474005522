export class APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorInstance {
    static getForStream(id) {
        return `/instance/stream/analytic/safety_equipment_detector/stream/${id}/instance/`
    }

    static get(id) {
        return `/instance/stream/analytic/safety_equipment_detector/instance/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/safety_equipment_detector/instance/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/safety_equipment_detector/instance/${id}/`
    }
}
