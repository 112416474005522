export class APIEndpointAuth {
    static login() {
        return '/auth/login/'
    }

    static logout() {
        return '/auth/logout/'
    }

    static register() {
        return '/auth/register/'
    }

    static fetchMyProfile() {
        return '/auth/me/'
    }

    static updateMyProfile() {
        return '/auth/me/'
    }
    static initiateForgotPassword() {
        return '/auth/forgot_password/initiate/'
    }
    static finalizeForgotPassword() {
        return '/auth/forgot_password/finalize/'
    }
}
