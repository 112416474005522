import "../../../../../../../../styles/analytic.css";
import "../../../../../../../../styles/literallycanvas.css";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import LC from "literallycanvas/lib/js/literallycanvas-core";
import { LineIntrusionDetectorConfigContext } from "../Container";
import { StreamPlayerCanvasContainer } from "../../../../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer";

const LineIntrusionDetectorCanvasBoundaryLine = ({ stream }) => {
  const canvasInitialized = useRef(false);
  const hasRenderedPreviousOnce = useRef(false);
  const [gCanvas, setgCanvas] = useState(null);
  const { boundaryLineData, setBoundaryLineData } = useContext(
    LineIntrusionDetectorConfigContext
  );

  const tempData = useRef({});

  const constructBoundaryLine = useCallback(() => {
    if (boundaryLineData && gCanvas) {
      const { start_x, start_y, end_x, end_y } = boundaryLineData;
      const canvas = document.querySelector(
        "#people-counter-canvas-boundary-line"
      );
      const { width, height } = canvas.getBoundingClientRect();

      const lineShape = LC.createShape("Line", {
        x1: (start_x || 0) * width,
        y1: (start_y || 0) * height,
        x2: (end_x || 0) * width,
        y2: (end_y || 0) * height,
        strokeWidth: 2,
        color: "#ff0000",
        endCapShapes: [null, null],
      });
      gCanvas.saveShape(lineShape);
      let {
        start_x: x_1_1,
        start_y: y_1_1,
        end_x: x_2_1,
        end_y: y_2_1,
      } = boundaryLineData;
      if (x_1_1 && y_1_1 && x_2_1 && y_2_1) {
        x_1_1 *= width;
        x_2_1 *= width;
        y_1_1 *= height;
        y_2_1 *= height;
        const arrow_length = 0.1;
        // Left arrow
        const x_2_2_init = x_1_1 + arrow_length * (x_2_1 - x_1_1);
        const y_2_2_init = y_1_1 + arrow_length * (y_2_1 - y_1_1);
        const x_2_2 = x_1_1 + (y_2_2_init - y_1_1);
        const y_2_2 = y_1_1 - (x_2_2_init - x_1_1);
        const left_arrow_coords = [
          [x_1_1, y_1_1],
          [x_2_2, y_2_2],
        ];
        // Right arrow
        const x_3_2_init = x_2_1 + arrow_length * (x_2_1 - x_1_1);
        const y_3_2_init = y_2_1 + arrow_length * (y_2_1 - y_1_1);
        const x_3_2 = x_2_1 - (y_3_2_init - y_2_1);
        const y_3_2 = y_2_1 + (x_3_2_init - x_2_1);
        const right_arrow_coords = [
          [x_2_1, y_2_1],
          [x_3_2, y_3_2],
        ];
        if ([0, -1].includes(boundaryLineData?.direction)) {
          const lineShapeL = LC.createShape("Line", {
            x1: left_arrow_coords[0][0],
            y1: left_arrow_coords[0][1],
            x2: left_arrow_coords[1][0],
            y2: left_arrow_coords[1][1],
            strokeWidth: 1,
            color: "#000000",
            endCapShapes: [null, "arrow"],
          });
          gCanvas.saveShape(lineShapeL);
        }
        if ([0, 1].includes(boundaryLineData?.direction)) {
          const lineShapeR = LC.createShape("Line", {
            x1: right_arrow_coords[0][0],
            y1: right_arrow_coords[0][1],
            x2: right_arrow_coords[1][0],
            y2: right_arrow_coords[1][1],
            strokeWidth: 1,
            color: "#000000",
            endCapShapes: [null, "arrow"],
          });
          gCanvas.saveShape(lineShapeR);
        }
      }
    }
  }, [boundaryLineData, gCanvas]);

  useEffect(() => {
    if (gCanvas && boundaryLineData) {
      gCanvas.clear();
      constructBoundaryLine();
    }
  }, [boundaryLineData, gCanvas]);

  useEffect(() => {
    if (!hasRenderedPreviousOnce.current && boundaryLineData && gCanvas) {
      constructBoundaryLine();
      hasRenderedPreviousOnce.current = true;
    }
  }, [gCanvas, boundaryLineData]);

  useEffect(() => {
    if (
      document.querySelector("#people-counter-canvas-boundary-line") &&
      !canvasInitialized.current
    ) {
      const canvas = document.querySelector(
        "#people-counter-canvas-boundary-line"
      );
      const lc = LC.init(canvas, {
        // disable panning
        keyboardShortcuts: true,
        primaryColor: "#ff0000",
        tools: [LC.tools.Line],
        defaultStrokeWidth: 2,
      });
      setgCanvas(lc);
      canvas.addEventListener("pointerdown", (e) => {
        lc.clear();
        tempData.current = {
          direction: boundaryLineData?.direction,
        };
        const { offsetX, offsetY } = e;
        const { width, height } = canvas.getBoundingClientRect();
        tempData.current = {
          start_x: offsetX / width,
          start_y: offsetY / height,
          direction: tempData?.current.direction,
        };
      });

      canvas.addEventListener("pointerup", (e) => {
        if (tempData.current?.start_x && tempData.current?.start_y) {
          const { offsetX, offsetY } = e;
          const { width, height } = canvas.getBoundingClientRect();
          tempData.current = {
            ...tempData.current,
            end_x: offsetX / width,
            end_y: offsetY / height,
          };
          setBoundaryLineData(tempData.current);
        }
      });
      canvasInitialized.current = true;
    }
  }, []);

  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
      <div className='people-counter-canvas-width-only text-analytic-header d-flex justify-content-start'>
        Boundary Line
      </div>
      <div className='people-counter-canvas'>
        <div className='wrapper'>
          <StreamPlayerCanvasContainer stream={stream} />
        </div>
        <div
          className='wrapper'
          id={"people-counter-canvas-boundary-line"}
        ></div>
      </div>
    </div>
  );
};

export default LineIntrusionDetectorCanvasBoundaryLine;
