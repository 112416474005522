import Spinner from '../Spinner'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import EmptyFrame from '../global/graphical/EmptyFrame'
import { CLEAR_USER_PROFILE } from '../../config/constants'
import { useDispatch } from 'react-redux'
import { AuthUtilsHelper } from '../../helpers/utils/auth'
import { useSelector } from 'react-redux'

const Logout = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const navigate = useNavigate()
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()
    // rgba(38, 56, 84, 1)
    const handleLogout = () => {
        setProcessing(true)
        localStorage.clear()
        AuthUtilsHelper.clearRelationAuth()
        dispatch({ type: CLEAR_USER_PROFILE, payload: null })
        setProcessing(false)
        navigate('/', { replace: true })
    }

    return (
        <EmptyFrame>
            {!processing ? (
                <button
                    disabled={processing}
                    type='submit'
                    onClick={e => {
                        e.preventDefault()
                        handleLogout()
                    }}
                    className={`btn btn-custom-orange waves-effect waves-light px-5 title ${
                        theme === 'dark' && 'btn-custom-orange-dark'
                    }`}
                >
                    Confirm Logout
                </button>
            ) : (
                <Spinner size={2} rot_time={0.5} />
            )}
        </EmptyFrame>
    )
}

export default Logout
