import {APIEndpointsHelper} from "../index";

export class APIEndpointAuthRelation {
    static getAllRelations(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams('/auth/relation/', params)
    }

    static getRelation(id) {
        return `/auth/relation/${id}/`
    }

    static editRelation(id) {
        return `/auth/relation/${id}/`
    }

    static authenticateRelation(id) {
        return `/auth/relation/${id}/authenticate/`
    }

    static deleteRelation(id) {
        return `/auth/relation/${id}/`
    }
}