import React from "react";
import {useParams} from "react-router-dom";
import StreamList from "../../../../instance/stream/list/StreamList";
import APIEndpoints from "../../../../../config/api/endpoints";

export const ProjectStreamList = () => {
    const {id: projectID} = useParams()
    return (
        <StreamList listEndpoint={
            APIEndpoints.instance.stream.main.getAll({
                project: projectID
            })
        }/>
    )
}