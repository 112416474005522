import { useEffect, useState } from 'react'

export const useLoadDependenciesBeforeRender = ({
    actionsProgressWithKeys,
}) => {
    const [canRender, setCanRender] = useState(null)

    useEffect(() => {
        if (canRender === null || canRender === false) {
            if (
                Object.values(actionsProgressWithKeys).every(
                    x => x === false
                ) &&
                canRender !== true
            ) {
                setCanRender(true)
            } else if (
                Object.values(actionsProgressWithKeys).every(x => x === true) &&
                canRender !== false
            ) {
                setCanRender(false)
            }
        }
    }, [actionsProgressWithKeys, canRender])

    return [canRender]
}
