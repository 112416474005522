import { useContext } from 'react'
import ProjectForm from '../ProjectForm'
import { GlobalUserProfileContext } from '../../../../App'
import { AuthPagePreloaderComponent } from '../../../global/graphical/AuthPagePreloaderComponent'

import { MainWrapper } from '../../../MainWrapper'

const AddProject = props => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )

    return (
        <MainWrapper
            parent='project'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Edit Site'
        >
            <div id='body-camera'>
                <div className='main-frame-camera position-relative'>
                    <div className='tab-content h-100 py-3 px-3'>
                        {profile ? (
                            <ProjectForm />
                        ) : (
                            <AuthPagePreloaderComponent />
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default AddProject
