import styled from "styled-components";
import {ErrorDisplayObject} from "./objects/Object";

const StyledComponent = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9rem;
    gap: 1.0rem;
`

export const ErrorDisplayContainer = ({errorData}) => {
    const data = Array.isArray(errorData) ? errorData : [errorData]
    return (
        <StyledComponent>
            {
                data.map((item) => <ErrorDisplayObject errorData={item}/>)
            }
        </StyledComponent>
    );
};
