import { APIUtils } from "../../../helpers/utils/api";
import { APIEndpointAuth } from "./auth";
import { APIEndpointFileUpload } from "./file";
import { APIEndpointGlobalVariable } from "./config/globalVariable";
import { APIEndpointInstanceStream } from "./instance/stream";
import { APIEndpointAuthRelation } from "./auth/relation";
import { APIEndpointUserAccountSubscription } from "./user/account/subscription";
import {
  APIEndpointUserAccountInstanceAssigned,
  APIEndpointUserAccountInstanceStatistics,
} from "./user/account/instance";
import { APIEndpointUserAccountProject } from "./user/account/project";
import { APIEndpointUtilsTypes } from "./utils/types";
import {
  APIEndpointInstanceVPSChecklistItem,
  APIEndpointInstanceVPSChecklistItemNegativeActionPredefinedMessage,
} from "./instance/vps/checklist_item";
import { APIEndpointInstanceVPS } from "./instance/vps";
import { APIEndpointInstanceVPSReport } from "./instance/vps/report";
import { APIEndpointInstanceVPSReportEntity } from "./instance/vps/report_entity";
import { APIEndpointInstanceVPSStreamSequence } from "./instance/vps/stream_sequence";
import {
  APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotification,
  APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotificationAsGuest,
} from "./instance/stream/analytics/line_intrusion_detector/event/notification";
import {
  APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventReport,
  APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventReportAsGuest,
} from "./instance/stream/analytics/line_intrusion_detector/event/notification/report";

import { APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorInstance } from "./instance/stream/analytics/line_intrusion_detector/instance";

import {
  APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReport,
  APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReportAsGuest,
} from "./instance/stream/analytics/fire_and_smoke_detection/event/notification/report";

import { APIEndpointInstanceStreamAnalyticsFireSmokeDetectionInstance } from "./instance/stream/analytics/fire_and_smoke_detection/instance";

import {
  APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventNotification,
  APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventNotificationAsGuest,
} from "./instance/stream/analytics/zone_intrusion_detector/event/notification";

import {
  APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReport,
  APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReportAsGuest,
} from "./instance/stream/analytics/zone_intrusion_detector/event/notification/report";
import { APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorInstance } from "./instance/stream/analytics/zone_intrusion_detector/instance";
import { APIEndpointAuthToken } from "./auth/token";
import {
  APIEndpointInstanceStreamAnalyticsEventNotification,
  APIEndpointInstanceStreamAnalyticsEventNotificationAsGuest,
} from "./instance/stream/analytics/global_notifcations";
import { APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorInstance } from "./instance/stream/analytics/safety_equipment_detector/instance";
import {
  APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventNotification,
  APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventNotificationAsGuest,
} from "./instance/stream/analytics/safety_equipment_detector/event/notification";
import {
  APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReport,
  APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReportAsGuest,
} from "./instance/stream/analytics/safety_equipment_detector/event/notification/report";
import { APIEndpointInstanceStreamAnalyticsHeaMapsInstance } from "./instance/stream/analytics/heat_map_detector/instance";
import {
  APIEndpointInstanceStreamAnalyticsHeaMapsEventNotificationAsGuest,
  APIEndpointInstanceStreamAnalyticsHeatMapsEventNotification,
} from "./instance/stream/analytics/heat_map_detector/event/notification";
import {
  APIEndpointInstanceStreamAnalyticsHeatMapsEventReport,
  APIEndpointInstanceStreamAnalyticsHeatMapsEventReportAsGuest,
} from "./instance/stream/analytics/heat_map_detector/event/notification/report";
import { APIEndpointInstanceStreamAnalyticsCameraTamperingInstance } from "./instance/stream/analytics/camera_tampering/instance";
import {
  APIEndpointInstanceStreamAnalyticsCameraTamperingEventNotification,
  APIEndpointInstanceStreamAnalyticsCameraTamperingEventNotificationAsGuest,
} from "./instance/stream/analytics/camera_tampering/event/notification";
import {
  APIEndpointInstanceStreamAnalyticsCameraTamperingEventReport,
  APIEndpointInstanceStreamAnalyticsCameraTamperingEventReportAsGuest,
} from "./instance/stream/analytics/camera_tampering/event/notification/report";
import {
  APIEndpointInstanceStreamAnalyticsEventReport,
  APIEndpointInstanceStreamAnalyticsEventReportAsGuest,
} from "./instance/stream/analytics/global_notifcations/report";
import { APIEndpointInstanceStreamAnalyticsVideoBackupInstance } from "./instance/stream/analytics/video_backup/instance";
import { APIEndpointInstanceStreamAnalyticsMotionDetectorInstance } from "./instance/stream/analytics/motion_detector/instance";
import {
  APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotification,
  APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotificationAsGuest,
} from "./instance/stream/analytics/motion_detector/event/notification";
import {
  APIEndpointInstanceStreamAnalyticsMotionDetectorEventReport,
  APIEndpointInstanceStreamAnalyticsMotionDetectorEventReportAsGuest,
} from "./instance/stream/analytics/motion_detector/event/notification/report";
import {
  APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotification,
  APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotificationAsGuest,
} from "./instance/stream/analytics/fire_and_smoke_detection/event/notification";

export class APIEndpointsHelper {
  static parseEndpointForQueryParams(endpoint, params) {
    return `${endpoint}${
      params ? APIUtils.formatQueryDictToQueryParams(params, "?") : ""
    }`;
  }
}

const APIEndpoints = {
  auth: {
    me: APIEndpointAuth,
    relation: APIEndpointAuthRelation,
    token: APIEndpointAuthToken,
  },
  fileUpload: APIEndpointFileUpload,
  config: {
    globalVariable: APIEndpointGlobalVariable,
  },
  user: {
    account: {
      subscription: APIEndpointUserAccountSubscription,
      instance: {
        statistics: APIEndpointUserAccountInstanceStatistics,
        assigned: APIEndpointUserAccountInstanceAssigned,
      },
      project: APIEndpointUserAccountProject,
    },
  },
  instance: {
    stream: {
      main: APIEndpointInstanceStream,
      analytic: {
        line_intrusion_detector: {
          main: APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventReportAsGuest,
              },
            },
          },
        },
        zone_intrusion_detector: {
          main: APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReportAsGuest,
              },
            },
          },
        },
        heat_map_detector: {
          main: APIEndpointInstanceStreamAnalyticsHeaMapsInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsHeatMapsEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsHeaMapsEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsHeatMapsEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsHeatMapsEventReportAsGuest,
              },
            },
          },
        },
        motion_detector: {
          main: APIEndpointInstanceStreamAnalyticsMotionDetectorInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsMotionDetectorEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsMotionDetectorEventReportAsGuest,
              },
            },
          },
        },
        fire_and_smoke_detection: {
          main: APIEndpointInstanceStreamAnalyticsFireSmokeDetectionInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventReportAsGuest,
              },
            },
          },
        },

        safety_equipment_detector: {
          main: APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReportAsGuest,
              },
            },
          },
        },

        camera_tampering: {
          main: APIEndpointInstanceStreamAnalyticsCameraTamperingInstance,
          event: {
            notification: {
              main: APIEndpointInstanceStreamAnalyticsCameraTamperingEventNotification,
              guest:
                APIEndpointInstanceStreamAnalyticsCameraTamperingEventNotificationAsGuest,
              report: {
                main: APIEndpointInstanceStreamAnalyticsCameraTamperingEventReport,
                guest:
                  APIEndpointInstanceStreamAnalyticsCameraTamperingEventReportAsGuest,
              },
            },
          },
        },
        video_backup: {
          main: APIEndpointInstanceStreamAnalyticsVideoBackupInstance,
        },
        global_notification: {
          notification: {
            main: APIEndpointInstanceStreamAnalyticsEventNotification,
            guest: APIEndpointInstanceStreamAnalyticsEventNotificationAsGuest,
          },
          report: {
            main: APIEndpointInstanceStreamAnalyticsEventReport,
            guest: APIEndpointInstanceStreamAnalyticsEventReportAsGuest,
          },
        },
      },
    },
    vps: {
      checklistItem: APIEndpointInstanceVPSChecklistItem,
      checklistItemNegativeActionPredefinedMessage:
        APIEndpointInstanceVPSChecklistItemNegativeActionPredefinedMessage,
      main: APIEndpointInstanceVPS,
      report: APIEndpointInstanceVPSReport,
      reportEntity: APIEndpointInstanceVPSReportEntity,
      streamSequence: APIEndpointInstanceVPSStreamSequence,
    },
  },
  utils: {
    types: APIEndpointUtilsTypes,
  },
};

export default APIEndpoints;
