import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import axiosInstance from '../../../../../../config/axios'
import displayError from '../../../../../../helpers/displayError'
import APIEndpoints from '../../../../../../config/api/endpoints'
import { RouteUtils } from '../../../../../../routes'
import { GlobalRoutes } from '../../../../../../routes/GlobalRoutes'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    background: ${props =>
        props.theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white'};
    .generate_report_header {
        /* text-transform: uppercase; */
        padding: 1rem 0rem;
        text-align: center;
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
        h3 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
        }
    }
    .generate_report_content {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;

        input {
            /* border-radius: 5px; */
            /* border: 2px solid #000; */

            border-bottom: ${props =>
                props.theme === 'dark' ? '1px solid white' : '1px solid black'};
            color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
            padding: 0 0.5rem;
        }
    }
`

export const GenerateReportModal = ({ VPSReportInstance }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const [data, setData] = useState({})
    const [isProcessing, setIsProcessing] = useState(true)
    const navigate = useNavigate()

    const updateVPSReportInstance = useCallback(async () => {
        setIsProcessing(true)
        await (
            await axiosInstance()
        )
            .patch(
                APIEndpoints.instance.vps.report.update(VPSReportInstance?.id),
                data
            )
            .then(response => {
                navigate(
                    RouteUtils.parseKwargsToRoute(
                        { id: VPSReportInstance?.id },
                        GlobalRoutes.pathDefinitions.feature.vps.viewReport
                    )
                )
            })
            .catch(err => {
                displayError('Error in generating vps report', theme)
            })
        setIsProcessing(false)
    }, [data, VPSReportInstance, isProcessing])

    const handleOnSubmit = () => {
        if (data?.name) {
            updateVPSReportInstance()
        } else {
            displayError(
                'Kindly enter the name you wish to name the report to be generated',
                theme
            )
        }
    }
    return (
        <Wrapper theme={theme}>
            <div className='generate_report_header'>
                <h3>Generate Report</h3>
            </div>
            <div className='generate_report_content'>
                <input
                    type='text'
                    placeholder='Enter the name of report'
                    value={data?.name}
                    onChange={e =>
                        HandleOnChangeInput(e, 'name', setData, data)
                    }
                />
                <button
                    className={`orange-text add-camera_btn
                                                    
                                                    ${
                                                        theme === 'dark' &&
                                                        'add-camera_btn-dark'
                                                    }`}
                    onClick={handleOnSubmit}
                >
                    Generate Report
                </button>
            </div>
        </Wrapper>
    )
}
