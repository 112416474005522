import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { HandleOnChangeInput } from "../../../../../helpers/utils/formInput/HandleOnChangeInput";
import { APIUtils } from "../../../../../helpers/utils/api";
import APIEndpoints from "../../../../../config/api/endpoints";
import Spinner from "../../../../Spinner";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  width: 100%;
  ${"" /* color: ${props => (props.theme === 'dark' ? 'white' : 'black')}; */}
  .rectification_header {
    padding: 2rem 4rem;
    h3 {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 700;
    }
  }
  .rectification_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;
    .input-wrapper {
      width: 60%;
      input {
        ::placeholder {
          color: ${(props) => (props.theme === "dark" ? "white" : "black")};
        }
      }
    }
  }

  .predefinedMessagesWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    .messageItem {
      border-radius: 5px;
      border: 1px solid #ddd;
      padding: 0.4rem;
    }

    .selectable-item-wrapper {
      cursor: pointer;
      transition: 500ms background-color ease-in-out, 500ms color ease-in-out;

      &.active,
      &:hover {
        background-color: rgb(37, 68, 120);
        color: #ffffff !important;
      }
    }
  }
`;

export const Rectification = ({ checklistItem, callBack, callbackCancel }) => {
  const { theme } = useSelector((state) => state.themeReducer);

  const [
    VPSChecklistItemsPredefinedNegativeActionMessages,
    setVPSChecklistItemsPredefinedNegativeActionMessages,
  ] = useState(null);

  const [data, setData] = useState({});

  const handleOnSubmit = useCallback(
    (customData) => {
      callBack(!customData ? data : customData);
      callbackCancel(false);
    },
    [data]
  );

  useEffect(() => {
    APIUtils.fetchItemData({
      endpoint:
        APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.fetchAll(
          {
            checklist_item: checklistItem?.id,
            ordering: "date_added",
          }
        ),
      item: VPSChecklistItemsPredefinedNegativeActionMessages,
      setItem: setVPSChecklistItemsPredefinedNegativeActionMessages,
    });
  }, [checklistItem]);

  return (
    <Wrapper theme={theme}>
      <div className='rectification_header'>
        <h3>Rectification</h3>
      </div>
      <div className='rectification_content'>
        <div className='input-wrapper'>
          <input
            type='text'
            id='informGaurd'
            placeholder='Inform guard'
            value={data?.message}
            className={`form-control form-control-custom ${
              theme === "dark" && "form-control-custom-dark"
            }`}
            onChange={(e) => HandleOnChangeInput(e, "message", setData, data)}
          />
        </div>
        <button
          className={`btn btn-custom-orange waves-effect ${
            theme === "dark" && "btn-custom-orange-dark"
          } waves-light px-5`}
          // to=''
          onClick={() => handleOnSubmit()}
        >
          SUBMIT
        </button>
        <ul className='predefinedMessagesWrapper'>
          {VPSChecklistItemsPredefinedNegativeActionMessages?.isFetching !==
          false
            ? VPSChecklistItemsPredefinedNegativeActionMessages?.isFetching ===
                true && <Spinner size={1.5} />
            : (
                VPSChecklistItemsPredefinedNegativeActionMessages?.data || []
              ).map((item, i) => {
                return (
                  <li
                    className='messageItem selectable-item-wrapper'
                    onClick={() => {
                      handleOnSubmit({
                        message: item?.content,
                      });
                    }}
                  >
                    {item.content}
                  </li>
                );
              })}
        </ul>
      </div>
    </Wrapper>
  );
};
