import React from 'react'

function VideoBackup() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 28 24'
        >
            <path
                fill='#05A431'
                d='M18.667 12a2.666 2.666 0 10-5.332-.001 2.666 2.666 0 005.332.001zM16 0C9.373 0 4 5.373 4 12H0l5.333 5.333L10.667 12h-4c0-5.153 4.18-9.333 9.333-9.333 5.153 0 9.333 4.18 9.333 9.333 0 5.153-4.18 9.333-9.333 9.333-2.02 0-3.88-.646-5.413-1.74L8.7 21.507A11.924 11.924 0 0016 24c6.627 0 12-5.373 12-12S22.627 0 16 0z'
            ></path>
        </svg>
    )
}

export default VideoBackup
