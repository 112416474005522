import { useState } from 'react'
import axiosInstance from '../../../config/axios'
import displayError from '../../../helpers/displayError'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Spinner'
import APIEndpoints from '../../../config/api/endpoints'
import { SubAdminSubUserRoutes } from '../../../routes/SubAdmin&SubUserRoutes'
import { HandleOnChangeInput } from '../../../helpers/utils/formInput/HandleOnChangeInput'
import { useSelector } from 'react-redux'

const ProjectForm = ({ redirectOnSuccess, obj }) => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const { theme } = useSelector(state => state.themeReducer)
    const handleCreate = async e => {
        e.preventDefault()
        setIsProcessing(true)
        const isUpdate = obj && obj?.id
        await (
            await axiosInstance()
        )
            [isUpdate ? 'patch' : 'post'](
                isUpdate
                    ? APIEndpoints.user.account.project.editProject(obj.id)
                    : APIEndpoints.user.account.project.createProject(),
                formData
            )
            .then(response => {
                navigate(
                    redirectOnSuccess ||
                        SubAdminSubUserRoutes.pathDefinitions.stream
                            .viewStreams,
                    { replace: true }
                )
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsProcessing(false)
    }

    const handleKeyPress = e => {
        if (e.charCode == 13 && !isProcessing) {
            handleCreate(e)
        }
    }

    return (
        <>
            <div className='w-100 d-flex'>
                <button
                    disabled={isProcessing}
                    onClick={handleCreate}
                    type='submit'
                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                        theme === 'dark' && 'btn-custom-orange-dark'
                    }`}
                >
                    Save
                </button>
            </div>
            {isProcessing ? (
                <div className='w-100 h-100 d-flex justify-content-center align-items-center my-5'>
                    <Spinner />
                </div>
            ) : (
                <div className='grid-form-camera my-3 mt-5'>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='project_name'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Project Name
                        </label>
                        <input
                            value={formData?.name || obj?.name}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'name',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='project_name'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='project_addr'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Project Address
                        </label>
                        <input
                            value={formData?.address || obj?.address}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'address',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='project_addr'
                        />
                    </div>
                    <div className='input-wrapper full-span'>
                        <label
                            htmlFor='project_phone'
                            className={`${theme === 'dark' && 'label-dark'}`}
                        >
                            Project Phone Number
                        </label>
                        <input
                            value={formData?.phone_number || obj?.phone_number}
                            onKeyPress={handleKeyPress}
                            onChange={e =>
                                HandleOnChangeInput(
                                    e,
                                    'phone_number',
                                    setFormData,
                                    formData
                                )
                            }
                            type='text'
                            className={`form-control form-control-custom ${
                                theme === 'dark' && 'form-control-custom-dark'
                            }`}
                            id='project_phone'
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default ProjectForm
