import React from 'react'
import styled from 'styled-components'

const StyledComponent = styled.div`
    min-height: 100vh;
    background-color: #f3f3f3;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
    overflow: auto;

    ${props =>
        props.theme === 'dark' &&
        `background: linear-gradient(to bottom right, #202d42, #162131, #142d31)`};

    @media screen and (max-width: 800px) {
        width: 100vw;
    }
    ${
        '' /* @media screen and (max-width: 500px) {
        padding: 1rem 1.5rem;
    } */
    }
`

export const MainContainer = props => {
    return <StyledComponent {...props}>{props.children}</StyledComponent>
}
