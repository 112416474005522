import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ProjectPanel from './ProjectPanel'
import { GlobalUserProfileContext } from '../../../../App'
import { useLoadDependenciesBeforeRender } from '../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { APIUtils } from '../../../../helpers/utils/api'
import APIEndpoints from '../../../../config/api/endpoints'
// import SideBarLeft from '../../../SidebarLeft'
// import { NavigationComponent } from '../../../global/containers/navigation/NavigationComponent'
import Spinner from '../../../Spinner'
import { SubAdminSubUserRoutes } from '../../../../routes/SubAdmin&SubUserRoutes'
import { MainWrapper } from '../../../MainWrapper'

const ProjectList = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const [projects, setProjects] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: projects?.isFetching,
        },
    })

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.user.account.project.getAllProjects({
                    owner: profile?.id,
                }),
                item: projects,
                setItem: setProjects,
            })
        }
    }, [profile])

    return (
        <MainWrapper
            parent='project'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Sites'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    {/* <SideBarLeft parent="project" isFetching={isFetching} profile={profile}/> */}
                    <div className='tab-content h-100 py-3 px-3'>
                        <div className='mt-2 tab-content-header d-flex justify-content-end'>
                            {/* <NavigationComponent>
                                <div className='d-flex crumbs'>
                                    <span className='crumb'>
                                        Manage Projects
                                    </span>
                                </div>
                            </NavigationComponent> */}
                            <Link
                                className='orange-text add-camera_btn'
                                to={{
                                    pathname:
                                        SubAdminSubUserRoutes.pathDefinitions
                                            .project.addProject,
                                    state: { reload: true },
                                }}
                            >
                                + Add Project
                            </Link>
                        </div>
                        {canRender !== true ? (
                            <div className='d-flex justify-content-center w-100 mt-5'>
                                {canRender === false && <Spinner size={1.8} />}
                            </div>
                        ) : (
                            projects?.data !== null &&
                            (!projects?.data?.length ? (
                                <div className='d-flex justify-content-center w-100 mt-5 text-center'>
                                    You currently have no project
                                </div>
                            ) : (
                                <div className='grid-region my-3'>
                                    {projects?.data.map(project => (
                                        <ProjectPanel
                                            obj={project}
                                            fetchProjects={() => {
                                                APIUtils.fetchItemData({
                                                    endpoint:
                                                        APIEndpoints.user.account.project.getAllProjects(
                                                            {
                                                                owner: profile?.id,
                                                            }
                                                        ),
                                                    item: projects,
                                                    setItem: setProjects,
                                                })
                                            }}
                                        />
                                    ))}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default ProjectList
