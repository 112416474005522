import React from "react";

function Heatmaps() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <g fill='#05A431' clipPath='url(#clip0_2069_1586)'>
        <path d='M19.25 15.75H17.5v1.75h1.75v-1.75zM26.25 14H24.5v1.75h1.75V14zM14 5.25h-1.75V7H14V5.25zM14 19.25h-3.5V14a1.752 1.752 0 00-1.75-1.75H3.5A1.752 1.752 0 001.75 14v5.25A1.752 1.752 0 003.5 21h5.25v3.5a1.752 1.752 0 001.75 1.75H14a1.752 1.752 0 001.75-1.75V21A1.752 1.752 0 0014 19.25zm-10.5 0V14h5.25v5.25H3.5zm7 5.25V21H14v3.5h-3.5zM24.5 26.25H21a1.752 1.752 0 01-1.75-1.75V21A1.752 1.752 0 0121 19.25h3.5A1.752 1.752 0 0126.25 21v3.5a1.752 1.752 0 01-1.75 1.75zM21 21v3.5h3.5V21H21zM24.5 1.75h-5.25A1.752 1.752 0 0017.5 3.5v5.25h-1.75A1.752 1.752 0 0014 10.5v1.75A1.752 1.752 0 0015.75 14h1.75a1.752 1.752 0 001.75-1.75V10.5h5.25a1.752 1.752 0 001.75-1.75V3.5a1.752 1.752 0 00-1.75-1.75zm-8.75 10.5V10.5h1.75v1.75h-1.75zm3.5-3.5V3.5h5.25v5.25h-5.25zM7 8.75H3.5A1.752 1.752 0 011.75 7V3.5A1.752 1.752 0 013.5 1.75H7A1.752 1.752 0 018.75 3.5V7A1.752 1.752 0 017 8.75zM3.5 3.5V7H7V3.5H3.5z'></path>
      </g>
      <defs>
        <clipPath id='clip0_2069_1586'>
          <path fill='#fff' d='M0 0H28V28H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Heatmaps;
