import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { ModalComponent } from '../../../../../helpers/utils/Modal'
import { SizedBox } from '../../../../../helpers/utils/SizedBox'
import { ConfigureVPSContainer } from '../modals/configure/Container'
import { StartVps } from './objects/StartVPS'
import { VPSContext } from '../MainWrapper'
import { GenerateReportModalContainer } from '../modals/generate_report/Container'
import Spinner from '../../../../Spinner'
import { useSelector } from 'react-redux'

const StyledComponent = styled.div``
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
`

export const VPSEntryContainer = () => {
    const [configureVPSModal, setConfigureVPSModal] = useState(false)
    const [generateReportModal, setGenerateReportModal] = useState(false)
    const { VPSInstance } = useContext(VPSContext)
    const { theme } = useSelector(state => state.themeReducer)
    const handleRectification = data => {}
    const handleOpenConfigureModel = e => {
        e.preventDefault()
        setConfigureVPSModal(true)
    }

    return (
        <StyledComponent>
            <ButtonWrapper>
                <button
                    className={`orange-text add-camera_btn ${
                        theme === 'dark' && 'add-camera_btn-dark'
                    }`}
                    onClick={handleOpenConfigureModel}
                >
                    CONFIGURE VPS
                </button>
                <button
                    className={`orange-text add-camera_btn ${
                        theme === 'dark' && 'add-camera_btn-dark'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        setGenerateReportModal(true)
                    }}
                >
                    VIEW REPORTS
                </button>
            </ButtonWrapper>
            <SizedBox height={1} />
            {VPSInstance?.isFetching === false ? (
                <StartVps />
            ) : (
                <>
                    <SizedBox height={1} />
                    <div className='spinnerWrapper'>
                        <>
                            <SizedBox height={1} />
                            <div className='spinnerWrapper'>
                                <Spinner size={2} />
                            </div>
                        </>
                    </div>
                </>
            )}
            <ModalComponent
                modalLabel={'CONFIGURE VPS'}
                isOpen={configureVPSModal}
                ClassName={'rectification_model'}
                callbackCloseModal={() => {
                    setConfigureVPSModal(false)
                }}
                onOverlayClose={false}
                customStyling={true}
            >
                <ConfigureVPSContainer
                    callBack={handleRectification}
                    callbackCancel={() => {
                        setConfigureVPSModal(false)
                    }}
                />
            </ModalComponent>
            <ModalComponent
                modalLabel={'Generate Report'}
                isOpen={generateReportModal}
                ClassName={'rectification_model'}
                callbackCloseModal={() => {
                    setGenerateReportModal(false)
                }}
            >
                <GenerateReportModalContainer
                    callBack={handleRectification}
                    callbackCancel={() => {
                        setGenerateReportModal(false)
                    }}
                />
            </ModalComponent>
        </StyledComponent>
    )
}
