export class APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorInstance {
    static getForStream(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/stream/${id}/instance/`
    }

    static get(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/instance/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/instance/${id}/`
    }
    static zonePoints() {
        return `/instance/stream/analytic/zone_intrusion_detector/coordinates/`
    }
    static zonePointsDelete(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/instance/${id}/batch_destroy/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/instance/${id}/`
    }
}
