import { APIEndpointsHelper } from "../../../../../../index";

export class APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotification {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/fire_and_smoke_detection/event/notification/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/${id}/`;
  }

  static batchDestroy(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/fire_and_smoke_detection/event/notification/batch_destroy/`,
      params
    );
  }
}

export class APIEndpointInstanceStreamAnalyticsFireSmokeDetectionEventNotificationAsGuest {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(
      `/instance/stream/analytic/fire_and_smoke_detection/event/notification/guest/`,
      params
    );
  }

  static get(id) {
    return `/instance/stream/analytic/fire_and_smoke_detection/event/notification/${id}/guest/`;
  }
}
