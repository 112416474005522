import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Styled components
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0; /* Adjust as needed */
`;

const StyledSelect = styled.div`
  border: 1px solid
    ${({ theme }) =>
      theme === "dark" ? "rgba(94, 132, 195, 1)" : "rgb(37, 68, 120)"};
  background-color: ${({ theme }) =>
    theme === "dark" ? "rgba(38, 56, 84, 1)" : ""};
  border-radius: 30px;
  padding: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
`;

const SpanContainer = styled.span`
  &&& {
    font-size: 12px !important; /* Ensure the font size is correctly applied */
  }
  font-weight: 300;
  color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")};
`;

const SpanIcon = styled.span`
  font-size: 12px !important;
  font-weight: 300;
  color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")};
`;

const OptionsList = styled.div`
  position: absolute;
  background-color: ${({ theme }) =>
    theme === "dark" ? "rgba(38, 56, 84, 1)" : "#ffffff"};
  border-radius: 15px;
  width: 100%;
  z-index: 1;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 0.5rem 0;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Keep checkbox on the left, text centered */
  padding: 8px;
  cursor: pointer;
  border-bottom: ${({ theme }) =>
    theme === "dark" ? "2px solid #5e84c3 " : "2px solid #5e84c3"} !important;
  font-size: 12px !important;
  font-weight: 400;
  transition: background-color 0.3s ease;

  &&& span {
    font-size: 12px !important;
    font-weight: 300;
    text-align: center; /* Center the text within its space */
    flex-grow: 1; /* Ensures the text takes up the remaining space */
  }

  .idDOGq span {
    font-size: 12px !important;
  }
`;

const OptionShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  border-bottom: ${({ theme }) =>
    theme === "dark" ? "2px solid #5e84c3 " : "2px solid #5e84c3"} !important;
  transition: background-color 0.3s ease;

  &&& span {
    font-size: 12px !important;
    font-weight: 300;
  }

  .idDOGq span {
    font-size: 12px !important;
  }
`;

const CustomCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #5e84c3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: ${({ checked }) => (checked ? "#5e84c3" : "transparent")};
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: ${({ checked }) => (checked ? "block" : "none")};
  }
`;

// Helper function to format option values
const formatOptionValue = (value) => {
  const words = value.split(" ");
  if (words.length > 2) {
    const truncatedThirdWord = words[2].charAt(0) + "...";
    return `${words[0]} ${words[1]} ${truncatedThirdWord}`;
  }
  return value;
};

// Main Component
export const SelectComponent = ({
  selectedOptions,
  optionType,
  handleOptionChange,
  optionData,
}) => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const hasOptions = optionData[optionType]?.data?.length > 0;

  const toggleDropdown = () => {
    if (hasOptions) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOptionClick = (option) => {
    const newSelectedOptions = selectedOptions[optionType].includes(option)
      ? selectedOptions[optionType].filter((opt) => opt !== option)
      : [...selectedOptions[optionType], option];

    handleOptionChange(optionType, newSelectedOptions);
    setIsOpen(false); // Close dropdown on option select
  };

  const handleSelectAll = () => {
    if (
      selectedOptions[optionType].length === optionData[optionType]?.data.length
    ) {
      handleOptionChange(optionType, []);
    } else {
      handleOptionChange(optionType, optionData[optionType]?.data);
    }
  };

  // Function to close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Adding and removing event listener for outside clicks
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <StyledSelect onClick={toggleDropdown} isDisabled={!hasOptions}>
        <SpanContainer>{optionType}</SpanContainer>
        <SpanIcon>{isOpen ? "▲" : "▼"}</SpanIcon>
      </StyledSelect>
      {isOpen && (
        <OptionsList theme={theme}>
          <OptionContainer onClick={handleSelectAll}>
            <CustomCheckbox
              checked={
                selectedOptions[optionType].length ===
                optionData[optionType]?.data.length
              }
            />
            <span>All</span>
          </OptionContainer>
          {optionData[optionType]?.data.map((option) => (
            <OptionShow
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              <CustomCheckbox
                checked={selectedOptions[optionType].includes(option)}
                onClick={() => handleOptionClick(option)}
              />
              <span>{formatOptionValue(option.value)}</span>
              <span style={{ color: "rgb(147,155,170)" }}>{option.count}</span>
            </OptionShow>
          ))}
        </OptionsList>
      )}
    </DropdownContainer>
  );
};

//
//
// import React from 'react'
// import { useSelector } from 'react-redux'
// import Select from 'react-select'

// const customStyles = (theme, isLoading) => ({
//     container: provided => ({
//         ...provided,
//         width: '100%',
//     }),
//     control: (provided, state) => ({
//         ...provided,
//         boxShadow: state.isFocused ? null : provided.boxShadow,
//         borderWidth: '1px',
//         borderColor:
//             theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'rgb(37, 68, 120)',
//         borderRadius: '30px',
//         fontWeight: 300,
//         minHeight: '10px',
//         fontSize: '12px',
//         lineHeight: '16px',
//         cursor: 'pointer',
//         outline: 'none',
//         background: 'transparent',
//         height: '33px !important',
//     }),
//     option: provided => ({
//         ...provided,
//         outline: 'none',
//         overflowY: 'hidden',
//         backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white',
//         color: theme === 'dark' ? 'white' : 'black',
//         cursor: 'pointer',
//     }),

//     noOptionsMessage: provided => ({
//         ...provided,
//         color: theme === 'dark' ? 'white' : 'black',
//     }),

//     indicatorSeparator: (provided, state) => ({
//         ...provided,
//         display: 'none',
//     }),

//     dropdownIndicator: (provided, state) => ({
//         ...provided,
//         color: theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'rgb(37, 68, 120)',
//         padding: '6px',
//     }),

//     clearIndicator: () => ({
//         color: theme === 'dark' ? 'white' : 'black',
//     }),
//     placeholder: (provided, state) => ({
//         ...provided,
//         color: theme === 'dark' ? 'white' : 'black',
//     }),
//     valueContainer: (provided, state) => ({
//         padding: '0px 8px',
//         display: 'flex',
//         alignItems: 'center',
//         width: !isLoading && '70%',
//         overflow: 'scroll',
//         overflowY: 'hidden',
//     }),
//     multiValue: (provided, state) => ({
//         ...provided,
//         minWidth: 'auto',
//         background: 'transparent',
//         color: theme === 'dark' ? 'white!important' : 'black!important',
//         fontSize: '0.9rem!important',
//     }),
//     multiValueGeneric: (provided, state) => ({
//         ...provided,
//         'minWidth': 'auto',
//         'background': 'transparent',
//         'color': theme === 'dark' ? 'white!important' : 'black!important',
//         '& span': {
//             fontSize: '0.9rem!important',
//         },
//     }),
//     input: (provided, state) => ({
//         ...provided,
//         margin: '-7px',
//     }),
//     indicatorContainer: (provided, state) => ({
//         padding: '0px',
//     }),
//     singleValue: (provided, state) => ({
//         ...provided,
//         outline: 'none',
//         color: theme === 'dark' ? 'white !important' : 'black !important',
//         background: state.isFocused ? 'white' : 'white',
//     }),
//     loadingDot: (provided, state) => ({
//         ...provided,
//         width: '5px',
//         height: '5px',
//         fontSize: '0.5rem',
//     }),
//     menu: provided => ({
//         ...provided,
//         backgroundColor: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white', // Change the background color of the dropdown menu
//         color: theme === 'dark' ? 'white' : 'black',
//         border: 'none',
//         boxShadow:
//             theme === 'dark'
//                 ? '0px 4px 9px 0px rgba(0, 0, 0, 0.25)'
//                 : '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
//     }),
//     menuList: base => ({
//         ...base,
//         'backgroundColor': theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white', // Change the background color of the dropdown menu
//         'color': theme === 'dark' ? 'white' : 'black',
//         'border': 'none',
//         'borderRadius': '15px',
//         'boxShadow': '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
//         '::-webkit-scrollbar': {
//             display: 'none',
//         },
//     }),
// })

// export const SelectComponent = ({
//     selectedOptions,
//     optionType,
//     handleOptionChange,
//     optionData,
//     handleScroll,
//     defaultValue,
// }) => {
//     const { theme } = useSelector(state => state.themeReducer)

//     return (
//         <>
//             {optionData && (
//                 <Select
//                     value={selectedOptions[optionType]}
//                     onChange={selectedOption =>
//                         handleOptionChange(optionType, selectedOption)
//                     }
//                     className='react-select-container'
//                     classNamePrefix='react-select'
//                     options={optionData[optionType]?.data}
//                     placeholder={optionType}
//                     isSearchable={true}
//                     isClearable={true}
//                     onMenuScrollToBottom={() => handleScroll(optionType)}
//                     defaultValue={defaultValue}
//                     styles={customStyles(
//                         theme,
//                         optionData[optionType]?.isLoading
//                     )}
//                     isMulti
//                     closeMenuOnSelect={false}
//                     isOptionDisabled={option => option.count <= 0}
//                     getOptionLabel={(option, index) => (
//                         <div
//                             key={index}
//                             style={{
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 gap: '0.8rem',
//                             }}
//                         >
//                             <span style={{ display: 'block' }}>
//                                 {option.value}
//                             </span>
//                             <div style={{ color: 'rgb(147,155,170' }}>
//                                 {option.count}
//                             </div>
//                         </div>
//                     )}
//                     // Disable the dropdown when it's loading
//                     isDisabled={
//                         optionData[optionType]?.data.length > 0 ? false : true
//                     }
//                     isLoading={optionData[optionType]?.isLoading}
//                 />
//             )}
//         </>
//     )
// }
