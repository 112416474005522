import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { AlertContentStyled } from './alertBaseConfig'

export const displayMessage = (title, message) => {
    const MySwal = withReactContent(Swal)
    let alertContent = <AlertContentStyled>{message}</AlertContentStyled>
    MySwal.fire({
        title: <p className='alert-title'>{title}</p>,
        html: alertContent,
        showConfirmButton: true,
    })
}
