import { APIEndpointsHelper } from '../../../../../../index'

export class APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReport {
    static create() {
        return `/instance/stream/analytic/safety_equipment_detector/event/notification/report/`
    }

    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/safety_equipment_detector/event/notification/report/`,
            params
        )
    }
    static get(id) {
        return `/instance/stream/analytic/safety_equipment_detector/event/notification/report/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/safety_equipment_detector/event/notification/report/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/safety_equipment_detector/event/notification/report/${id}/`
    }
}

export class APIEndpointInstanceStreamAnalyticsSafetyEquipmentDetectorEventReportAsGuest {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/safety_equipment_detector/event/notification/report/guest/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/safety_equipment_detector/event/notification/report/guest/${id}/`
    }
}
