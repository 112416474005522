export class APIEndpointInstanceStreamAnalyticsCameraTamperingInstance {
  static getForStream(id) {
    return `/instance/stream/analytic/camera_tampering/stream/${id}/instance/`;
  }

  static get(id) {
    return `/instance/stream/analytic/camera_tampering/instance/${id}/`;
  }

  static update(id) {
    return `/instance/stream/analytic/camera_tampering/instance/${id}/`;
  }

  static destroy(id) {
    return `/instance/stream/analytic/camera_tampering/instance/${id}/`;
  }
}
