import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  profileReducer,
  themeReducer,
  projectsListReducer,
} from "./config/reducers";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { globalNavigationReducer } from "./config/reducers";
import styled from "styled-components";
import { StyledEngineProvider } from "@mui/material/styles";

// Your global styles for ToastContainer
const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    box-shadow: 0px 0px 10px -5px;
    border-radius: 15px;
  }
`;

const reducer = combineReducers({
  profileReducer,
  globalNavigationReducer,
  themeReducer,
  projectsListReducer,
});
export const store = createStore(reducer, applyMiddleware(thunk));

Sentry.init({
  dsn: "https://3c4508340ad3edbebcdc5a3b8e44c8f2@o4505232523853824.ingest.us.sentry.io/4507033576603648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

  tracePropagationTargets: [
    "localhost",
    /^https:\/\/(www\.)?nett-virtual\.deutics\.com/,
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
    <CustomToastContainer />
  </Provider>,
  document.getElementById("root")
);
