export class APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorInstance {
    static getForStream(id) {
        return `/instance/stream/analytic/line_intrusion_detector/stream/${id}/instance/`
    }

    static get(id) {
        return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    }
}
