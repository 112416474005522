import { APIEndpointsHelper } from '../../../../../../index'

export class APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReport {
    static create() {
        return `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/`
    }

    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/${id}/`
    }
}

export class APIEndpointInstanceStreamAnalyticsZoneIntrusionDetectorEventReportAsGuest {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/guest/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/zone_intrusion_detector/event/notification/report/guest/${id}/`
    }
}
