import { useSelector } from 'react-redux'
import styled from 'styled-components'
import '../../../styles/account_auth.css'
import { ThemeComponent } from '../toggletheme'
import { DarkThemeAuth } from './DarkThemeAuth'

const EmptyFrame = ({ children }) => {
    const { theme } = useSelector(state => state.themeReducer)
    return (
        <div
            id='body-2'
            style={
                theme === 'dark'
                    ? {
                          background: `linear-gradient(
          to right bottom,
          rgb(32, 45, 66),
          rgb(22, 33, 49),
          rgb(20, 45, 49)`,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
                    : {
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
        >
            <div className='toggleBtnAuth'>
                <ThemeComponent />
            </div>
            <DarkThemeAuth>
                <div
                    className={`main-frame d-flex justify-content-center align-items-center ${
                        theme === 'dark' && 'main-frame-dark'
                    }`}
                >
                    {children}
                </div>
            </DarkThemeAuth>
        </div>
    )
}

export default EmptyFrame
