export class BaseEnum {
    static keys() {
        return Object.getOwnPropertyNames(this).filter(
            name => name.toLowerCase() !== name
        )
    }

    static values() {
        return this.keys().map(key => this[key])
    }

    static items() {
        return this.keys().map(key => [key, this[key]])
    }
}
