export const FETCH_PROFILE_START = 'FETCH_PROFILE_START'
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const REFRESH_PROFILE_SUCCESS = 'REFRESH_PROFILE_SUCCESS'
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'

export const SELECT_INPUT_CLASS_NAME = 'select_input'

export const SET_ACTIVE_NAVIGATION = 'SET_ACTIVE_NAVIGATION'
export const CLEAR_ACTIVE_NAVIGATION = 'CLEAR_ACTIVE_NAVIGATION'

export const TOGGLE_THEME = 'TOGGLE_THEME'

export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START'
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
