import "../../../styles/camera.css";
import APIEndpoints from "../../../config/api/endpoints";
import { UserAccountType } from "../../../config/enums/user";
import { useContext } from "react";
import { GlobalUserProfileContext } from "../../../App";
import { UserListViewContainer } from "../../user/listView/Container";
import moment from "moment";
import { MainWrapper } from "../../MainWrapper";
import { useSelector } from "react-redux";

const SubAdminList = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const { theme } = useSelector((state) => state.themeReducer);
  return (
    <MainWrapper
      parent='sub_admin'
      isFetching={isFetching}
      profile={profile}
      pageTitle='Sub-Administrator'
    >
      <div id='body-camera'>
        <div className='main-frame-camera'>
          <div className='tab-content h-100 py-3 px-3'>
            <UserListViewContainer
              endpoint={APIEndpoints.auth.relation.getAllRelations({
                account_type: UserAccountType.SUB_ADMINISTRATOR,
              })}
              panelChildren={(params) => (
                <>
                  <hr className=' mb-2 mt-0' style={{ color: "transparent" }} />
                  <div className='content px-2 d-flex justify-content-between align-items-center'>
                    {params?.data?.data?.subscription?.is_active ? (
                      <span className='color-active'>Subscribed</span>
                    ) : (
                      <span className='color-disabled'>No Subscription</span>
                    )}
                  </div>
                  <hr className=' mb-2 mt-2' style={{ color: "transparent" }} />
                  <div className='content px-2 d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <span
                        className={`color-${
                          params?.data?.data?.is_active ? "active" : "disabled"
                        }`}
                      >
                        {params?.data?.data?.is_active ? "Active" : "Disabled"}
                      </span>

                      <div
                        className={`toggler mx-3 ${
                          theme === "dark" && "toggler-dark"
                        }`}
                        style={{
                          backgroundColor: params?.data?.data?.is_active
                            ? theme === "dark"
                              ? "rgba(94, 132, 195, 1)"
                              : "rgb(37, 68, 120, 1)"
                            : "transparent",
                        }}
                      >
                        <span
                          className={`indicator ${
                            params?.data?.data?.is_active
                              ? "active"
                              : "disabled"
                          }`}
                        ></span>
                        <span
                          className={`setter ${
                            params?.data?.data?.is_active
                              ? "active"
                              : "disabled"
                          }`}
                          onClick={() => params.toggleAccountState()}
                        ></span>
                      </div>
                    </div>
                    <span className=''>
                      {moment(params?.data?.data?.date_joined)
                        .local()
                        .format("DD-MM-YYYY")}
                    </span>
                  </div>
                </>
              )}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default SubAdminList;
