import '../../../styles/camera.css'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../App'
import { AddUserForm } from '../../user/add/AddUserForm'
import { MainWrapper } from '../../MainWrapper'

const AddSubUser = props => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )

    return (
        <MainWrapper
            parent='sub_user'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Add Sub-User'
        >
            <div id='body-camera'>
                <div className='main-frame-camera position-relative'>
                    <div className='tab-content py-3 px-3'>
                        <AddUserForm redirectOnSuccess={'/sub_users'} />
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default AddSubUser
