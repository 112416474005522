import axiosInstance from '../../../../config/axios'
import displayError from '../../../displayError'
import APIEndpoints from '../../../../config/api/endpoints'
import { useSelector } from 'react-redux'

export const singleFileUpload = async ({ fileBuffer, purpose }) => {
    const formData = new FormData()
    formData.append('file', fileBuffer)
    formData.append('purpose', purpose)

    return await (
        await axiosInstance()
    )
        .post(APIEndpoints.fileUpload.singleFileUpload(), formData)
        .then(response => {
            return response.data
        })
        .catch(err => {
            displayError(err)
        })
}

export const batchFileUpload = async ({ fileBuffers, purpose }) => {
    const formData = new FormData()
    formData.append('files', fileBuffers)
    formData.append('purpose', purpose)
    return await (
        await axiosInstance()
    )
        .post(APIEndpoints.fileUpload.batchFileUpload(), formData)
        .then(response => {
            return response.data
        })
        .catch(err => {
            displayError(err)
        })
}

export const deleteUploadedFile = async ({ fileID }) => {
    return await (
        await axiosInstance()
    )
        .delete(APIEndpoints.fileUpload.deleteUpload(fileID))
        .then(response => {
            return true
        })
        .catch(err => {
            displayError(err)
        })
}
