import '../../../../../../../../styles/analytic.css'
import '../../../../../../../../styles/literallycanvas.css'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import LC from 'literallycanvas/lib/js/literallycanvas-core'
import { ZoneIntrusionDetectorConfigContext } from '../Container'
import { StreamPlayerCanvasContainer } from '../../../../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer'

const ZoneIntrusionDetectorCanvasPersonSize = ({ stream }) => {
    const [pauseStream, setPauseStream] = useState(null)
    const canvasInitialized = useRef(false)
    const hasRenderedPreviousOnce = useRef(false)
    const [gCanvas, setgCanvas] = useState(null)
    const { boundingBoxData, setBoundingBoxData } = useContext(
        ZoneIntrusionDetectorConfigContext
    )
    const tempData = useRef({})

    const constructBoundingBox = useCallback(() => {
        if (boundingBoxData && gCanvas) {
            const { start_x, start_y, end_x, end_y } = boundingBoxData
            if (start_x && start_y && end_x && end_y) {
                const canvas = document.querySelector(
                    '#people-counter-canvas-person-size'
                )
                const { width, height } = canvas.getBoundingClientRect()
                const [box_actual_width, box_actual_height] = [
                    Math.abs(start_x - end_x) * width,
                    Math.abs(start_y - end_y) * height,
                ]
                const [box_start_x, box_start_y] = [
                    (start_x < end_x ? start_x : end_x || 0) * width,
                    (start_y < end_y ? start_y : end_y || 0) * height,
                ]
                gCanvas.saveShape(
                    LC.createShape('Rectangle', {
                        x: box_start_x,
                        y: box_start_y,
                        width: box_actual_width,
                        height: box_actual_height,
                        strokeWidth: 2,
                        strokeColor: '#ff0000',
                        fillColor: 'transparent',
                    })
                )
                const precision_factor =
                    (boundingBoxData?.precision_factor || 0) / 100
                const [width_offset, height_offset] = [
                    box_actual_width * precision_factor,
                    box_actual_height * precision_factor,
                ]
                const [outer_box_width, outer_box_height] = [
                    box_actual_width + width_offset * 2,
                    box_actual_height + height_offset * 2,
                ]
                const [outer_box_start_x, outer_box_start_y] = [
                    box_start_x - width_offset,
                    box_start_y - height_offset,
                ]
                gCanvas.saveShape(
                    LC.createShape('Rectangle', {
                        x: outer_box_start_x,
                        y: outer_box_start_y,
                        width: outer_box_width,
                        height: outer_box_height,
                        strokeWidth: 1,
                        strokeColor: '#ff0000',
                        fillColor: 'transparent',
                    })
                )
                const [inner_box_width, inner_box_height] = [
                    box_actual_width - width_offset * 2,
                    box_actual_height - height_offset * 2,
                ]
                const [inner_box_start_x, inner_box_start_y] = [
                    box_start_x + width_offset,
                    box_start_y + height_offset,
                ]
                gCanvas.saveShape(
                    LC.createShape('Rectangle', {
                        x: inner_box_start_x,
                        y: inner_box_start_y,
                        width: inner_box_width,
                        height: inner_box_height,
                        strokeWidth: 1,
                        strokeColor: '#ff0000',
                        fillColor: 'transparent',
                    })
                )
            }
        }
    }, [boundingBoxData, gCanvas])

    useEffect(() => {
        if (boundingBoxData && gCanvas) {
            gCanvas.clear()
            constructBoundingBox()
            if (!hasRenderedPreviousOnce.current) {
                hasRenderedPreviousOnce.current = true
            }
        }
    }, [gCanvas, boundingBoxData])

    useEffect(() => {
        if (
            document.querySelector('#people-counter-canvas-person-size') &&
            !canvasInitialized.current
        ) {
            const canvas = document.querySelector(
                '#people-counter-canvas-person-size'
            )
            const lc = LC.init(canvas, {
                // disable panning
                keyboardShortcuts: true,
                secondaryColor: 'transparent',
                primaryColor: '#ff0000',
                defaultStrokeWidth: 2,
                tools: [LC.tools.Rectangle],
            })
            setgCanvas(lc)
            canvas.addEventListener('pointerdown', e => {
                setPauseStream(true)
                lc.clear()
                const { offsetX, offsetY } = e
                const { width, height } = canvas.getBoundingClientRect()
                tempData.current = {
                    start_x: offsetX / width,
                    start_y: offsetY / height,
                    precision_factor: boundingBoxData?.precision_factor,
                }
            })
            canvas.addEventListener('pointerup', e => {
                setPauseStream(false)
                if (tempData.current?.start_x && tempData.current?.start_y) {
                    const { offsetX, offsetY } = e
                    const { width, height } = canvas.getBoundingClientRect()
                    tempData.current = {
                        ...tempData.current,
                        end_x: offsetX / width,
                        end_y: offsetY / height,
                        precision_factor: boundingBoxData?.precision_factor,
                    }
                    setBoundingBoxData(tempData.current)
                }
            })
            canvasInitialized.current = true
        }
    }, [])

    return (
        <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
            <div className='people-counter-canvas-width-only text-analytic-header d-flex justify-content-start'>
                Person Size
            </div>
            <div className='people-counter-canvas'>
                <div className='wrapper'>
                    <StreamPlayerCanvasContainer
                        stream={stream}
                        pauseStream={pauseStream}
                        clearWGLOnExit={false}
                    />
                </div>
                <div
                    className='wrapper'
                    id={'people-counter-canvas-person-size'}
                ></div>
            </div>
        </div>
    )
}

export default ZoneIntrusionDetectorCanvasPersonSize
