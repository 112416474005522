import axiosInstance from "../config/axios";
import { fetchAllPaginatedListing } from "../helpers/apiRequests";
import displayError from "../helpers/displayError";
import APIEndpoints from "../config/api/endpoints";
import { APIAuthUtils } from "../helpers/utils/api/auth";
import { retrieveFromLocalStorage } from "../helpers/localStorage";
import { JWTTokenUtilsHelper } from "../helpers/utils/token/jwt";
import moment from "moment";
import { APIEndpointInstanceVPSStreamSequence } from "../config/api/endpoints/instance/vps/stream_sequence";

export const accessTokenCheckExistence = async () => {
  const access_token = retrieveFromLocalStorage("access_token");
  if (access_token === null) {
    window.localStorage.setItem("refresh_token", null);
    return false;
  }
  const decodedAccessToken = JWTTokenUtilsHelper.decode(access_token);
  if (moment() >= decodedAccessToken.exp.clone().subtract(5, "seconds")) {
    window.localStorage.setItem("access_token", null);
    const refreshToken = retrieveFromLocalStorage("refresh_token");
    if (refreshToken) {
      const decodedRefreshToken = JWTTokenUtilsHelper.decode(refreshToken);
      if (moment() <= decodedRefreshToken.exp.clone().subtract(5, "seconds")) {
        const accessTokenRefreshResponse =
          await APIAuthUtils.refreshAccessToken();
        if (accessTokenRefreshResponse) {
          return true;
        }
      }
      window.localStorage.setItem("refresh_token", null);
    }
    return false;
  }
  return true;
};

export const isGuest = async function () {
  return !(await accessTokenCheckExistence());
};

export const isAuthenticated = async function () {
  return await accessTokenCheckExistence();
};

export const isAccountTypes = async (types_to_check) => {
  return await (
    await axiosInstance()
  )
    .get(APIEndpoints.auth.me.fetchMyProfile())
    .then((response) => {
      const profile = response.data;
      return types_to_check.includes(profile.account_type);
    })
    .catch((err) => {
      return false;
    });
};

export const accountHasSubscription = async () => {
  return await (
    await axiosInstance()
  )
    .get(APIEndpoints.auth.me.fetchMyProfile())
    .then((response) => {
      const { subscription } = response.data;
      return subscription?.is_active;
    })
    .catch((err) => {
      return false;
    });
};

export const accountIsComplete = async () => {
  return await (
    await axiosInstance()
  )
    .get(APIEndpoints.auth.me.fetchMyProfile())
    .then((response) => {
      const { is_account_complete } = response.data;
      return is_account_complete;
    })
    .catch((err) => {
      return false;
    });
};

export const accountHasProject = async () => {
  return await (
    await axiosInstance()
  )
    .get(APIEndpoints.user.account.project.getAllProjects())
    .then((response) => {
      const projects = response.data;
      return !!projects.length;
    })
    .catch((err) => {
      return false;
    });
};

export class VPSRouteConditions {
  static async hasVPSCameraSequence() {
    let endpoint = APIEndpointInstanceVPSStreamSequence.fetchAll();
    return (
      (await fetchAllPaginatedListing(
        endpoint,
        "Error in fetching vps camera sequences"
      )) || []
    ).filter((item) => item?.checklist_items_count).length;
  }

  static async hasConfiguredVPS() {
    const state = await VPSRouteConditions.hasVPSCameraSequence();
    if (!state) {
      displayError(
        "You have to configure your VPS profile by setting your camera sequences and checklists in order to start a VPS session"
      );
    }
    return state;
  }
}
