import React from "react";
import styled from "styled-components";
import Spinner from "../../../../Spinner";
import { NotificationListItem } from "./components/NotificationListItem";

const StyledContent = styled.div`
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: 70vh !important;
  overflow-y: scroll;
  margin-top: 0.3rem;
`;

export const NotifyContent = ({
  notificationList,
  canRender,
  onScroll,
  handleOpenModal,
}) => {
  return (
    <StyledContent onScroll={onScroll}>
      {canRender === true &&
        notificationList?.data &&
        notificationList.data.map((item, i) => {
          return (
            <NotificationListItem
              handleOpenModal={handleOpenModal}
              item={item}
              key={i}
            />
          );
        })}
      {(canRender === false || notificationList?.isFetching === true) && (
        <div className='spinnerWrapper'>
          <Spinner size={1.5} />
        </div>
      )}
    </StyledContent>
  );
};
