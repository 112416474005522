import {APIEndpointsHelper} from "../../index";

export class APIEndpointUserAccountProject {
    static getAllProjects(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams('/user/account/project/', params)
    }

    static createProject() {
        return `/user/account/project/`
    }

    static getProject(id) {
        return `/user/account/project/${id}`
    }

    static editProject(id) {
        return `/user/account/project/${id}/`
    }

    static deleteProject(id) {
        return `/user/account/project/${id}/`
    }
}