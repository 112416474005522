import {APIEndpointsHelper} from "../index";

export class APIEndpointGlobalVariable {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams('/config/global_variable/', params)
    }

    static forceGet(key) {
        const params = {key}
        return APIEndpointsHelper.parseEndpointForQueryParams('/config/global_variable/force_retrieve/', params)
    }

    static get(id) {
        return `/config/global_variable/${id}/`
    }

    static update(id) {
        return `/config/global_variable/${id}/`
    }
}