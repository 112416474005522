import {retrieveFromLocalStorage} from "../../localStorage";
import axiosInstance from "../../../config/axios";
import APIEndpoints from "../../../config/api/endpoints";
import {GlobalRoutes} from "../../../routes/GlobalRoutes";

export class APIAuthUtils {
    static async refreshAccessToken() {
        const refreshToken = retrieveFromLocalStorage('refresh_token')
        if (refreshToken) {
            const payload = {
                "refresh": refreshToken
            };
            return await (await axiosInstance()).post(APIEndpoints.auth.token.refresh(), payload).then((response) => {
                    const {access} = response.data;
                    window.localStorage.setItem('access_token', access);
                    return access
                }
            ).catch((err) => {

            });
        }
    }


    static clearSessionAndMoveToLoginPage() {
        window.localStorage.setItem('access_token', null);
        window.localStorage.setItem('refresh_token', null);
        window.location.href = GlobalRoutes.pathDefinitions.account.login2
    }
}