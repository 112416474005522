import styled from "styled-components";

const StyledComponent = styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    
    *{
        font-size: 0.9rem;
    }
    
    .title{
        font-weight: 700;
    }
    
`

export const ErrorDisplayObject = ({errorData}) => {
    const {title, message} = errorData;
    return (
        <StyledComponent>
            {title && <span className='title'>{title}</span>}
            {<span className='message'>{message}</span>}
        </StyledComponent>
    )
};