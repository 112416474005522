export class APIEndpointUserAccountSubscription {
    static getPersonalSubscription() {
        return `/user/account/subscription/personal/`
    }

    static getSubscription(id) {
        return `/user/account/subscription/${id}/`
    }

    static editSubscription(id) {
        return `/user/account/subscription/${id}/`
    }
}