import "../../../../../styles/camera.css";
import { useParams } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { MainWrapper } from "../../../../MainWrapper";
import { GlobalUserProfileContext } from "../../../../../App";
import { useLoadDependenciesBeforeRender } from "../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import APIEndpoints from "../../../../../config/api/endpoints";
import { APIUtils } from "../../../../../helpers/utils/api";
import { AuthPagePreloaderComponent } from "../../../../global/graphical/AuthPagePreloaderComponent";
import { useSelector } from "react-redux";
import CanvasStream from "./objects/CanvasStream";
import InfoIcon from "../../../../svgTojsx/InfoIcon";

const StyledComponent = styled.div`
  .vertical-line {
    background-color: ${(props) =>
      props.theme === "dark"
        ? "rgba(94, 132, 195, 1)"
        : "rgba(228, 228, 228, 1)"};
    width: 2px;
    margin-top: 4.3rem;
    @media (max-width: 1285px) {
      display: none;
    }
  }
  .canvasDrawBoxOuter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    @media (max-width: 1150px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .canvasBox {
      width: auto;
    }
  }

  .msg-box {
    background: white;
    border-radius: 10px;
    width: 18%;
    min-width: 150px;
    max-width: 250px;
    height: 120px;
    margin-top: 3vw;
    padding: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    display: grid;
    place-items: center;
    gap: 0.5rem;

    @media (max-width: 1150px) {
      width: 100%;
      max-width: 100%;
      height: 70px;
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0px;
      }
    }
  }
`;

export const VideoBackupConfigContext = createContext({});

const VideoBackupConfigView = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const { theme } = useSelector((state) => state.themeReducer);
  const [stream, setStream] = useState(null);
  const { id: streamID } = useParams();

  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      b: stream?.isFetching,
    },
  });

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.instance.stream.main.get(streamID),
        item: stream,
        setItem: setStream,
      });
    }
  }, [profile]);

  return (
    <MainWrapper
      parent='stream'
      isFetching={isFetching}
      profile={profile}
      pageTitle='Settings'
    >
      <StyledComponent theme={theme}>
        <div id='body-camera'>
          <div className='main-frame-camera'>
            <div className='tab-content h-100 py-3 px-3'>
              {canRender ? (
                <div className='canvasDrawBoxOuter'>
                  <VideoBackupConfigContext.Provider value={{}}>
                    <div className='canvasBox'>
                      <CanvasStream stream={stream?.data} />
                    </div>
                  </VideoBackupConfigContext.Provider>
                  <div className='vertical-line'></div>
                  <div className='msg-box'>
                    <InfoIcon />
                    <p>Your data will be retained for 30 days.</p>
                  </div>
                </div>
              ) : (
                <AuthPagePreloaderComponent />
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </StyledComponent>
    </MainWrapper>
  );
};

export default VideoBackupConfigView;
