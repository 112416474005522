import React, { useContext, useEffect, useState } from "react";
import { GlobalUserProfileContext } from "../../../../../../../App";
import { SelectComponent } from "./SelectComponent";
import displayError from "../../../../../../../helpers/displayError";
import { useSelector } from "react-redux";
import { APIUtils } from "../../../../../../../helpers/utils/api";
import axiosInstance from "../../../../../../../config/axios";

const CameraFilters = ({ setterCallback }) => {
  const { isFetchingProfile: isFetching, profile } = useContext(GlobalUserProfileContext);

  const { theme } = useSelector((state) => state.themeReducer);
  const selectArray = ["Features", "Site", "Camera"];
  const [selectedOptions, setSelectedOptions] = useState({
    Camera: "",
    Site: "",
    Features: "",
  });

  const [optionData, setOptionData] = useState({
    Features: {
      data: [
        { value: "Line Intrusion", label: "LINE_INTRUSION" },
        { value: "Zone Intrusion", label: "ZONE_INTRUSION" },
        {
          value: "Safety Equipment",
          label: "SAFETY_EQUIPMENT",
        },

        { value: "Motion Detection", label: "MOTION_DETECTION" },
        { value: "Camera Tampering", label: "CAMERA_TAMPERING" },
        { value: "Fire and Smoke", label: "FIRE_AND_SMOKE" },
      ],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
    Site: {
      data: [],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
    Camera: {
      data: [],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
  });

  const fieldMappings = {
    Site: {
      label: "project_id",
      value: "project_name",
      count: "count",
    },
    Camera: {
      label: "stream_id",
      value: "stream_name",
    },
  };

  const fetchOptions = async (optionType, parentID = null, page = 1, scrolling = false) => {
    try {
      let endpoint = "";
      let pagination = `limit=${100}&offset=${(page - 1) * 100}`;

      if (optionType === "Site") {
        let parentIDs;
        if (!parentID || parentID?.length <= 0) {
          // Extract the labels (IDs) from parentID array
          parentIDs = [profile.id];
        } else {
          parentIDs = parentID.map((item) => item.label);
        }

        let queryParams = APIUtils.formatQueryFields(parentIDs, "subUser");
        endpoint = `/instance/stream/filter?${queryParams}`;
      } else if (optionType === "Camera") {
        if (!parentID || parentID?.length <= 0) {
          return;
        } else {
          const parentIDs = parentID.map((item) => item.label);
          let SubUserIDs;
          if (!selectedOptions.SubUser || selectedOptions.SubUser?.length <= 0) {
            // Extract the labels (IDs) from parentID array
            SubUserIDs = [profile.id];
          } else {
            SubUserIDs = selectedOptions.SubUser.map((item) => item.label);
          }

          let queryParams = APIUtils.formatQueryFields(SubUserIDs, "subUser");
          let queryParamsSon = APIUtils.formatQueryFields(parentIDs, "projects");
          endpoint = `/instance/stream/filter?${queryParams}&${queryParamsSon}`;
        }
      }

      let response;

      await (await axiosInstance()).get(endpoint).then((resp) => {
        response = resp;
      });

      if ((response.data.length <= 0 || response.data.every((item) => item.count === 0)) && optionType === "SubUser") {
        fetchOptions("Site");
      }

      const mappedData = response.data
        .filter(
          (item) => item[fieldMappings[optionType].label] !== null && item[fieldMappings[optionType].label] !== ""
        )
        .map((item) => ({
          label: item[fieldMappings[optionType].label],
          value: item[fieldMappings[optionType].value],
          count: item[fieldMappings[optionType].count],
        }));

      // console.log('before update', optionData)

      // console.log('Mapped Data', mappedData)

      // Check for duplicates and filter them out
      const uniqueData = mappedData.filter((newItem) => {
        return !optionData[optionType].data.some((existingItem) => existingItem.label === newItem.label);
      });
      // console.log('Unique Data', uniqueData)

      // console.log(response.data)
      // console.log(response)
      let updatedOptionData;
      if (scrolling) {
        // Create a new object with updated data
        updatedOptionData = {
          ...optionData,
          [optionType]: {
            data: [...optionData[optionType].data, ...uniqueData],
            currentPage: page,
            hasMore: response.extraData.next !== null,
            isLoading: false,
          },
        };
      } else {
        // Create a new object with updated data
        updatedOptionData = {
          ...optionData,
          [optionType]: {
            data: mappedData,
            currentPage: page,
            hasMore: response.extraData.next !== null,
            isLoading: false,
          },
        };
      }

      // console.log('after Update: ', updatedOptionData)

      // Update state
      setOptionData(updatedOptionData);
    } catch (err) {
      // console.log(err)
      displayError(err, theme);
    }
  };

  const handleOptionChange = (optionType, value) => {
    if (optionType === "Features") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Features: value,
      }));
    }
    if (optionType === "Site") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Site: value,
        Camera: value === null || value?.length <= 0 ? [] : prevData.Camera,
      }));
      const updatedValue = {
        ...optionData,
        Camera: {
          data: [],
          currentPage: 1,
          hasMore: false,
          isLoading: value && value.length > 0,
        },
      };
      setOptionData(updatedValue);
      fetchOptions("Camera", value);
    }
    if (optionType === "Camera") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Camera: value,
      }));
    }
  };

  const handleScroll = (optionType) => {
    if (optionData[optionType].hasMore) {
      if (optionType === "Site") {
        fetchOptions(optionType, selectedOptions.SubUser, optionData[optionType].currentPage + 1, true);
      } else if (optionType === "Camera") {
        fetchOptions(optionType, selectedOptions.Site, optionData[optionType].currentPage + 1, true);
      }
    }
  };

  useEffect(() => {
    fetchOptions("Site");
  }, []);

  useEffect(() => {
    setterCallback(selectedOptions);
  }, [selectedOptions]);

  return (
    <div className="d-flex flex-column w-100 gap-2">
      {selectArray?.map((optionType, index) => (
        <SelectComponent
          key={index}
          optionType={optionType}
          selectedOptions={selectedOptions}
          optionData={optionData}
          handleOptionChange={handleOptionChange}
          handleScroll={handleScroll}
        />
      ))}
    </div>
  );
};

export default CameraFilters;
