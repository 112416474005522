import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../../Spinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { VPSContext } from '../../../MainWrapper'
import APIEndpoints from '../../../../../../../config/api/endpoints'
import { useLoadDependenciesBeforeRender } from '../../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    h5 {
        margin-top: 0;
        padding-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.6rem;
    }

    input {
        color: #8a8888;
        text-indent: 0.5rem !important;
        height: 30px !important;
        font-weight: 400;
        font-size: 12px !important;
        border-bottom: 1.5px solid rgb(37, 68, 120) !important;
    }
    .add-camera_btn {
        padding: 0.4rem 1.2rem;
    }
`
export const AddCheckListNegativeActionPredefinedMessage = ({
    checklistItem,
    callBackOnProcessed,
}) => {
    const { theme } = useSelector(state => state.themeReducer)
    const { VPSInstance } = useContext(VPSContext)
    const [isProcessingAddRequest, setIsProcessingAddRequest] = useState(false)
    const [data, setData] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: isProcessingAddRequest,
            b: VPSInstance?.isFetching,
        },
    })
    const handleOnSubmit = useCallback(async () => {
        const requestData = {
            checklist_item: checklistItem?.id,
            ...data,
        }
        setIsProcessingAddRequest(true)
        await (
            await axiosInstance()
        )
            .post(
                APIEndpoints.instance.vps.checklistItemNegativeActionPredefinedMessage.add(),
                requestData
            )
            .then(response => {
                callBackOnProcessed()
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsProcessingAddRequest(false)
    }, [data, checklistItem, callBackOnProcessed])

    return (
        <Wrapper>
            <h5>Add Negative Choice Message</h5>
            <input
                type='text'
                placeholder='Message Content'
                value={data?.content}
                onChange={e => HandleOnChangeInput(e, 'content', setData, data)}
            />
            <SizedBox height={1} />
            {isProcessingAddRequest || VPSInstance?.isFetching ? (
                <Spinner size={1.5} />
            ) : (
                <Link
                    className='orange-text add-camera_btn'
                    to=''
                    onClick={handleOnSubmit}
                >
                    ADD
                </Link>
            )}
        </Wrapper>
    )
}
