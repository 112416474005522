import APIEndpoints from '../../../../config/api/endpoints'
import displayError from '../../../../helpers/displayError'
import axiosInstance from '../../../../config/axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import Spinner from '../../../Spinner'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { GlobalUserProfileContext } from '../../../../App'
import { APIUtils } from '../../../../helpers/utils/api'
import { useSelector } from 'react-redux'

export const UpdateSubAdminExtras = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const { id: userID } = useParams()
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const [user, setUser] = useState(null)

    const handleEditSubscription = useCallback(
        async new_date_of_expiration => {
            const subscriptionID = user?.data?.subscription?.id
            if (subscriptionID) {
                const requestPayload = {
                    date_of_expiration: new_date_of_expiration,
                }
                await (
                    await axiosInstance()
                )
                    .patch(
                        APIEndpoints.user.account.subscription.editSubscription(
                            subscriptionID
                        ),
                        requestPayload
                    )
                    .then(async response => {
                        APIUtils.fetchItemData({
                            endpoint: APIEndpoints.auth.relation.getRelation(
                                user?.data?.id
                            ),
                            item: user,
                            setItem: setUser,
                        })
                    })
                    .catch(err => {
                        displayError(err, theme)
                    })
            }
        },
        [user]
    )

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.auth.relation.getRelation(userID),
                item: user,
                setItem: setUser,
            })
        }
    }, [])

    return (
        <>
            <h6 className='mt-3'>Subscription</h6>
            {user?.isFetching === true || user?.isFetching === null ? (
                <div className='d-flex justify-content-center w-100 mt-5'>
                    {user?.isFetching === true && <Spinner size={1.8} />}
                </div>
            ) : (
                <div className='grid-region my-3'>
                    <div
                        className={`dashpanel p-2 p-md-3 ${
                            theme === 'dark' && 'dashpanel-dark'
                        }`}
                    >
                        <div className='title d-flex justify-content-between'>
                            <div>
                                Started On :{' '}
                                {moment(user?.data?.subscription?.date_added)
                                    .local()
                                    .format('YYYY-MM-DD')}
                            </div>
                            <div className='d-flex'>
                                <a
                                    href='#'
                                    onClick={() => handleEditSubscription(null)}
                                    className='material-icons camera-control-icon red-button'
                                >
                                    delete
                                </a>
                            </div>
                        </div>

                        <div className='content px-3 d-flex justify-content-end'>
                            Expires On :{' '}
                            {user?.data?.subscription?.date_of_expiration}
                        </div>

                        <div className='input-wrapper'>
                            <label
                                htmlFor='camera'
                                style={{
                                    color:
                                        theme === 'dark'
                                            ? 'rgba(94, 132, 195, 1)'
                                            : '',
                                }}
                            >
                                New Date of Expiration
                            </label>
                            <input
                                onKeyPress={e => {
                                    if (e.charCode === 13) {
                                        handleEditSubscription(e.target.value)
                                    }
                                }}
                                type='date'
                                className={`form-control form-control-custom ${
                                    theme === 'dark' &&
                                    'form-control-custom-dark'
                                }`}
                                id='camera'
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
