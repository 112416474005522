export class APIEndpointFileUpload {
    static singleFileUpload() {
        return '/upload/'
    }

    static batchFileUpload() {
        return '/upload/batch_upload/'
    }

    static fetchAllUploads() {
        return '/upload/'
    }

    static editUpload(id) {
        return `/upload/${id}/`
    }

    static deleteUpload(id) {
        return `/upload/${id}/`
    }

    static fetchUpload(id) {
        return `/upload/${id}/`
    }
}