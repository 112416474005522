import { LineIntrusionAnalyticEventNotificationRemarkType } from '../../../../../config/enums/instance/stream/analytic/line_intrusion_detector/index'
import { ZoneIntrusionAnalyticEventNotificationRemarkType } from '../../../../../config/enums/instance/stream/analytic/zone_intrusion_detector/index'

export const notificationRemarkStateColorMapping = {}
notificationRemarkStateColorMapping[
    LineIntrusionAnalyticEventNotificationRemarkType.INCRIMINATING
] = {
    bg: '#FB2576',
    fg: '#ffffff',
}
notificationRemarkStateColorMapping[
    LineIntrusionAnalyticEventNotificationRemarkType.FALSE_ALARM
] = {
    bg: '#A3C7D6',
    fg: '#000000',
}
notificationRemarkStateColorMapping[
    LineIntrusionAnalyticEventNotificationRemarkType.NON_INCRIMINATING
] = {
    bg: '#A77979',
    fg: '#ffffff',
}
notificationRemarkStateColorMapping[
    LineIntrusionAnalyticEventNotificationRemarkType.CUSTOM
] = {
    bg: '#D8D8D8',
    fg: '#000000',
}
notificationRemarkStateColorMapping[
    ZoneIntrusionAnalyticEventNotificationRemarkType.INCRIMINATING
] = {
    bg: '#FB2576',
    fg: '#ffffff',
}
notificationRemarkStateColorMapping[
    ZoneIntrusionAnalyticEventNotificationRemarkType.FALSE_ALARM
] = {
    bg: '#A3C7D6',
    fg: '#000000',
}
notificationRemarkStateColorMapping[
    ZoneIntrusionAnalyticEventNotificationRemarkType.NON_INCRIMINATING
] = {
    bg: '#A77979',
    fg: '#ffffff',
}
notificationRemarkStateColorMapping[
    ZoneIntrusionAnalyticEventNotificationRemarkType.CUSTOM
] = {
    bg: '#D8D8D8',
    fg: '#000000',
}
