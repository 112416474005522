import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GlobalUserProfileContext } from '../../../../App'
import { useLoadDependenciesBeforeRender } from '../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { APIUtils } from '../../../../helpers/utils/api'
import APIEndpoints from '../../../../config/api/endpoints'
import Spinner from '../../../Spinner'
import { SubAdminSubUserRoutes } from '../../../../routes/SubAdmin&SubUserRoutes'
import { MainWrapper } from '../../../MainWrapper'
import { TableCardComponent } from './streampanel/TableCardComponent'
import { useDispatch, useSelector } from 'react-redux'
import { projectsList } from '../../../../config/actions'
import { FilterContainer } from '../../../global/filters/Container'
import { SearchComponent } from '../../../global/filters/SearchComponent'

const StreamList = ({ listEndpoint }) => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )

    const { projectsList: projects } = useSelector(
        state => state.projectsListReducer
    )

    const dispatch = useDispatch()

    useEffect(() => {
        profile && dispatch(projectsList(profile.id))
    }, [profile])

    const { theme } = useSelector(state => state.themeReducer)
    const [showDropdown, setShowDropdown] = useState(false)

    const [streams, setStreams] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: streams?.isFetching,
        },
    })

    const [filterData, setFilterData] = useState({})
    const [searchParam, setSearchParam] = useState('')

    useEffect(() => {
        if (profile) {
            const params = {
                owner:
                    filterData?.SubUser?.length >= 1
                        ? `${filterData?.SubUser?.map(item => item.label).join(
                              ','
                          )}`
                        : profile?.id,
                ordering: 'project',
            }

            if (searchParam !== '' && searchParam !== null) {
                params.stream_name = searchParam
            }

            if (filterData?.Site?.length >= 1) {
                params.projects = `${filterData?.Site?.map(
                    item => item.label
                ).join(',')}`
            }

            if (filterData?.Camera?.length >= 1) {
                params.streams = `${filterData?.Camera?.map(
                    item => item.label
                ).join(',')}`
            }
            APIUtils.fetchItemData({
                endpoint:
                    listEndpoint ||
                    APIEndpoints.instance.stream.main.getAll(params),
                item: streams,
                setItem: setStreams,
            })
        }
    }, [profile, filterData, searchParam])

    const handleFilterData = data => {
        setFilterData(data)
    }

    return (
        <MainWrapper
            parent='stream'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Configuration'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    <div
                        className={`flex flex-col w-100 h-100 ${
                            theme === 'dark' && 'tab-content-header-dark-text'
                        }`}
                    >
                        <div
                            className={`mt-2 tab-content-header d-flex ${
                                theme === 'dark' &&
                                'tab-content-header-dark-bg tab-content-header-dark-text'
                            }`}
                        >
                            <div className='filters-content'>
                                <FilterContainer
                                    componentName={'FilterByUserSiteCamera'}
                                    setterCallback={handleFilterData}
                                    liveSearch={true}
                                    defaultValues={null}
                                />

                                <div className='search-add'>
                                    <SearchComponent
                                        searchParam={setSearchParam}
                                    />
                                    <span
                                        className={`navbarDwn ${
                                            theme === 'dark' && 'navbarDwn-dark'
                                        }`}
                                        onClick={() =>
                                            setShowDropdown(!showDropdown)
                                        }
                                    >
                                        Add
                                    </span>
                                    {showDropdown && (
                                        <div
                                            className={`dropdownContent ${
                                                theme === 'dark' &&
                                                'dropdownContent-dark'
                                            }`}
                                        >
                                            <Link
                                                to={{
                                                    pathname:
                                                        SubAdminSubUserRoutes
                                                            .pathDefinitions
                                                            .project.addProject,
                                                    state: { reload: true },
                                                }}
                                            >
                                                Sites
                                            </Link>
                                            <Link
                                                className=''
                                                to={{
                                                    pathname:
                                                        SubAdminSubUserRoutes
                                                            .pathDefinitions
                                                            .stream.addStream,
                                                    state: { reload: true },
                                                }}
                                            >
                                                Cameras
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {canRender !== true ? (
                            <div className='d-flex justify-content-center w-100 mt-5'>
                                {canRender === false && <Spinner size={1.8} />}
                            </div>
                        ) : (
                            streams?.data !== null &&
                            (!streams?.data?.length ? (
                                <div className='d-flex justify-content-center w-100 mt-5'>
                                    You currently have no stream
                                </div>
                            ) : (
                                <TableCardComponent
                                    streams={streams}
                                    setStreams={setStreams}
                                    listEndpoint={listEndpoint}
                                    profile={profile}
                                    theme={theme}
                                    projects={projects}
                                    filterData={filterData}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default StreamList
