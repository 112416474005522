import "../../../../../../styles/analytic.css";
import { StreamPlayerCanvasContainer } from "../../../../../global/graphical/instance/stream/StreamPlayerCanvasContainer";

const CanvasStream = ({ stream }) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-5'>
      <div className='people-counter-canvas-width-only text-analytic-header d-flex justify-content-start'>
        Video Backup
      </div>
      <div className='people-counter-canvas'>
        <div className='wrapper'>
          <StreamPlayerCanvasContainer stream={stream} />
        </div>
      </div>
    </div>
  );
};

export default CanvasStream;
