import dummyImage from '../../../../static/no_picture.png'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const StyledComponent = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    .imageWrapper {
        width: 7rem;
        height: 7rem;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .controlsWrapperImage input[type='file'] {
        display: none;
        ${'' /* Hide the default file input */}
    }
    .controlsWrapperImage {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        .text {
            color: var(--custom-white);
            font-weight: 600;
            font-size: 2rem;
        }
    }
`

export const ImagePickerComponent = ({ image, setImage, inputUploadText }) => {
    const [tempImage, setTempImage] = useState(null)
    const [tempImageData, setTempImageData] = useState(null)
    const { theme } = useSelector(state => state.themeReducer)
    useEffect(() => {
        if (tempImage) {
            const fileReader = new FileReader()
            fileReader.addEventListener(
                'load',
                () => {
                    setTempImageData(fileReader.result)
                },
                false
            )
            fileReader.readAsDataURL(tempImage)
            if (setImage) setImage(tempImage)
        }
    }, [tempImage])

    return (
        <StyledComponent theme={theme}>
            <div className='imageWrapper'>
                <img src={tempImageData || image || dummyImage} alt={''} />
            </div>
            <div className='controlsWrapperImage'>
                <div style={{ cursor: 'pointer' }}>
                    <label
                        htmlFor='inputFile'
                        className={`input-tag-upload_btn ${
                            theme === 'dark' && 'input-tag-upload_btn-dark'
                        }`}
                    >
                        {inputUploadText || 'Choose File'}
                        <input
                            type='file'
                            onChange={e => {
                                setTempImage(e.target.files[0])
                            }}
                            id='inputFile'
                        />
                    </label>
                </div>
            </div>
        </StyledComponent>
    )
}
