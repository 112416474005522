import React from "react";
import styled from "styled-components";

export const ConfigIcon = ({ theme }) => {
  return (
    <StyledComponent theme={theme}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19'
        height='21'
        fill='none'
        viewBox='0 0 19 21'
      >
        <path fill='#254478' d='M0 0h7v21H0zM7 10h5v11H7zM12 6h7v15h-7z'></path>
      </svg>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  svg {
    path {
      fill: ${(props) =>
        props.theme === "dark" ? "rgba(94, 132, 195, 1)" : "#254478"};
    }
  }
`;
