import '../../../../../../../styles/camera.css'
import { useParams } from 'react-router-dom'
import React, { createContext, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { GlobalUserProfileContext } from '../../../../../../../App'
import { APIUtils } from '../../../../../../../helpers/utils/api'
import APIEndpoints from '../../../../../../../config/api/endpoints'
import { useLoadDependenciesBeforeRender } from '../../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { AuthPagePreloaderComponent } from '../../../../../../global/graphical/AuthPagePreloaderComponent'
import { displayMessage } from '../../../../../../../helpers/displayMessage'
import fireSpinnerAlert from '../../../../../../../helpers/alertSpinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import ZoneIntrusionDetectorCanvasBoundaryZone from './objects/CanvasBoundaryZone'
import ZoneIntrusionDetectorCanvasPersonSize from './objects/CanvasPersonSize'
import { MainWrapper } from '../../../../../../MainWrapper'
import { useSelector } from 'react-redux'

const StyledComponent = styled.div`
    .vertical-line {
        background-color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(228, 228, 228, 1)'};
        width: 2px;
        margin-top: 4.3rem;

        @media (max-width: 600px) {
            display: none;
        }
    }
    .canvasDrawBoxOuter {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
        @media (max-width: 1100px) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
        .canvasBox {
            width: auto;
        }
        .people_counter_canvas_width_only {
            padding: 1rem;
            border-radius: 1rem;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            button {
                width: 100%;
            }

            .currentModeOptionWrapper {
                margin: 1rem;
            }

            .boundaryLineDirectionWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inputWrapper {
                    display: flex;
                    justify-content: flex-start;
                    gap: 3px;

                    .input {
                        position: initial;
                        opacity: initial;
                        pointer-events: initial;
                        cursor: pointer;
                    }
                }
            }

            .boundingBoxPrecisionFactorWrapper,
            .timeBoundWrapper {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .input {
                    height: 2rem !important;
                    width: 100%;
                    ${props =>
                        props.theme === 'dark' &&
                        `
                          border-bottom: 1px solid rgba(94, 132, 195, 1); 
                          color: white !important;
                        `};
                }
            }

            .timeBoundWrapper {
                gap: 1rem;
            }

            .timeBoundWrapper > .inputWrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: stretch;
            }
        }
        .people_counter_canvas_draw_Box {
            gap: 1rem;
            display: none;
        }
        .people_counter_canvas_draw_Box.Active {
            display: flex;
            flex-direction: column;

            * {
                font-size: 0.6rem;
            }

            button {
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .people_counter_canvas_sidebar {
            width: 18%;
            min-width: 150px;
            max-width: 250px;
            overflow: hidden;
            margin-top: 3vw;
            padding: 0.5rem;
        }
    }
    .saveButton {
        justify-content: flex-end;
        display: flex;
    }
`

export const ZoneIntrusionDetectorConfigContext = createContext({})

const ZoneIntrusionDetectorConfigView = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)
    const [stream, setStream] = useState(null)
    const { id: streamID } = useParams()
    const [boundingBoxMode, setBoundingBoxMode] = useState(false)
    const [boundaryZoneMode, setBoundaryZoneMode] = useState(true)
    const [timeBoundMode, setTimeBoundMode] = useState(false)
    const [dwellingTimeMode, setDwellingTimeMode] = useState(false)
    const [analyticConfiguration, setAnalyticConfiguration] = useState(null)
    const [boundaryZoneData, setBoundaryZoneData] = useState(null)
    const [boundingBoxData, setBoundingBoxData] = useState(null)
    const [timeBoundData, setTimeBoundData] = useState(null)
    const [dwellingTimeData, setDwellingTimeData] = useState(false)
    const [isUpdatingData, setIsUpdatingData] = useState(false)
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: analyticConfiguration?.isFetching,
            b: stream?.isFetching,
            c: boundaryZoneData === null,
            d: boundingBoxData === null,
        },
    })

    const addPolygonePoints = async () => {
        const endpoint =
            APIEndpoints.instance.stream.analytic.zone_intrusion_detector.main.zonePoints()
        let instanceID = analyticConfiguration?.data?.id
        if (analyticConfiguration?.data?.coordinates.length > 0) {
            await (
                await axiosInstance()
            ).delete(
                APIEndpoints.instance.stream.analytic.zone_intrusion_detector.main.zonePointsDelete(
                    instanceID
                )
            )
        }
        let body = []
        for (const item of boundaryZoneData) {
            body.push({
                x_coordinate: item.x_coordinate,
                y_coordinate: item.y_coordinate,
                zone: instanceID,
            })
        }
        ;(await axiosInstance()).post(endpoint, body)
    }

    const saveAnalyticConfiguration = async () => {
        try {
            setIsUpdatingData(true)
            fireSpinnerAlert({ title: 'Saving Configuration' })

            await addPolygonePoints()

            const endpoint =
                APIEndpoints.instance.stream.analytic.zone_intrusion_detector.main.update(
                    analyticConfiguration?.data?.id
                )
            let requestData = {}

            if (
                boundingBoxData?.start_x === boundingBoxData?.end_x &&
                boundingBoxData?.start_y === boundingBoxData?.end_y
            ) {
                requestData = {
                    bounding_box_start_x: 0,
                    bounding_box_start_y: 0,
                    bounding_box_end_x: 0,
                    bounding_box_end_y: 0,
                    threshold: boundingBoxData?.dwelling_factor,
                    bounding_box_precision_factor:
                        boundingBoxData?.precision_factor,
                    time_bound_start: timeBoundData?.start,
                    time_bound_end: timeBoundData?.end,
                }
            } else {
                requestData = {
                    bounding_box_start_x: boundingBoxData?.start_x,
                    bounding_box_start_y: boundingBoxData?.start_y,
                    bounding_box_end_x: boundingBoxData?.end_x,
                    bounding_box_end_y: boundingBoxData?.end_y,
                    threshold: boundingBoxData?.dwelling_factor,
                    bounding_box_precision_factor:
                        boundingBoxData?.precision_factor,
                    time_bound_start: timeBoundData?.start,
                    time_bound_end: timeBoundData?.end,
                }
            }

            ;(await axiosInstance()).patch(endpoint, requestData).then(() => {
                setIsUpdatingData(false)
                displayMessage('Success', 'Configuration updated successfully')
            })
        } catch (err) {
            setIsUpdatingData(false)
            displayError(err, theme)
        }
    }

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.instance.stream.main.get(streamID),
                item: stream,
                setItem: setStream,
            })
        }
    }, [profile])

    useEffect(() => {
        if (stream?.data) {
            APIUtils.fetchItemData({
                endpoint:
                    APIEndpoints.instance.stream.analytic.zone_intrusion_detector.main.getForStream(
                        stream?.data?.id
                    ),
                item: analyticConfiguration,
                setItem: setAnalyticConfiguration,
            })
        }
    }, [stream])

    useEffect(() => {
        if (analyticConfiguration?.data) {
            const data = analyticConfiguration.data
            setBoundaryZoneData(data?.coordinates)
            setBoundingBoxData({
                start_x: data.bounding_box_start_x,
                start_y: data.bounding_box_start_y,
                end_x: data.bounding_box_end_x,
                end_y: data.bounding_box_end_y,
                precision_factor: data.bounding_box_precision_factor,
            })
            setTimeBoundData({
                start: data.time_bound_start,
                end: data.time_bound_end,
            })
        }
    }, [analyticConfiguration])

    useEffect(() => {
        return async () => {
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }
    }, [])

    return (
        <MainWrapper
            parent='stream'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Settings'
        >
            <StyledComponent>
                <div id='body-camera'>
                    <div className='main-frame-camera'>
                        <div className='tab-content h-100 py-3 px-3'>
                            {canRender ? (
                                <div className='canvasDrawBoxOuter'>
                                    <ZoneIntrusionDetectorConfigContext.Provider
                                        value={{
                                            boundaryZoneData,
                                            setBoundaryZoneData,
                                            boundingBoxData,
                                            setBoundingBoxData,
                                        }}
                                    >
                                        <div className='canvasBox'>
                                            {boundaryZoneMode && (
                                                <ZoneIntrusionDetectorCanvasBoundaryZone
                                                    stream={stream?.data}
                                                />
                                            )}
                                            {boundingBoxMode && (
                                                <ZoneIntrusionDetectorCanvasPersonSize
                                                    stream={stream?.data}
                                                />
                                            )}
                                        </div>
                                    </ZoneIntrusionDetectorConfigContext.Provider>
                                    <div className='vertical-line'></div>
                                    <div className='people_counter_canvas_sidebar'>
                                        <div className='people_counter_canvas_width_only '>
                                            <div
                                                className={`people_counter_canvas_draw_Box Active`}
                                            >
                                                <button
                                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    } ${
                                                        boundaryZoneMode
                                                            ? ''
                                                            : 'show'
                                                    }`}
                                                    onClick={() => {
                                                        setBoundaryZoneMode(
                                                            true
                                                        )
                                                        setBoundingBoxMode(
                                                            false
                                                        )
                                                        setTimeBoundMode(false)
                                                    }}
                                                >
                                                    ZONE BOUNDARY
                                                </button>
                                                <button
                                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    }  ${
                                                        boundingBoxMode
                                                            ? 'show'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setBoundaryZoneMode(
                                                            false
                                                        )
                                                        setBoundingBoxMode(true)
                                                        setTimeBoundMode(false)

                                                        setDwellingTimeMode(
                                                            false
                                                        )
                                                    }}
                                                >
                                                    OBJECT SIZE
                                                </button>
                                                {boundingBoxMode &&
                                                !timeBoundMode &&
                                                !dwellingTimeMode ? (
                                                    <div
                                                        className={
                                                            'currentModeOptionWrapper boundingBoxPrecisionFactorWrapper'
                                                        }
                                                    >
                                                        <div
                                                            className={'label'}
                                                        >
                                                            Precision (0-100)
                                                        </div>
                                                        <input
                                                            value={
                                                                boundingBoxData?.precision_factor
                                                            }
                                                            type='number'
                                                            className={'input'}
                                                            onChange={e => {
                                                                if (
                                                                    e.target
                                                                        .value >=
                                                                        0 &&
                                                                    e.target
                                                                        .value <
                                                                        101
                                                                ) {
                                                                    HandleOnChangeInput(
                                                                        e,
                                                                        'precision_factor',
                                                                        setBoundingBoxData,
                                                                        boundingBoxData,
                                                                        'value'
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                <button
                                                    className={`btn btn-custom-orange waves-effect waves-light ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    } px-5 ${
                                                        dwellingTimeMode
                                                            ? 'show'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setDwellingTimeMode(
                                                            true
                                                        )
                                                    }}
                                                >
                                                    DWELLING TIME
                                                </button>
                                                {dwellingTimeMode ? (
                                                    <div
                                                        className={
                                                            'currentModeOptionWrapper boundingBoxPrecisionFactorWrapper'
                                                        }
                                                    >
                                                        <div
                                                            className={'label'}
                                                        >
                                                            In seconds
                                                        </div>
                                                        <input
                                                            value={
                                                                dwellingTimeData?.dwelling_factor
                                                            }
                                                            type='number'
                                                            className={'input'}
                                                            onChange={e => {
                                                                if (
                                                                    e.target
                                                                        .value >=
                                                                    0
                                                                ) {
                                                                    HandleOnChangeInput(
                                                                        e,
                                                                        'dwelling_factor',
                                                                        setDwellingTimeData,
                                                                        dwellingTimeData,
                                                                        'value'
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                <button
                                                    className={`btn btn-custom-orange waves-effect waves-light ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    } px-5 ${
                                                        timeBoundMode
                                                            ? 'show'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setTimeBoundMode(true)
                                                    }}
                                                >
                                                    TIME BOUND
                                                </button>
                                                {timeBoundMode ? (
                                                    <div
                                                        className={
                                                            'currentModeOptionWrapper timeBoundWrapper'
                                                        }
                                                    >
                                                        <div className='inputWrapper'>
                                                            <div
                                                                className={
                                                                    'label'
                                                                }
                                                            >
                                                                Starting Time
                                                            </div>
                                                            <input
                                                                type='time'
                                                                className={
                                                                    'input'
                                                                }
                                                                value={
                                                                    timeBoundData?.start
                                                                }
                                                                onChange={e =>
                                                                    HandleOnChangeInput(
                                                                        e,
                                                                        'start',
                                                                        setTimeBoundData,
                                                                        timeBoundData,
                                                                        'value',
                                                                        data => {
                                                                            return (
                                                                                data ||
                                                                                null
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className='inputWrapper'>
                                                            <div
                                                                className={
                                                                    'label'
                                                                }
                                                            >
                                                                Ending Time
                                                            </div>
                                                            <input
                                                                type='time'
                                                                className={
                                                                    'input'
                                                                }
                                                                value={
                                                                    timeBoundData?.end
                                                                }
                                                                onChange={e =>
                                                                    HandleOnChangeInput(
                                                                        e,
                                                                        'end',
                                                                        setTimeBoundData,
                                                                        timeBoundData,
                                                                        'value',
                                                                        data => {
                                                                            return (
                                                                                data ||
                                                                                null
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='mt-3 people_counter_canvas_width_only'>
                                            <div className='saveButton'>
                                                <button
                                                    onClick={() => {
                                                        saveAnalyticConfiguration()
                                                    }}
                                                    type='submit'
                                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    } ${
                                                        isUpdatingData
                                                            ? 'disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <AuthPagePreloaderComponent />
                            )}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </StyledComponent>
        </MainWrapper>
    )
}

export default ZoneIntrusionDetectorConfigView
