import axiosInstance from "../../../config/axios";
import displayError from "../../displayError";

export class APIUtils {
  static formatQueryDictToQueryParams(data, prefix = "") {
    const params = [];
    for (let item of Object.keys(data)) {
      params.push(`${item}=${data[item]}`);
    }
    return params.length ? `${prefix}${params.join("&")}` : "";
  }

  static formatQueryFields(fields, type) {
    const queryParamMap = {
      subUser: "sub_user_ids",
      projects: "projects",
      streams: "streams",
      type: "type",
    };
    const queryParam = queryParamMap[type];
    if (!queryParam) {
      return "";
    }

    return `${queryParam}=${fields.join(",")}`;
  }

  static dataStateMutator(oldState, newData, setterFunc) {
    const newState = {
      ...(oldState || {}),
      ...(newData || {}),
    };
    setterFunc(newState);
    return newState;
  }

  static async fetchListingAllPagination(
    endpoint,
    countLimit,
    fetchWithSecretKey = false
  ) {
    let tempData = [];
    let errorEncountered = false;
    let breakLoop = false;
    while (true) {
      await (
        await axiosInstance(null, fetchWithSecretKey)
      )
        .get(endpoint)
        .then((response) => {
          tempData = [...tempData, ...response.data];
          if (countLimit && tempData.length >= countLimit) {
            tempData = tempData.slice(0, countLimit);
            breakLoop = true;
          }
          if (response?.extraData?.next) {
            endpoint = response?.extraData?.next;
          } else {
            breakLoop = true;
          }
        })
        .catch((err) => {
          displayError(err);
          errorEncountered = true;
          breakLoop = true;
        });
      if (breakLoop) {
        break;
      }
    }
    if (!errorEncountered) {
      return tempData;
    }
  }

  static async fetchItemData({
    endpoint,
    item,
    setItem,
    isFetchingValueOnTrue,
    callBackOnErr,
    customAccessToken,
    displayErrorMessage = true,
    customErrorMessage,
    fetchAsAppendMode = false,
    fetchAllListItem = true,
    fetchAllListItemLimit = 100,
    fetchWithSecretKey = false,
  }) {
    let oldState = {
      ...item,
    };
    oldState = APIUtils.dataStateMutator(
      oldState,
      { isFetching: isFetchingValueOnTrue || true },
      setItem
    );
    await (
      await axiosInstance(customAccessToken, fetchWithSecretKey)
    )
      .get(endpoint)
      .then(async (response) => {
        if (response?.extraData?.next && fetchAllListItem) {
          oldState = APIUtils.dataStateMutator(
            oldState,
            {
              data: await APIUtils.fetchListingAllPagination(
                endpoint,
                fetchAllListItemLimit,
                fetchWithSecretKey
              ),
            },
            setItem
          );
        } else {
          if (fetchAsAppendMode) {
            oldState = APIUtils.dataStateMutator(
              oldState,
              {
                data: [...(oldState.data || []), ...response.data],
              },
              setItem
            );
          } else {
            oldState = APIUtils.dataStateMutator(
              oldState,
              { data: response.data },
              setItem
            );
          }
        }
      })
      .catch((err) => {
        displayErrorMessage && displayError(customErrorMessage || err);
        callBackOnErr && callBackOnErr();
      });
    APIUtils.dataStateMutator(oldState, { isFetching: false }, setItem);
  }

  static async editItemData({
    endpoint,
    item,
    setItem,
    payload,
    callBackOnErr,
    customAccessToken,
  }) {
    const tempItem = APIUtils.dataStateMutator(
      { ...item },
      {
        data: { ...item.data, ...payload },
      },
      setItem
    );
    await (await axiosInstance(customAccessToken))
      .patch(endpoint, payload)
      .catch((err) => {
        displayError(err);
        callBackOnErr && callBackOnErr();
      });
    await APIUtils.fetchItemData({
      endpoint: endpoint,
      item: tempItem,
      setItem,
    });
  }
}
