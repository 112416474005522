import { useSelector } from 'react-redux'
import axiosInstance from '../config/axios'
import displayError from './displayError'

export const fetchAllPaginatedListing = async (
    endpoint,
    error_msg,
    countLimit
) => {
    let tempData = []
    let errorEncountered = false
    let breakLoop = false
    while (true) {
        await (
            await axiosInstance()
        )
            .get(endpoint)
            .then(response => {
                tempData = [...tempData, ...response.data]
                if (countLimit && tempData.length >= countLimit) {
                    tempData = tempData.slice(0, countLimit)
                    breakLoop = true
                }
                if (response?.extraData?.next) {
                    endpoint = response?.extraData?.next
                } else {
                    breakLoop = true
                }
            })
            .catch(err => {
                if (error_msg) {
                    displayError(error_msg)
                }
                errorEncountered = true
                breakLoop = true
            })
        if (breakLoop) {
            break
        }
    }
    if (!errorEncountered) {
        return tempData
    }
}
