import { APIEndpointsHelper } from "../../../../../../index";

export class APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotification {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(``, params);
  }

  static get(id) {
    return ``;
  }

  static update(id) {
    return ``;
  }

  static destroy(id) {
    return ``;
  }

  static batchDestroy(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(``, params);
  }
}

export class APIEndpointInstanceStreamAnalyticsMotionDetectorEventNotificationAsGuest {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(``, params);
  }

  static get(id) {
    return ``;
  }
}
