import {APIEndpointsHelper} from "../../index";

export class APIEndpointInstanceVPSChecklistItem {

    static fetch(id) {
        return `/instance/vps/checklist_item/${id}/`
    }

    static add() {
        return `/instance/vps/add_checklist/`
    }

    static update(id) {
        return `/instance/vps/checklist_item/${id}/`
    }

    static delete(id) {
        return `/instance/vps/checklist_item/${id}/`
    }

    static fetchAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/checklist_item/`, params)
    }
}


export class APIEndpointInstanceVPSChecklistItemNegativeActionPredefinedMessage {

    static fetch(id) {
        return `/instance/vps/checklist_item/action/negative/predefined_message/${id}/`
    }

    static add() {
        return `/instance/vps/checklist_item/action/negative/predefined_message/`
    }


    static delete(id) {
        return `/instance/vps/checklist_item/action/negative/predefined_message/${id}/`
    }

    static fetchAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(`/instance/vps/checklist_item/action/negative/predefined_message/`, params)
    }
}