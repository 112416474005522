import { UpdateUser } from '../../../user/update/UpdateUser'
import { UpdateSubAdminExtras } from './Extras'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../../App'
import { MainWrapper } from '../../../MainWrapper'
import { useSelector } from 'react-redux'

export const UpdateSubAdminMain = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <MainWrapper
            parent='sub_admin'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Update Sub-Administrator'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    <div
                        className={`tab-content h-100 py-3 px-3 ${
                            theme === 'dark' && 'tab-content-dark'
                        }`}
                    >
                        <UpdateUser extras={<UpdateSubAdminExtras />} />
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}
