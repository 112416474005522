import '../../../styles/camera.css'
import SideBarLeft from '../../SidebarLeft'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../App'
import { NavigationComponent } from '../../global/containers/navigation/NavigationComponent'
import { AddUserForm } from '../../user/add/AddUserForm'
import { AdminRoutes } from '../../../routes/AdminRoutes'
import { MainWrapper } from '../../MainWrapper'

const AddSubAdmin = props => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    return (
        <MainWrapper
            parent='sub_admin'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Add Sub-Administrator'
        >
            <div id='body-camera'>
                <div className='main-frame-camera position-relative'>
                    {/* <SideBarLeft
                        parent='sub_admin'
                        profile={profile}
                        isFetching={isFetching}
                    /> */}
                    <div className='tab-content h-100 py-3 px-3'>
                        {/* <div className='mt-2 tab-content-header d-flex justify-content-between'>
                            <NavigationComponent>
                                <div className='d-flex crumbs'>
                                    <span className='crumb'>
                                        <Link
                                            to={
                                                AdminRoutes.pathDefinitions
                                                    .viewSubAdmins
                                            }
                                            className='orange-text'
                                        >
                                            Manage Sub-Administrators
                                        </Link>
                                    </span>
                                    <span className='crumb'>
                                        Add Sub-Administrator
                                    </span>
                                </div>
                            </NavigationComponent>
                        </div> */}
                        <AddUserForm
                            redirectOnSuccess={
                                AdminRoutes.pathDefinitions.viewSubAdmins
                            }
                        />
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default AddSubAdmin
