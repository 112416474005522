import { useContext, useEffect, useState } from 'react'
import { GlobalLiveStreamContext } from './LiveStream'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const StyledWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    /* position: relative; */
    z-index: 1;

    .dimensionWrapper {
        border: ${props =>
            props.theme === 'dark'
                ? '1px solid rgba(94, 132, 195, 1)'
                : '1px solid rgb(37, 68, 120)'};
        color: ${props =>
            props.theme === 'dark' ? 'white' : 'rgb(37, 68, 120)'};
        padding: 1px 1rem;
        font-size: 0.8rem;
        background-color: transparent;
        transition: 0.5s all ease-in-out;
        cursor: pointer;
        border-radius: 30px;
    }

    .dimensionWrapper:hover,
    .dimensionWrapper.active {
        color: white;
        background-color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgb(37, 68, 120)'};
    }
`

export const FrameDimensionContainer = () => {
    const {
        selectedFrameDimension,
        setSelectedFrameDimension,
        streams,
        setPaginationLevel,
    } = useContext(GlobalLiveStreamContext)
    const [frameDimensions, setFrameDimensions] = useState([])
    const { theme } = useSelector(state => state.themeReducer)

    useEffect(() => {
        let noOfCamera = streams?.data?.length
        let count = 1
        let tempFrameDimensions = []
        while (true) {
            tempFrameDimensions.push([count, count])
            if (count ** 2 >= noOfCamera) {
                break
            }
            if (count >= 4) {
                break
            }
            count++
        }
        setFrameDimensions(tempFrameDimensions)
        setSelectedFrameDimension(tempFrameDimensions[0])
    }, [streams])

    return (
        <StyledWrapper theme={theme}>
            {frameDimensions.map(frameDimension => {
                return (
                    <div
                        className={`dimensionWrapper ${
                            selectedFrameDimension[0] *
                                selectedFrameDimension[1] ===
                                frameDimension[0] * frameDimension[1] &&
                            'active'
                        }`}
                        key={frameDimension}
                        onClick={() => {
                            setSelectedFrameDimension(frameDimension)
                            setPaginationLevel(1)
                        }}
                    >
                        {frameDimension.join('x')}
                    </div>
                )
            })}
        </StyledWrapper>
    )
}
