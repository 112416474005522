import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../App";
import { MainWrapper } from "../../../MainWrapper";
import { FilterContainer } from "../../../global/filters/Container";
import { useSelector } from "react-redux";
import { InputDates } from "./InputDates";
import { APIEndpointInstanceStreamAnalyticsHeaMapsInstance } from "../../../../config/api/endpoints/instance/stream/analytics/heat_map_detector/instance";
import axiosInstance from "../../../../config/axios";
import { notifySuccess } from "../../../../helpers/notifications/notifySuccess";
import displayError from "../../../../helpers/displayError";
import { WSUtilsHelperConnectionManager } from "../../../../helpers/utils/api/ws";
import { notifyToast } from "../../../../helpers/notifications";
import moment from "moment";
import { notifyFailure } from "../../../../helpers/notifications/notifyFailure";
import Spinner from "../../../Spinner";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;

  .heatmap-container {
    // height: 100%;
    width: 100%;
  }

  .fieldsWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 4;
  }

  .filters-content {
    justify-content: flex-start !important;
    gap: 1rem !important;
    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
    }
  }

  .stream-wrapper {
    width: 100%;
    height: fit-content;
  }

  @media (max-width: 1431px) {
    .filters-content {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  span {
    font-size: 0.9rem !important;
    color: ${(props) =>
      props.theme === "dark" ? "white!important" : "black!important"};
  }

  .react-select-single__option {
    span {
      font-size: 0.9rem !important;
      text-overflow: "ellipsis";
    }
  }
  .react-select-single-container {
    span {
      display: none;
    }
    .react-select-single__loading-indicator {
      margin-right: 0px !important;
      padding: 0px !important;

      span {
        display: block !important;
      }
    }
  }

  img {
    border-radius: 27px;
    width: 100%;
    aspect-ratio: 16/8;
    object-fit: contain;
  }

  .add-camera_btn {
    padding: 0.6 1.5rem;
  }
`;

export const GlobalHeatmapsContext = createContext({});

export const GlobalHeatmapsContainer = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );

  const [generatedFrame, setGeneratedFrame] = useState(null);

  const [isGenerating, setIsGenerating] = useState(false);

  const [socket, setSocket] = useState(null);

  const [filterData, setFilterData] = useState({});
  const { theme } = useSelector((state) => state.themeReducer);

  const socketCount = useRef(null);

  useEffect(() => {
    if (socket) {
      socket.connInstance.onmessage = function (e) {
        try {
          socketCount.current = socketCount.current + 1;
          if (socketCount.current > 1) {
            const { extra } = JSON.parse(e.data);

            // If extra is undefined or doesn't have a frame, don't proceed
            if (!extra || extra.frame === undefined) {
              return;
            }

            const { frame } = extra;

            if (frame === null) {
              notifyFailure("No heatmap data found in this time-frame.");
              setIsGenerating(false);
              setGeneratedFrame(null);
            }
            // Check if it's a valid URL
            else if (isValidUrl(frame)) {
              setGeneratedFrame(frame);
              setIsGenerating(false);
            } else {
              notifyFailure("Something went wrong");
              setIsGenerating(false);
              setGeneratedFrame(null);
            }
          }
        } catch (err) {
          console.error("Error parsing WebSocket message: ", err);
          notifyFailure("Error receiving data");
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      const wsConnManagerInstance = new WSUtilsHelperConnectionManager(
        "/heatmap/event",
        setSocket
      );
      wsConnManagerInstance.initiate();
      return () => {
        wsConnManagerInstance.close();
      };
    }
  }, []);

  const dateFormatter = (fromDate, toDate) => {
    // Parse the dates and round down to the nearest hour
    const startTime = moment(fromDate, "YYYY-MM-DD HH:mm").startOf("hour");
    const endTime = moment(toDate, "YYYY-MM-DD HH:mm").startOf("hour");

    // Extract the minutes for both times
    const startMinutes = startTime.minutes();
    const endMinutes = endTime.minutes();

    // Check if both times are on the hour (minutes should be 0)
    if (startMinutes !== 0 || endMinutes !== 0) {
      notifyFailure("Both times must be on the hour (e.g., 1:00, 2:00, etc.).");
      return false;
    }

    // Check if the start time is before the end time
    if (startTime.isAfter(endTime)) {
      notifyFailure("The start time must be before the end time.");
      return false;
    }

    // Return formatted start and end times in HH:00 format
    const formattedFrom = startTime.format("YYYY-MM-DD HH:00");
    const formattedTo = endTime.format("YYYY-MM-DD HH:00");

    return {
      from: formattedFrom,
      to: formattedTo,
    };
  };

  // Helper function to validate the URL
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  // const generateHeatMap = useCallback(async () => {

  // }, []);

  const generateHeatMap = async (data) => {
    setIsGenerating(true);
    if (!data?.Camera || !data?.Camera?.label) {
      setIsGenerating(false);
      return;
    }
    // Format dates and check time difference
    const formattedDates = dateFormatter(
      filterData?.from_date,
      filterData?.to_date
    );
    if (!formattedDates) {
      setIsGenerating(false);
      return;
    }

    const requestData = {
      start_date: formattedDates.from,
      end_date: formattedDates.to,
      stream: data?.Camera.label,
    };

    const endpoint =
      APIEndpointInstanceStreamAnalyticsHeaMapsInstance.generate();

    await (
      await axiosInstance()
    )
      .post(endpoint, requestData)
      .then((response) => {
        notifySuccess("generating...");
      })
      .catch((err) => {
        setIsGenerating(false);
        displayError(err, theme);
        setGeneratedFrame(null);
      });
  };

  const handleFilterData = (data) => {
    generateHeatMap(data);
    setFilterData((prev) => ({
      ...prev,
      SubUser: data.SubUser,
      Site: data.Site,
      Camera: data.Camera,
    }));
  };

  return (
    <GlobalHeatmapsContext.Provider
      value={{
        setFilterData,
        filterData,
      }}
    >
      <MainWrapper
        parent=''
        isFetching={isFetching}
        profile={profile}
        pageTitle='Heatmaps'
      >
        <StyledWrapper theme={theme}>
          <div id='body-camera'>
            <div className='main-frame-camera'>
              <div className='heatmap-container'>
                <div
                  className={`mt-2 tab-content-header ${
                    theme === "dark" &&
                    "tab-content-header-dark-text tab-content-header-dark-bg"
                  } d-flex justify-content-between`}
                >
                  <div className='filters-content'>
                    <InputDates />
                    <FilterContainer
                      componentName={"FilterByUserSiteCameraSingle"}
                      setterCallback={handleFilterData}
                      liveSearch={false}
                      backup={true}
                      isMulti={false}
                      btnText='Generate'
                    />
                  </div>
                </div>

                {isGenerating ? (
                  <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                    <Spinner />
                  </div>
                ) : (
                  generatedFrame && (
                    <div
                      className='mt-4 w-100'
                      style={{
                        background:
                          theme === "dark"
                            ? "rgba(38, 56, 84, 1)"
                            : "rgba(30, 30, 30, 0.7)",
                        borderRadius: "27px",
                      }}
                    >
                      <img
                        src={generatedFrame}
                        alt='Heatmap'
                        onError={(e) => {
                          e.target.onerror = null; // prevents looping
                          e.target.src = ""; // fallback image or placeholder
                          notifyToast("Failed to load the heatmap.");
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </StyledWrapper>
      </MainWrapper>
    </GlobalHeatmapsContext.Provider>
  );
};
