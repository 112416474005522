import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../../Spinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { VPSContext } from '../../../MainWrapper'
import APIEndpoints from '../../../../../../../config/api/endpoints'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
        margin-top: 0;
        padding-bottom: 0.5rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 500;
        color: ${props =>
            props.theme === 'dark'
                ? 'rgba(94, 132, 195, 1)'
                : 'rgba(37, 68, 120, 1)'};
    }

    input {
        color: ${props => (props.theme === 'dark' ? 'white' : '#8a8888')};
        text-indent: 0.5rem !important;
        height: 30px !important;
        font-weight: 400;
        font-size: 12px !important;
        border-bottom: ${props =>
            props.theme === 'dark'
                ? '1.5px solid rgba(94, 132, 195, 1) !important'
                : '1.5px solid rgb(37, 68, 120) !important'};
    }
    .add-camera_btn {
        padding: 0.4rem 1.2rem;
    }
`
export const ConfigureVPSLocation = ({ theme }) => {
    const [isUpdatingVPSLocation, setIsUpdatingVPSLocation] = useState(false)
    const [data, setData] = useState({})

    const { VPSInstance, fetchVPSInstance } = useContext(VPSContext)

    useEffect(() => {
        setData({
            location: VPSInstance?.data?.location,
        })
    }, [VPSInstance])

    const handleOnSubmit = async e => {
        e.preventDefault()
        setIsUpdatingVPSLocation(true)
        await (
            await axiosInstance()
        )
            .patch(
                APIEndpoints.instance.vps.main.update(VPSInstance?.data?.id),
                data
            )
            .then(response => {
                fetchVPSInstance()
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsUpdatingVPSLocation(false)
    }

    return (
        <Wrapper theme={theme}>
            <h5>Add Location</h5>
            <h6
                className={`input-tag-upload_btn ${
                    theme === 'dark' && 'input-tag-upload_btn-dark'
                }`}
            >
                Location Here
            </h6>
            <input
                type='text'
                placeholder=''
                value={data?.location}
                onChange={e =>
                    HandleOnChangeInput(e, 'location', setData, data)
                }
            />
            <SizedBox height={1} />
            {VPSInstance?.isFetching === true || isUpdatingVPSLocation ? (
                <Spinner size={1.5} />
            ) : (
                VPSInstance?.isFetching === false && (
                    <Link
                        className={`orange-text add-camera_btn ${
                            theme === 'dark' && 'add-camera_btn-dark'
                        }`}
                        to=''
                        onClick={handleOnSubmit}
                    >
                        Save
                    </Link>
                )
            )}
        </Wrapper>
    )
}
