import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import axiosInstance from '../../../../../../../config/axios'
import { notifyFailure } from '../../../../../../../helpers/notifications/notifyFailure'
import Spinner from '../../../../../../Spinner'
import { notifySuccess } from '../../../../../../../helpers/notifications/notifySuccess'
import displayError from '../../../../../../../helpers/displayError'
import { LivestreamNotificationContext } from '../../Container'
import { FormInputUtils } from '../../../../../../../helpers/utils/formInput'
import APIEndpoints from '../../../../../../../config/api/endpoints'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    width: 90vw;
    max-width: 400px;

    .fieldsWrapper {
        display: flex;
        flex-direction: column;
        input {
            border: 2px solid #cacaca !important;
            color: #000;
            text-indent: 0.5rem !important;
            height: 30px !important;
        }

        .submit-btn-wrapper {
            align-self: flex-end;

            .add-camera_btn {
                padding: 0.4rem 1.2rem;
            }
        }

        select {
            display: initial !important;
            width: rem;
        }

        textarea {
            height: 10rem;
            border: 0.5px solid !important;
            padding: 0.5rem;
            border-radius: 5px;

            &:focus {
                border: 0.5px solid !important;
            }
        }
    }
`
export const NotificationRemarkForm = ({ notificationInstance }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const [data, setData] = useState(notificationInstance?.remark)
    const [remarkTypes, setRemarkTypes] = useState(null)
    const customRemark = useRef('Custom')
    const [isSubmittingData, setIsSubmittingData] = useState(false)
    const { modalAddRemarkToNotificationState } = useContext(
        LivestreamNotificationContext
    )
    const { callBackUpdateFeatureData } = modalAddRemarkToNotificationState

    const callBackFetchRemarkTypes = useCallback(async () => {
        const endpoint = APIEndpoints.utils.types.get(
            'stream_analytic_instance_line_intrusion_detector_event_notification_remark_types'
        )
        setRemarkTypes(null)
        await (
            await axiosInstance()
        )
            .get(endpoint)
            .then(response => {
                setRemarkTypes(response?.data)
            })
            .catch(err => {
                notifyFailure('Error in loading remark types')
                setRemarkTypes([])
            })
    }, [remarkTypes])

    const callBackSaveRemark = useCallback(async () => {
        if (!data?.type) {
            notifyFailure('Kindly select the type of the alert')
            return
        } else if (data?.type === customRemark.current) {
            if (!data?.type) {
                notifyFailure('Kindly type a message for the custom remark')
                return
            }
        }
        const endpoint =
            APIEndpoints.instance.stream.analytic.line_intrusion_detector.event.notification.main.update(
                notificationInstance?.id
            )
        const requestData = {
            remark: {
                type: data.type,
                message: data.message,
            },
        }
        setIsSubmittingData(true)
        await (
            await axiosInstance()
        )
            .patch(endpoint, requestData)
            .then(response => {
                notifySuccess('Success')
                callBackUpdateFeatureData()
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsSubmittingData(false)
    }, [data])

    useEffect(() => {
        callBackFetchRemarkTypes()
    }, [])

    return (
        <Wrapper>
            <div className='fieldsWrapper'>
                <label htmlFor='remark-type'>Type of Remark</label>
                <div className='input-wrapper'>
                    {remarkTypes === null ? (
                        <Spinner size={0.8} />
                    ) : (
                        remarkTypes && (
                            <select
                                value={data?.type}
                                onChange={e =>
                                    HandleOnChangeInput(
                                        e,
                                        'type',
                                        setData,
                                        data
                                    )
                                }
                            >
                                {FormInputUtils.parseArrayValuesToSelectFields(
                                    remarkTypes
                                ).map(item => (
                                    <option
                                        value={item}
                                        selected={item === data?.type}
                                    >
                                        {item}
                                    </option>
                                ))}
                            </select>
                        )
                    )}
                </div>
                <SizedBox height={1} />
                {data?.type === customRemark.current && (
                    <>
                        <label htmlFor='remark-type'>Message</label>
                        <textarea
                            placeholder='Enter a custom message'
                            id='remark-type'
                            value={data?.message}
                            onChange={e =>
                                HandleOnChangeInput(e, 'message', setData, data)
                            }
                        ></textarea>
                    </>
                )}
                <SizedBox height={1} />
                <div className='submit-btn-wrapper'>
                    {isSubmittingData ? (
                        <Spinner size={1.5} />
                    ) : (
                        <Link
                            className={
                                'orange-text add-camera_btn ' +
                                (data?.type ? '' : 'disabled-button')
                            }
                            to=''
                            onClick={callBackSaveRemark}
                        >
                            SAVE
                        </Link>
                    )}
                </div>
            </div>
        </Wrapper>
    )
}
