import styled from "styled-components";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../components/Spinner";
import { GlobalUserProfileContext } from "../../App";
import { BlankAuthCenterCanvas } from "../../components/global/graphical/BlankAuthCenterCanvas";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../../config/actions";
import { FETCH_PROFILE_START } from "../../config/constants";
import { useSelector } from "react-redux";

const StyledContainer = styled.div`
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;

  ${(props) =>
    props.theme === "dark"
      ? `background: linear-gradient(to bottom right, #202d42, #162131, #142d31)`
      : "rgb(243, 243, 243)"};
`;

export const ProtectedRouteComponent = ({
  conditions,
  elementToRenderOnPass,
  ComponentToRenderOnPass,
  profileRequired = true,
}) => {
  const [status, setStatus] = useState(null);
  const redirectPath = useRef("");
  const location = useLocation();
  const params = useParams();
  const userProfileContextData = useContext(GlobalUserProfileContext);
  const { isFetchingProfile: isFetching, profile } = userProfileContextData;
  const dispatch = useDispatch();
  const [rendered, setRendered] = useState(false);
  const cachedLocation = useRef(null);
  const { theme } = useSelector((state) => state.themeReducer);

  useEffect(() => {
    const evaluateCondition = async () => {
      let tempStatus = true;
      for (let key of Object.keys(conditions)) {
        const condition = conditions[key];
        if (typeof condition === "function") {
          if (!(await condition())) {
            redirectPath.current = key;
            tempStatus = false;
            break;
          }
        } else if (typeof condition === "boolean") {
          if (!condition) {
            redirectPath.current = key;
            tempStatus = false;
            break;
          }
        }
      }
      setStatus(tempStatus);
    };
    setStatus(null);
    setRendered(false);
    cachedLocation.current = location;
    if (profileRequired) {
      dispatch({ type: FETCH_PROFILE_START, payload: null });
    }
    evaluateCondition();
  }, [location]);

  useEffect(() => {
    if (status === true) {
      if (profileRequired) {
        dispatch(fetchProfile);
      }
    }
  }, [status]);

  useEffect(() => {
    if (status === true && rendered === false) {
      if (profileRequired) {
        if (isFetching === false) {
          setRendered(true);
        }
      } else {
        setRendered(true);
      }
    }
  }, [status, isFetching, rendered]);

  if (cachedLocation.current && cachedLocation.current !== location) {
    return (
      <StyledContainer>
        <Spinner size={2.0} />
      </StyledContainer>
    );
  } else if (rendered === true) {
    return ComponentToRenderOnPass ? (
      <ComponentToRenderOnPass {...params} />
    ) : (
      elementToRenderOnPass
    );
  } else {
    if (typeof status === "boolean") {
      if (status === true) {
        let canRender = false;
        if (profileRequired) {
          if (!isFetching) {
            if (!profile) {
              return (
                <BlankAuthCenterCanvas pageTitle={"Unable to access page"}>
                  You do not currently have access to this page
                </BlankAuthCenterCanvas>
              );
            } else {
              canRender = true;
            }
          }
        } else {
          canRender = true;
        }
        if (canRender) {
          return ComponentToRenderOnPass ? (
            <ComponentToRenderOnPass {...params} />
          ) : (
            elementToRenderOnPass
          );
        }
      } else {
        return <Navigate to={redirectPath.current} replace={false} />;
      }
    }
    return (
      <StyledContainer theme={theme}>
        <Spinner size={2.0} />
      </StyledContainer>
    );
  }
};
