import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
  svg {
    path {
      stroke: ${(props) =>
        props.theme === "dark" ? "rgba(94, 132, 195, 1)" : "#254478"};
    }
  }
`;

const EditIcon = ({ theme }) => {
  return (
    <StyledComponent theme={theme}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        fill='none'
        viewBox='0 0 21 21'
      >
        <path
          stroke='#254478'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='m17.01 6.99-3-3M2.5 18.5c.83.83 2.17.83 3 0l13-13c.83-.83.83-2.17 0-3s-2.17-.83-3 0l-13 13c-.83.83-.83 2.17 0 3'
        ></path>
      </svg>
    </StyledComponent>
  );
};

export default EditIcon;
