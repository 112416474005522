import styled from 'styled-components'
import { useContext } from 'react'
import { GlobalLiveStreamContext } from './LiveStream'
import { useSelector } from 'react-redux'

const MainStyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const StyledWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    .iconWrapper {
        border: none;
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        i {
            font-size: 2rem;
            color: ${props =>
                props.theme === 'dark' ? 'rgba(94, 132, 195, 1)' : 'black'};
            :hover {
                font-size: 2.2rem;
            }
        }
    }

    .slideShowTimerInput {
        width: 3rem !important;
    }

    input {
        border-bottom: ${props =>
            props.theme === 'dark'
                ? '1px solid rgba(94, 132, 195, 1) !important'
                : '1px solid #9e9e9e !important'};
        color: ${props =>
            props.theme === 'dark' ? 'white !important' : 'black !important'};
    }
`

export const GeneralLiveStreamPaginationContainer = () => {
    const {
        streams,
        selectedFrameDimension,
        paginationLevel,
        setPaginationLevel,
        isPlayingSlideShow,
        setIsPlayingSlideShow,
        setHasNextSlideShow,
        setHasPrevSlideShow,
        slideShowIntervalSecs,
        setSlideShowIntervalSecs,
    } = useContext(GlobalLiveStreamContext)

    const { theme } = useSelector(state => state.themeReducer)

    const hasPrevious = () => {
        const hasPrev =
            paginationLevel > 1 &&
            streams?.data?.length > selectedFrameDimension[0] ** 2
        setHasPrevSlideShow(hasPrev)
        return hasPrev
    }

    const hasNext = () => {
        const hasNext =
            paginationLevel * selectedFrameDimension[0] ** 2 <
            streams?.data?.length
        setHasNextSlideShow(hasNext)
        return hasNext
    }

    const hasPlay = () => {
        return (
            selectedFrameDimension[0] ** 2 < streams?.data?.length &&
            !isPlayingSlideShow
        )
    }

    const hasPause = () => {
        return (
            selectedFrameDimension[0] ** 2 < streams?.data?.length &&
            isPlayingSlideShow
        )
    }

    return (
        <MainStyledWrapper>
            <StyledWrapper theme={theme}>
                {hasPrevious() && (
                    <div
                        className='iconWrapper'
                        onClick={() => {
                            setPaginationLevel(x => x - 1)
                        }}
                    >
                        <i className='fas fa-arrow-alt-circle-left'></i>
                    </div>
                )}
                {(hasPause() || hasNext()) && (
                    <input
                        type='number'
                        className='form-control slideShowTimerInput'
                        value={slideShowIntervalSecs}
                        onChange={e => {
                            if (e.target.value < 1) {
                                e.preventDefault()
                            }
                            setSlideShowIntervalSecs(e.target.value)
                        }}
                    />
                )}

                {hasPause() && (
                    <div
                        className='iconWrapper'
                        onClick={() => {
                            setIsPlayingSlideShow(false)
                        }}
                    >
                        <i className='fas fa-pause-circle'></i>
                    </div>
                )}
                {hasPlay() && (
                    <div
                        className='iconWrapper'
                        onClick={() => {
                            setIsPlayingSlideShow(true)
                        }}
                    >
                        <i className='fas fa-play-circle'></i>
                    </div>
                )}
                {hasNext() && (
                    <div
                        className='iconWrapper'
                        onClick={() => {
                            setPaginationLevel(x => x + 1)
                        }}
                    >
                        <i className='fas fa-arrow-alt-circle-right'></i>
                    </div>
                )}
            </StyledWrapper>
        </MainStyledWrapper>
    )
}
