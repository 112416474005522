import React, { createContext, useState } from "react";
import styled from "styled-components";
import { NotifyContent } from "./NotifyContent";
import { AddNotificationRemarkContainer } from "./components/add_remark/Container";
import { ModalComponent } from "../../../../../helpers/utils/Modal";

const StyledComponent = styled.div`
  border-radius: 1.4rem;
  padding: 0.5rem;
`;

export const LivestreamNotificationContext = createContext({});

export const LiveStreamNotificationContainer = ({
  onScroll,
  notificationList,
  canRender,
  handleOpenModal,
}) => {
  const [
    modalAddRemarkToNotificationState,
    setModalAddRemarkToNotificationState,
  ] = useState({
    isOpen: false,
    notificationInstance: null,
  });

  return (
    <LivestreamNotificationContext.Provider
      value={{
        modalAddRemarkToNotificationState,
        setModalAddRemarkToNotificationState,
      }}
    >
      <StyledComponent>
        <NotifyContent
          onScroll={onScroll}
          canRender={canRender}
          notificationList={notificationList}
          handleOpenModal={handleOpenModal}
        />
      </StyledComponent>
      <ModalComponent
        modalLabel={"Add Notification Remark"}
        isOpen={!!modalAddRemarkToNotificationState?.isOpen}
        callbackCloseModal={() => {
          setModalAddRemarkToNotificationState({
            ...modalAddRemarkToNotificationState,
            isOpen: false,
          });
        }}
      >
        <AddNotificationRemarkContainer />
      </ModalComponent>
    </LivestreamNotificationContext.Provider>
  );
};
