import React from 'react'

function LogoutIcon(mobile = true) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='23'
            fill='transparent'
            viewBox='0 0 22 23'
        >
            <path
                stroke='#05A431'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                d='M7.9 6.56c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M14 11H2.62m2.23-3.35L1.5 11l3.35 3.35'
            ></path>
        </svg>
    )
}

export default LogoutIcon
