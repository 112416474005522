import '../../../../../styles/camera.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { GlobalUserProfileContext } from '../../../../../App'
import { useParams } from 'react-router-dom'
import { APIUtils } from '../../../../../helpers/utils/api'
import APIEndpoints from '../../../../../config/api/endpoints'
import { useLoadDependenciesBeforeRender } from '../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { StreamInstanceHooks } from '../../../../../helpers/hooks/instance/stream'
import { AuthPagePreloaderComponent } from '../../../../global/graphical/AuthPagePreloaderComponent'
import { StreamMediaLivePanel } from './Panel'
import { MainWrapper } from '../../../../MainWrapper'
import axiosInstance from '../../../../../config/axios'
import displayError from '../../../../../helpers/displayError'
import { useSelector } from 'react-redux'

export const StreamMediaLive = () => {
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const { theme } = useSelector(state => state.themeReducer)
    const [isFetchingUrl, setIsFetchingUrl] = useState(true)
    const [data, setData] = useState({})
    const [liveHLS, setLiveHLS] = useState(null)
    const { id: streamID } = useParams()
    const isCheckingStreamAvailability =
        StreamInstanceHooks.useValidateStreamStreamingConfigWithCurrentEnvironmentBeforeRender(
            { stream: data?.data }
        )
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: data?.isFetching,
            b: isCheckingStreamAvailability,
        },
    })

    const getLiveHlsUrl = useCallback(async () => {
        setIsFetchingUrl(true)
        const endpoint = APIEndpoints.instance.stream.main.getHlsUrl(streamID)
        const maxRetries = 3
        let attempts = 0

        const fetchUrl = async () => {
            ;(await axiosInstance())
                .get(endpoint)
                .then(response => {
                    if (response.data.url) {
                        setLiveHLS(response.data.url)
                        setIsFetchingUrl(false)
                    }
                })
                .catch(err => {
                    attempts++
                    if (attempts < maxRetries) {
                        fetchUrl() // Retry fetching the URL
                    } else {
                        displayError(err, theme) // Assuming displayError is defined elsewhere
                    }
                })
        }

        fetchUrl() // Initial call to fetch the URL
    }, [])

    //Here fetch live HLS URL in this useEffect
    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: APIEndpoints.instance.stream.main.get(streamID),
                item: data,
                setItem: setData,
            })
        }
    }, [])

    useEffect(() => {
        if (profile) {
            // Fetch the initial video URL
            getLiveHlsUrl()

            // Set up timeout to refresh the video URL before it expires (e.g., after 55 minutes)
            const timeoutId = setTimeout(() => {
                getLiveHlsUrl()
            }, 58 * 60 * 1000) // 58 minutes

            // Clean up the timeout on component unmount
            return () => clearTimeout(timeoutId)
        }
    }, [])

    return (
        <MainWrapper
            parent='stream'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Live Stream'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    <div className='tab-content h-100 py-3 px-3'>
                        {canRender === true && !isFetchingUrl ? (
                            <div className='d-flex justify-content-center w-100 mt-5'>
                                <StreamMediaLivePanel
                                    stream={data?.data}
                                    liveHls={liveHLS}
                                />
                            </div>
                        ) : (
                            canRender === false ||
                            (isFetchingUrl && <AuthPagePreloaderComponent />)
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}
