import { useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import AnalyticPanel from './AnalyticPanel'
import { useLoadDependenciesBeforeRender } from '../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender'
import { APIUtils } from '../../../../../../helpers/utils/api'
import APIEndpoints from '../../../../../../config/api/endpoints'
import { notifyFailure } from '../../../../../../helpers/notifications/notifyFailure'
import { GlobalUserProfileContext } from '../../../../../../App'
import { AuthPagePreloaderComponent } from '../../../../../global/graphical/AuthPagePreloaderComponent'
import { StreamAnalyticInstancesAvailableTypes } from '../../../../../../config/enums/instance/stream/analytic'
import { StreamAnalyticInstanceConfig } from '../../../../../../config/instance/stream/analytics'
import { MainWrapper } from '../../../../../MainWrapper'

export const AnalyticsListContainer = () => {
    const { id: streamID } = useParams()
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const navigate = useNavigate()
    const [stream, setStream] = useState({})
    const [canRender] = useLoadDependenciesBeforeRender({
        actionsProgressWithKeys: {
            a: stream?.isFetching,
        },
    })

    useEffect(() => {
        APIUtils.fetchItemData({
            endpoint: APIEndpoints.instance.stream.main.get(streamID),
            item: stream,
            setItem: setStream,
            callBackOnErr: () => {
                notifyFailure(`Error in retrieving stream ${streamID}`)
                navigate(-1)
            },
        })
    }, [streamID])

    return (
        <MainWrapper
            parent='stream'
            isFetching={isFetching}
            profile={profile}
            pageTitle='Analytics'
        >
            <div id='body-camera'>
                <div className='main-frame-camera'>
                    <div className='tab-content h-100 py-3 px-3'>
                        {canRender === true ? (
                            <div className='grid-region my-3'>
                                {StreamAnalyticInstancesAvailableTypes.values().map(
                                    item => {
                                        if (item !== 'global_notification') {
                                            return (
                                                StreamAnalyticInstanceConfig
                                                    .Definitions[item] && (
                                                    <AnalyticPanel
                                                        stream={stream?.data}
                                                        analyticInstanceID={
                                                            item
                                                        }
                                                    />
                                                )
                                            )
                                        }
                                        return <></>
                                    }
                                )}
                            </div>
                        ) : (
                            canRender === false && (
                                <AuthPagePreloaderComponent />
                            )
                        )}
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}
