import React, {useCallback, useContext, useEffect} from "react";
import styled from "styled-components";
import {NotificationRemarkForm} from "./RemarkForm";
import {SizedBox} from "../../../../../../../helpers/utils/SizedBox";
import {LivestreamNotificationContext} from "../../Container";

const Wrapper = styled.div`
    .headerWrapper {
        h3 {
            margin-top: 0;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.6rem;
        }
    }
    .contentWrapper {
        display: flex;
        justify-content: center;
    }
`;


export const AddNotificationRemarkContainer = ({}) => {
    const {modalAddRemarkToNotificationState} = useContext(LivestreamNotificationContext)
    const {notificationInstance} = modalAddRemarkToNotificationState;
    return (
        <Wrapper>
            <div className="headerWrapper">
                <h3>ADD REMARK</h3>
            </div>
            <hr/>
            <SizedBox height={1}/>
            <div className="contentWrapper">
                <NotificationRemarkForm notificationInstance={notificationInstance}/>
            </div>
        </Wrapper>
    );
};
