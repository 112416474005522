import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HandleOnChangeInput } from '../../../../../../../helpers/utils/formInput/HandleOnChangeInput'
import { SizedBox } from '../../../../../../../helpers/utils/SizedBox'
import Spinner from '../../../../../../Spinner'
import axiosInstance from '../../../../../../../config/axios'
import displayError from '../../../../../../../helpers/displayError'
import { VPSContext } from '../../../MainWrapper'
import APIEndpoints from '../../../../../../../config/api/endpoints'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    h5 {
        margin-top: 0;
        padding-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.6rem;
    }

    input {
        color: #8a8888;
        text-indent: 0.5rem !important;
        height: 30px !important;
        font-weight: 400;
        font-size: 12px !important;
        border-bottom: 1.5px solid rgb(37, 68, 120) !important;
    }
    .add-camera_btn {
        padding: 0.4rem 1.2rem;
    }
`
export const AddCheckList = ({ cameraSequence, fetchVPSChecklistItems }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const { VPSInstance } = useContext(VPSContext)
    const [isAddingChecklistItem, setIsAddingChecklistItem] = useState(false)
    const [data, setData] = useState({})

    const validateData = () => {
        if (!data?.question) {
            displayError('Enter the checklist question', theme)
            return false
        }
        if (!data?.guard_email) {
            displayError(
                'Enter the email address of the guard handling the checklist',
                theme
            )
            return false
        }
        return true
    }

    const handleOnSubmit = async () => {
        if (!validateData()) {
            return false
        }
        const requestData = {
            stream_sequence: cameraSequence?.id,
            question: (data?.question || '').toLowerCase().trim(),
            negative_response_action: {
                type: 'Notify',
                config: {
                    guard_email: data?.guard_email,
                },
            },
        }
        setIsAddingChecklistItem(true)
        await (
            await axiosInstance()
        )
            .post(APIEndpoints.instance.vps.checklistItem.add(), requestData)
            .then(response => {
                fetchVPSChecklistItems()
            })
            .catch(err => {
                displayError(err, theme)
            })
        setIsAddingChecklistItem(false)
    }

    return (
        <Wrapper>
            <h5>Add Checklist Item</h5>
            <input
                type='text'
                placeholder='Question'
                value={data?.question}
                onChange={e =>
                    HandleOnChangeInput(e, 'question', setData, data)
                }
            />
            <input
                type='email'
                placeholder='Guard email'
                value={data?.guard_email}
                onChange={e =>
                    HandleOnChangeInput(e, 'guard_email', setData, data)
                }
            />
            <SizedBox height={1} />
            {VPSInstance?.isFetching || isAddingChecklistItem ? (
                <Spinner size={1.5} />
            ) : (
                <Link
                    className='orange-text add-camera_btn'
                    to=''
                    onClick={handleOnSubmit}
                >
                    ADD
                </Link>
            )}
        </Wrapper>
    )
}
