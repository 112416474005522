import React, { useCallback, useContext, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { LivestreamNotificationReportContext } from "../../Container";
import axiosInstance from "../../../../../../../../config/axios";
import displayError from "../../../../../../../../helpers/displayError";
import moment from "moment";
import Spinner from "../../../../../../../Spinner";
import { SizedBox } from "../../../../../../../../helpers/utils/SizedBox";
import CameraFilters from "../../filter";
import { useSelector } from "react-redux";
import { StreamAnalyticInstanceConfig } from "../../../../../../../../config/instance/stream/analytics";
import { StreamAnalyticInstancesAvailableTypes } from "../../../../../../../../config/enums/instance/stream/analytic";
import { toast } from "react-toastify";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const GlobalStyle = createGlobalStyle`
  .MuiPickersToolbar-root {
    background-color: ${({ theme }) =>
      theme === "dark" ? "rgba(94, 132, 195, 1)" : "#ffffff"} !important; /* Change based on the theme */
  }

  /* Typography styles for various toolbar elements */
  .MuiTypography-root {
    color: ${({ theme }) =>
      theme === "dark" ? "#ffffff" : "#000000"} !important; /* Set default color based on theme */
  }

  /* Clock styles */
  .MuiClock-clock {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")}; /* Set clock color based on theme */
  }

  .MuiClockNumber-root {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set hour numbers color */
  }

  .MuiClock-pin {
    background-color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set clock pin color */
  }

  .MuiClockPointer-thumb {
    background-color: ${({ theme }) =>
      theme === "dark" ? "#ffffff" : "#000000"} !important; /* Set clock pointer color */
  }

  /* Additional styles for the DateTimePicker toolbar separator */
  .MuiDateTimePickerToolbar-separator,
  .MuiDateTimePickerToolbar-ampmLabel {
    color: ${({ theme }) =>
      theme === "dark" ? "#ffffff" : "#000000"} !important; /* Set separator and AM/PM label color */
  }

  /* Styles for the Calendar Header */
  .MuiPickersCalendarHeader-root {
    background-color: ${({ theme }) =>
      theme === "dark" ? "rgba(94, 132, 195, 1)" : "#ffffff"} !important; /* Set background color based on theme */
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set text color for the header */
  }

  /* Styles for the content wrapper of the date picker */
  .MuiPickersLayout-contentWrapper {
    background-color: ${({ theme }) =>
      theme === "dark" ? "#2E3B55" : "#ffffff"} !important; /* Change background color for content wrapper */
    padding: 16px; /* Add padding to the content wrapper */
  }

  /* Set text color for the date and time selections */
  .MuiInputBase-input {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set input text color */
    border-bottom: none !important;
  }

  /* Optional: Add more styles to adjust the layout further */
  .MuiPickersLayout-container {
    border-radius: 10px; /* Optional: Add border radius to layout */
  }

  /* Style for the selected date */
  .Mui-selected {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set calendar button color */
  }

  /* Style for the calendar day */
  .MuiButtonBase-root {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Set calendar button color */
  
    /* Style for focus state */
    &:focus {
      background: none !important; /* Remove background on focus */
      outline: none; /* Remove the default outline */
    }
  }  

  /* New styles for the selected day button */
  .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: #1976d2 !important; /* Set background color for selected day */
    color: #fff !important; /* Set text color for selected day */
  }

  /* Styles for MuiDialogActions-root */
  .MuiDialogActions-root {
    background-color: ${({ theme }) =>
      theme === "dark" ? "#2E3B55" : "#f5f5f5"} !important; /* Background color based on theme */
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Text color based on theme */
  }
  .MuiYearCalendar-root{
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#000000")} !important; /* Text color based on theme */
  }

  /* New styles for the selected day button */
  .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }

  .MuiClockPointer-root{
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }

  .MuiClockPointer-thumb{
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center; /* Align items to the center */
  width: 100%; /* Ensure it takes full width */

  label {
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#254478")}; /* Text color based on theme */
    font-size: small;
    font-weight: 500 !important;
  }
`;
const StyledDateTimePicker = styled(MobileDatePicker)`
  .MuiInputBase-root {
    border: 1px solid #5e84c3; /* Add a border */
    border-radius: 25px; /* Set the border radius */
    box-shadow: none; /* Remove any shadow */
    font-size: 12px !important; /* Set font size to 12px */
    height: 33px !important;
    font-weight: 400;
    padding-top: 5px; /* Add some padding */
    cursor: pointer;
  }

  /* Remove the bottom border outline */
  .MuiOutlinedInput-notchedOutline {
    display: none; /* Hide the outline */
    border-bottom: none !important;
  }

  .MuiInputBase-input {
    border-radius: 25px; /* Ensure the input also has rounded corners */
    border: none; /* Ensure no border is shown */
    cursor: pointer;
  }
`;
const Wrapper = styled.div`
  width: 40vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 95vw;
  }

  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;

    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) => (props.theme === "dark" ? "white !important" : "black !important")};
      text-indent: 0.5rem !important;
      height: 30px !important;
    }

    .submit-btn-wrapper {
      align-self: center;

      .add-camera_btn {
        padding: 0.4rem 1.2rem;
      }
    }

    select {
      display: initial !important;
      wisdth: rem;
    }

    textarea {
      height: 10rem;
      border: 0.5px solid !important;
      padding: 0.5rem;
      border-radius: 5px;

      &:focus {
        border: 0.5px solid !important;
      }
    }
  }
  span {
    font-size: 0.5rem !important;
    color: ${(props) => (props.theme === "dark" ? "white!important" : "black!important")};
  }

  .react-select__multi-value__label {
    span {
      font-size: 0.9rem !important;
    }
  }
  .react-select__option {
    span {
      font-size: 0.9rem !important;
      text-overflow: "ellipsis";
    }
  }
  .react-select-container {
    span {
      display: none;
    }
  }
`;
export const GenerateAnalyticEventReportDocumentForm = () => {
  const [data, setData] = useState({});
  const [fromTime, setFromTime] = useState(dayjs());
  const [toTime, setToTime] = useState(dayjs());
  const [filterData, setFilterData] = useState("");
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const { setGenerateReportDocumentModalOpened } = useContext(LivestreamNotificationReportContext);
  const { theme } = useSelector((state) => state.themeReducer);
  const callBackSaveReport = useCallback(async () => {
    if (!filterData?.Site?.length >= 1 || !filterData?.Features?.length >= 1 || !data?.start_date || !data?.end_date) {
      toast.error("Fields are required.");
      return;
    }

    const params = { projects: [], type: [], streams: [] };

    if (filterData?.Features?.length >= 1) {
      params.type = filterData.Features.map((item) => item.label);
    }

    if (filterData?.Site?.length >= 1) {
      params.projects = filterData.Site.map((item) => item.label);
    }

    if (filterData?.Camera?.length >= 1) {
      params.streams = filterData.Camera.map((item) => item.label);
    }

    const requestData = {
      ...data,
      offset_from_utc_in_secs: moment().utcOffset(),
      ...params,
    };

    const endpoint =
      StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes["GLOBAL_NOTIFICATION"]
      ].apiEndpointsEntryPoint.report.main.createNew();

    setIsSubmittingData(true);

    await (
      await axiosInstance()
    )
      .post(endpoint, requestData)
      .then((response) => {
        setTimeout(() => {
          setIsSubmittingData(false);
          setGenerateReportDocumentModalOpened(false);
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }, 2000);
      })
      .catch((err) => {
        displayError(err, theme);
        setTimeout(() => {
          setIsSubmittingData(false);
        }, 2000);
      });
  }, [data, isSubmittingData, filterData]);

  return (
    <Wrapper theme={theme}>
      <GlobalStyle theme={theme} />

      <div className="fieldsWrapper">
        <CameraFilters setterCallback={setFilterData} />
        <SizedBox height={2} />

        <div className="date-container">
          <Container>
            <label>Start Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDateTimePicker
                value={fromTime}
                onChange={(newValue) => {
                  setFromTime(newValue);
                  setData({ ...data, start_date: dayjs(newValue).format("YYYY-MM-DD") });
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </LocalizationProvider>
          </Container>

          <SizedBox height={2} />

          <Container>
            <label>End Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDateTimePicker
                value={toTime}
                onChange={(newValue) => {
                  setToTime(newValue);
                  setData({ ...data, end_date: dayjs(newValue).format("YYYY-MM-DD") });
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </LocalizationProvider>
          </Container>
        </div>

        <SizedBox height={2} />

        <div className="submit-btn-wrapper">
          {isSubmittingData ? (
            <Spinner size={1.5} />
          ) : (
            <button
              className={`orange-text add-camera_btn ${theme === "dark" && "add-camera_btn-dark"}`}
              onClick={() => callBackSaveReport()}
            >
              SAVE
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
