import {
    CLEAR_ACTIVE_NAVIGATION,
    CLEAR_USER_PROFILE,
    FETCH_PROFILE_FAILED,
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROJECTS_FAILED,
    FETCH_PROJECTS_START,
    FETCH_PROJECTS_SUCCESS,
    REFRESH_PROFILE_SUCCESS,
    SET_ACTIVE_NAVIGATION,
    TOGGLE_THEME,
} from './constants'
import moment from 'moment'

export const profileReducer = (
    state = { isFetching: false, profile: null },
    { type, payload }
) => {
    switch (type) {
        case FETCH_PROFILE_START:
            return {
                isFetching: true,
                profile: state.profile,
            }
        case FETCH_PROFILE_FAILED:
            return {
                isFetching: false,
                profile: state.profile,
            }
        case FETCH_PROFILE_SUCCESS:
            return {
                lastTimeFetched: moment(),
                isFetching: false,
                profile: payload,
            }
        case REFRESH_PROFILE_SUCCESS:
            return {
                isFetching: false,
                profile: payload,
            }
        case CLEAR_USER_PROFILE:
            return {
                lastTimeFetched: null,
                isFetching: false,
                profile: null,
            }
        default:
            return state
    }
}
export const globalNavigationReducer = (
    state = {
        activeNavigationKey: 1,
    },
    { type, payload }
) => {
    switch (type) {
        case CLEAR_ACTIVE_NAVIGATION:
            return {
                ...state,
                activeNavigationKey: null,
            }
        case SET_ACTIVE_NAVIGATION:
            return {
                ...state,
                activeNavigationKey: payload,
            }

        default:
            return state
    }
}

export const themeReducer = (
    state = {
        theme: localStorage.getItem('theme') || 'light', // Check local storage first
    },
    action
) => {
    switch (action.type) {
        case TOGGLE_THEME:
            return {
                ...state,
                theme: action.payload,
            }

        default:
            return state
    }
}

export const projectsListReducer = (
    state = { porjectsList: 0 },
    { type, payload }
) => {
    switch (type) {
        case FETCH_PROJECTS_START:
            return {
                isFetching: true,
                projectsList: state.projectsList,
            }
        case FETCH_PROJECTS_FAILED:
            return {
                isFetching: false,
                projectsList: state.projectsList,
            }
        case FETCH_PROJECTS_SUCCESS: {
            return {
                lastTimeFetched: moment(),
                isFetching: false,
                projectsList: payload,
            }
        }
        default:
            return state
    }
}
