import { useState, useMemo } from "react";
import styled from "styled-components";
import { TableCardHeader } from "./components/TableCardHeader";
import Pagination from "../../pagination/Pagination";

const StyledComponent = styled.div`
  * {
    font-size: 0.85rem;
    font-family: "Poppins";
  }
  .tableOuter {
    overflow-x: scroll;
  }
  table {
    width: 100%;
    max-width: 100vw;
    position: relative;
  }

  tr {
    border: 0px;
  }
  td {
    &:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      ${"" /* border-color: red; */}
    }

    &:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      ${"" /* border-color: red; */}
    }
  }

  table thead th {
    text-align: center;
  }

  table tbody td {
    text-align: center;
  }
`;

export const TableCardComponent = ({ headerData, bodyData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return bodyData && bodyData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, bodyData, PageSize]);

  return (
    <StyledComponent>
      <div className='tableOuter'>
        <table>
          <thead>
            <TableCardHeader HeaderData={headerData} />
          </thead>
          <tbody>{currentTableData}</tbody>
        </table>
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={bodyData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </StyledComponent>
  );
};
