import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import displayError from '../../../../../../helpers/displayError'
import { VPSContext } from '../../MainWrapper'
import { SubAdminSubUserRoutes } from '../../../../../../routes/SubAdmin&SubUserRoutes'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 2rem;
    @media (max-width: 920px) {
        padding-bottom: 6rem;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
    .configureVpsOuter {
        padding: 2rem;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 2rem;

        .start-button {
            border-radius: 5px;
            padding: 1rem 2rem;
            font-size: 1.5rem !important;
        }
    }
`
export const StartVps = () => {
    const { VPSInstance } = useContext(VPSContext)
    const { theme } = useSelector(state => state.themeReducer)
    return (
        <Wrapper>
            <div className='configureVpsOuter'>
                {VPSInstance && (
                    <Link
                        className={`orange-text add-camera_btn start-button ${
                            theme === 'dark' && 'add-camera_btn-dark'
                        }`}
                        to={
                            SubAdminSubUserRoutes.pathDefinitions.feature.vps
                                .session
                        }
                        onClick={e => {
                            if (!VPSInstance) {
                                displayError(
                                    'Unable to start Virtual Patrolling',
                                    theme
                                )
                                e.preventDefault()
                            }
                        }}
                    >
                        START VPS
                    </Link>
                )}
            </div>
        </Wrapper>
    )
}
