import { APIEndpointsHelper } from '../../../../../../index'

export class APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotification {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/line_intrusion_detector/event/notification/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/line_intrusion_detector/event/notification/${id}/`
    }

    static update(id) {
        return `/instance/stream/analytic/line_intrusion_detector/event/notification/${id}/`
    }

    static destroy(id) {
        return `/instance/stream/analytic/line_intrusion_detector/event/notification/${id}/`
    }

    static batchDestroy(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/line_intrusion_detector/event/notification/batch_destroy/`,
            params
        )
    }
}

export class APIEndpointInstanceStreamAnalyticsLineIntrusionDetectorEventNotificationAsGuest {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            `/instance/stream/analytic/line_intrusion_detector/event/notification/guest/`,
            params
        )
    }

    static get(id) {
        return `/instance/stream/analytic/line_intrusion_detector/event/notification/${id}/guest/`
    }
}
