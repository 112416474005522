import { APIEndpointsHelper } from "../../../../../../index";

export class APIEndpointInstanceStreamAnalyticsMotionDetectorEventReport {
  static create() {
    return ``;
  }

  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(``, params);
  }

  static get(id) {
    return ``;
  }

  static update(id) {
    return ``;
  }

  static destroy(id) {
    return ``;
  }
}

export class APIEndpointInstanceStreamAnalyticsMotionDetectorEventReportAsGuest {
  static getAll(params) {
    return APIEndpointsHelper.parseEndpointForQueryParams(``, params);
  }

  static get(id) {
    return ``;
  }
}
