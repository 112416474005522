import { Route, Routes } from "react-router-dom";
import { ProtectedRouteComponent } from "./protected_routes";
import {
  accountHasSubscription,
  accountIsComplete,
  isAccountTypes,
  isAuthenticated,
} from "../conditions";
import SubUserList from "../components/sub_admin/subuser/SubUserList";
import AddSubUser from "../components/sub_admin/subuser/AddSubUser";
import UpdateSubUser from "../components/sub_admin/subuser/UpdateSubUser";
import { UserAccountType } from "../config/enums/user";
import { GlobalRoutes } from "./GlobalRoutes";

export class SubAdminRoutes {
  static get pathDefinitions() {
    return {
      viewSubUsers: "/sub_users",
      addSubUser: "/sub_user/add",
      viewSingleSubUser: "/sub_user/:id",
    };
  }

  static Mapping() {
    return (
      <Routes>
        <Route
          path={SubAdminRoutes.pathDefinitions.viewSubUsers}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.SUB_ADMINISTRATOR]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<SubUserList />}
            />
          }
        />
        <Route
          path={SubAdminRoutes.pathDefinitions.addSubUser}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.SUB_ADMINISTRATOR]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<AddSubUser />}
            />
          }
        />
        <Route
          path={SubAdminRoutes.pathDefinitions.viewSingleSubUser}
          element={
            <ProtectedRouteComponent
              conditions={{
                [GlobalRoutes.pathDefinitions.account.login2]: isAuthenticated,
                [GlobalRoutes.pathDefinitions.account.dashboard]: async () =>
                  await isAccountTypes([UserAccountType.SUB_ADMINISTRATOR]),
                [GlobalRoutes.pathDefinitions.misc.noSubscription]:
                  accountHasSubscription,
                [GlobalRoutes.pathDefinitions.misc.accountIncomplete]:
                  accountIsComplete,
              }}
              elementToRenderOnPass={<UpdateSubUser />}
            />
          }
        />
      </Routes>
    );
  }
}
