import styled from 'styled-components'

const StyledWrapper = styled.div`
    flex-grow: 1;
    padding: 0;
    /* margin-bottom: 1rem; */
    width: 100%;
    height: 90vh;
`

export const MainContentContainer = ({ children }) => {
    return <StyledWrapper>{children}</StyledWrapper>
}
