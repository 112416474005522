import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SpinnerStyled = styled.div`
    height: ${prop => prop.size}rem;
    width: ${prop => prop.size}rem;
    border-radius: 50%;
    border-top: ${prop => prop.color} 3px solid;
    border-bottom: ${prop => prop.color} 3px solid;
    animation-name: spinnerAnimation;
    animation-timing-function: linear;
    animation-duration: ${prop => prop.rot_time}s;
    animation-iteration-count: infinite;

    @keyframes spinnerAnimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const Spinner = ({ size = 2, rot_time = 1, color = 'rgb(37, 68, 120)' }) => {
    const { theme } = useSelector(state => state.themeReducer)

    return (
        <SpinnerStyled
            size={size}
            rot_time={rot_time}
            color={theme === 'dark' ? 'white' : color}
        />
    )
}

export default Spinner
