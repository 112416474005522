import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AlertContentStyled } from './alertBaseConfig'
import { ServerErrorResponseHelperParser } from './utils/response/server/error/parser'
import { ErrorDisplayContainer } from './utils/response/server/error/components/Container'
import { ResponseStatusCode } from '../config/enums/api'
import { AppConfig } from '../config'

const displayError = (error, theme) => {
    const MySwal = withReactContent(Swal)
    let alertContent
    if (error.response) {
        if (
            AppConfig.IS_IN_PRODUCTION_ENVIRONMENT &&
            error.response.status === ResponseStatusCode.INTERNAL_SERVER_ERROR
        ) {
            alertContent = (
                <AlertContentStyled>
                    A server error was encountered, kindly try again
                </AlertContentStyled>
            )
        } else {
            const errParserInstance = new ServerErrorResponseHelperParser(
                error.response.status,
                error.response.data
            )
            const parsedError = errParserInstance.parsedData
            alertContent = (
                <AlertContentStyled>
                    <ErrorDisplayContainer errorData={parsedError} />
                </AlertContentStyled>
            )
        }
    } else if (error.request) {
        alertContent = (
            <AlertContentStyled>
                Error in processing your request, kindly check your network
                connection
            </AlertContentStyled>
        )
    } else if (typeof error === 'string') {
        alertContent = <AlertContentStyled>{error}</AlertContentStyled>
    } else {
        alertContent = (
            <AlertContentStyled>Unknown error encountered</AlertContentStyled>
        )
    }

    return MySwal.fire({
        title: (
            <p
                className='alert-title'
                style={
                    theme === 'dark' ? { color: 'rgba(94, 132, 195, 1)' } : {}
                }
            >
                Notification
            </p>
        ),
        html: alertContent,
        showConfirmButton: true,
        background: theme === 'dark' ? 'rgba(38, 56, 84, 1)' : 'white',
        color: theme === 'dark' && 'white',
    })
}

export default displayError
