import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { GlobalUserProfileContext } from "../../../../../../App";
import { SizedBox } from "../../../../../../helpers/utils/SizedBox";
import Spinner from "../../../../../Spinner";
import { TableComponent } from "../../../../../../helpers/utils/table/Container";
import { APIUtils } from "../../../../../../helpers/utils/api";
import APIEndpoints from "../../../../../../config/api/endpoints";
import { useLoadDependenciesBeforeRender } from "../../../../../../helpers/hooks/UseLoadDependenciesBeforeRender";

import { MainWrapper } from "../../../../../MainWrapper";
import { useSelector } from "react-redux";
import { StreamAnalyticInstanceConfig } from "../../../../../../config/instance/stream/analytics";
import { StreamAnalyticInstancesAvailableTypes } from "../../../../../../config/enums/instance/stream/analytic";
import { displayMessage } from "../../../../../../helpers/displayMessage";
import { ModalComponent } from "../../../../../../helpers/utils/Modal";
import { SendAnalyticEventReportDocumentContainer } from "./modals/send/Container";

const StyledComponent = styled.div`
  table {
    thead {
      tr {
        th {
          background-color: ${(props) =>
            props.theme === "dark" ? "rgba(38, 56, 84, 1)" : "aquamarine"};
          border-radius: 0px;
        }
      }
    }
  }
  table,
  th,
  td {
    border-collapse: collapse;
  }
`;
const tableHeaderData = ["Sr No", "Project", "Stream", "Type", "Date", "Time"];

export const StreamAnalyticEventNotificationReportViewContainer = () => {
  const [sendReportDocumentModalOpened, setSendReportDocumentModalOpened] =
    useState(false);

  const location = useLocation();
  const item = location.state;
  const [tableBodyData, setTableBodyData] = useState([]);
  const [notificationEvents, setNotificationEvents] = useState(null);
  const [formattedNotificationEvents, setFormattedNotificationEvents] =
    useState(null);
  const { isFetchingProfile, profile } = useContext(GlobalUserProfileContext);
  const { id: userID } = useParams();
  const { theme } = useSelector((state) => state.themeReducer);
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: notificationEvents?.isFetching,
    },
  });

  const formatNotificationEventsToTableData = useCallback(() => {
    setFormattedNotificationEvents(
      (notificationEvents?.data || []).map((item, index) => ({
        row: "row1",
        id: index * 3,
        type: "text",
        detectionPhoto: "Detection Photo",
        image: item?.event_image?.file,
        value: {
          sNo: `${(index + 1)
            .toString()
            .padStart(
              (notificationEvents?.data || []).length.toString().length,
              "0"
            )} `,
          project: `${item?.project}`,
          stream: `${item?.stream?.label_name}`,
          type: `${
            StreamAnalyticInstanceConfig.Definitions[
              StreamAnalyticInstancesAvailableTypes[item?.type]
            ]?.label
          }`,
          date: `${moment(item?.date_added).format("YYYY-MM-DD")}`,
          time: `${moment(item?.event_time).format("HH:mm")}`,
        },
      }))
    );
  }, [notificationEvents]);

  const tableSetData = useCallback(() => {
    setTableBodyData(
      formattedNotificationEvents.map((item, i) => ({
        tableRows: Object.values(item.value).map((config, ixd) => (
          <tr key={ixd}>
            <th className='color-bg'>{tableHeaderData[ixd]}</th>
            <td colSpan={item.row === "row1" ? 1 : 6}>{config}</td>
          </tr>
        )),
        imageObj: { heading: item?.detectionPhoto, src: item?.image },
      }))
    );
  }, [formattedNotificationEvents]);

  useEffect(() => {
    if (profile || userID) {
      APIUtils.fetchItemData({
        endpoint: (profile
          ? APIEndpoints.instance.stream.analytic.global_notification.report
              .main
          : APIEndpoints.instance.stream.analytic.global_notification.report
              .guest
        ).getReports({ id: userID }),
        item: notificationEvents,
        setItem: setNotificationEvents,
        fetchWithSecretKey: !profile,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (notificationEvents) {
      formatNotificationEventsToTableData();
    }
  }, [notificationEvents]);

  useEffect(() => {
    if (formattedNotificationEvents) {
      tableSetData();
    }
  }, [formattedNotificationEvents]);

  const navigate = useNavigate();

  return (
    <MainWrapper
      parent='camera'
      isFetching={isFetchingProfile}
      profile={profile}
      pageTitle='Analytic Events Report View'
    >
      <div id='body-camera'>
        <div className={`main-frame-camera ${profile ? "" : "no_side_bar"}`}>
          <div className='tab-content h-100 py-3 px-3'>
            <div className='mt-2 mb-2 d-flex align-items-center justify-content-between'>
              <StyledDownload>
                <button
                  className={`add-camera_btn ${
                    theme === "dark" && "add-camera_btn-dark"
                  }`}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </StyledDownload>

              <StyledDownload>
                <button
                  className={`add-camera_btn ${
                    theme === "dark" && "add-camera_btn-dark"
                  }`}
                  onClick={async (e) => {
                    e.preventDefault();
                    setSendReportDocumentModalOpened(true);
                  }}
                >
                  Export as pdf
                </button>
              </StyledDownload>
            </div>
            <SizedBox height={1} />
            <StyledComponent theme={theme}>
              {canRender !== true ? (
                <div className='spinnerWrapper'>
                  <SizedBox height={3} />
                  {canRender === false && <Spinner size={2} />}
                </div>
              ) : notificationEvents?.data === null ? (
                <div className='spinnerWrapper'>
                  <SizedBox height={3} />
                  <span>Their was an error in fetching the report</span>
                </div>
              ) : (
                <>
                  <HeadingComponent theme={theme}>
                    <div className='vps-logo-wrapper'>
                      <img
                        className='bounded-image'
                        src={profile?.picture?.file}
                        alt={"Logo not found"}
                      />
                    </div>
                    <div className='vps-report-details-wrapper'>
                      <div className='vps-report-detail-wrapper'>
                        <span className='vps-report-detail-title'>
                          Generated Date & Time:
                        </span>
                        {/* <div className='vps-report-detail-separator'>:</div> */}
                        <div className='vps-report-detail-value'>
                          {item?.value?.date && item?.value?.date}
                        </div>
                      </div>

                      <div className='vps-report-detail-wrapper'>
                        <span className='vps-report-detail-title'>
                          Start Date:
                        </span>
                        {/* <div className='vps-report-detail-separator'>:</div> */}
                        <div className='vps-report-detail-value'>
                          {item?.value?.start_date &&
                            moment(item?.value?.start_date).format(
                              "YYYY-MM-DD"
                            )}
                        </div>
                      </div>
                      <div className='vps-report-detail-wrapper'>
                        <span className='vps-report-detail-title'>
                          End Date:
                        </span>
                        {/* <div className='vps-report-detail-separator'>:</div> */}
                        <div className='vps-report-detail-value'>
                          {item?.value?.end_date &&
                            moment(item?.value?.end_date).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </div>
                  </HeadingComponent>
                  <SizedBox height={2} />

                  <TableComponent
                    headerData={tableHeaderData}
                    bodyData={tableBodyData}
                    theme={theme}
                  />
                </>
              )}
            </StyledComponent>
          </div>
        </div>
      </div>
      <ModalComponent
        modalLabel={"Send via Email"}
        isOpen={sendReportDocumentModalOpened}
        callbackCloseModal={() => {
          setSendReportDocumentModalOpened(false);
        }}
      >
        <SendAnalyticEventReportDocumentContainer reportItem={item} />
      </ModalComponent>
    </MainWrapper>
  );
};

const StyledDownload = styled.div`
  .add-camera_btn {
    font-family: "Poppins" !important;
    text-transform: uppercase;
    padding: 0.4rem 1.5rem;
    @media screen and (max-width: 450px) {
      margin-top: 0rem !important;
    }
  }
`;

const HeadingComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  .vps-logo-wrapper {
    width: 11rem;
    height: 11rem;

    img {
      border-radius: 50%;
      object-fit: fill;
    }
  }

  .vps-report-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
    .vps-report-detail-wrapper {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      span {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* color: rgba(0, 0, 0, 1); */

        color: ${(props) => (props.theme === "dark" ? "white" : "black")};
      }
      .vps-report-detail-value {
        font-family: "Poppins";
        font-size: 12px;
      }
    }
  }
`;
