import '../../styles/account_auth.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Unlock from '../../static/svg/unlockPass.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeComponent } from '../global/toggletheme'
import { DarkThemeAuth } from '../global/graphical/DarkThemeAuth'
import APIEndpoints from '../../config/api/endpoints'
import axiosInstance from '../../config/axios'
import { displayMessage } from '../../helpers/displayMessage'
import displayError from '../../helpers/displayError'
import { replace } from 'lodash'
import { notifyFailure } from '../../helpers/notifications/notifyFailure'

const ResetPassword = () => {
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [isInitiating, setIsInitiating] = useState(false)

    const location = useLocation()
    const email = location.state
    const handleSubmit = async () => {
        if (newPassword !== password) {
            notifyFailure('Your password fields does not match each other')
            return
        }
        setIsInitiating(true)

        const requestPayload = {
            verify: false,
            email: email,
            password: password,
            confirm_password: newPassword,
        }
        ;(await axiosInstance())
            .post(APIEndpoints.auth.me.finalizeForgotPassword(), requestPayload)
            .then(response => {
                displayMessage(response.data.msg)
                navigate('/login', { replace: true })
                setIsInitiating(false)
            })
            .catch(err => {
                displayError(err, theme)
                setIsInitiating(false)
            })
    }
    const { theme } = useSelector(state => state.themeReducer)
    return (
        <div
            id='body-2'
            style={
                theme === 'dark'
                    ? {
                          background: `linear-gradient(
to right bottom,
rgb(32, 45, 66),
rgb(22, 33, 49),
rgb(20, 45, 49)`,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
                    : {
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
        >
            <div className='toggleBtnAuth'>
                <ThemeComponent />
            </div>
            <DarkThemeAuth>
                <div
                    className={`main-frame ${
                        theme === 'dark' && 'main-frame-dark'
                    }`}
                    style={{ width: '500px' }}
                >
                    <div className='form-wrapper' style={{ width: '100%' }}>
                        <form className='d-flex flex-column align-items-start w-100'>
                            <div
                                className='desktop mt-5'
                                style={{ width: '100px', height: '100px' }}
                            >
                                <img
                                    src={Unlock}
                                    alt=''
                                    className='w-100 h-100,'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-5'>
                                <span
                                    className={`highlight-heading ${
                                        theme === 'dark' &&
                                        'highlight-heading-dark'
                                    } text-auth-title`}
                                >
                                    nettbox
                                </span>
                            </div>

                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='password'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    New Password
                                </label>
                                <input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type='password'
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='password'
                                    placeholder='Enter password here...'
                                />
                            </div>
                            <div className='my-3 w-100'>
                                <label
                                    htmlFor='password'
                                    className={`form-label highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Confirm Password
                                </label>
                                <input
                                    value={newPassword}
                                    onChange={e =>
                                        setNewPassword(e.target.value)
                                    }
                                    type='password'
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='newPassword'
                                    placeholder='Enter password again...'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <button
                                    disabled={isInitiating}
                                    type='submit'
                                    onClick={e => {
                                        e.preventDefault()
                                        handleSubmit()
                                    }}
                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                        theme === 'dark' &&
                                        'btn-custom-orange-dark'
                                    }`}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </DarkThemeAuth>
        </div>
    )
}

export default ResetPassword
