import "../../styles/account_auth.css";

import { useContext, useState } from "react";
import { GlobalUserProfileContext } from "../../App";
import { MainWrapper } from "../MainWrapper";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FilterContainer } from "../global/filters/Container";
import Features from "./components/dashbaord/filters/Featurs";

const Dashboard = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const [filterData, setFilterData] = useState({});
  const [featureData, setFeatureData] = useState({});

  console.log(profile);

  const { theme } = useSelector((state) => state.themeReducer);

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  return (
    <MainWrapper
      parent='account'
      isFetching={isFetching}
      profile={profile}
      pageTitle='Dashboard'
    ></MainWrapper>
  );
};

export default Dashboard;

const StyledComponent = styled.div`
  margin: 1.3rem;

  .user-welcome {
    font-size: clamp(0.5rem, 5vw, 1.5rem);
    color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    span {
      color: ${(props) => (props.theme === "dark" ? "#5E84C3" : "#254478")};
    }
  }

  .filters-content {
    span {
      font-size: 0.9rem !important;
      color: ${(props) =>
        props.theme === "dark" ? "white!important" : "black!important"};
    }

    .react-select-single__option {
      span {
        font-size: 0.9rem !important;
        text-overflow: "ellipsis";
      }
    }
    .react-select-single-container {
      span {
        display: none;
      }
      .react-select-single__loading-indicator {
        margin-right: 0px !important;
        padding: 0px !important;

        span {
          display: block !important;
        }
      }
    }
  }
`;
