import '../../styles/account_auth.css'
import { useDispatch } from 'react-redux'
import { fetchProfile } from '../../config/actions'
import { useContext, useState } from 'react'
import Spinner from '../Spinner'
import axiosInstance from '../../config/axios'
import displayError from '../../helpers/displayError'
import { GlobalUserProfileContext } from '../../App'
import { handleFileUploadSingle } from '../../helpers/utils/api/fileManagement/handler'
import APIEndpoints from '../../config/api/endpoints'
import { FileUploadPurpose } from '../../config/enums/file'
import { RightSideContent } from '../RightSideContent'
import { useSelector } from 'react-redux'

const ProfileView = ({ closeProfile }) => {
    const { theme } = useSelector(state => state.themeReducer)
    const dispatch = useDispatch()
    const { isFetchingProfile: isFetching, profile } = useContext(
        GlobalUserProfileContext
    )
    const [newEmailAddress, setNewEmailAddress] = useState('')
    const [newFirstName, setNewFirstName] = useState('')
    const [newLastName, setNewLastName] = useState('')
    const [newContactNumber, setNewContactNumber] = useState('')
    const [newProfilePicture, setNewProfilePicture] = useState(null)
    const [isUpdatingProfilePicture, setUpdatingProfilePicture] =
        useState(false)
    const [isUpdatingEmail, setUpdatingEmail] = useState(false)
    const [isUpdatingFirstName, setUpdatingFirstName] = useState(false)
    const [isUpdatingLastName, setUpdatingLastName] = useState(false)
    const [isUpdatingContactNumber, setUpdatingContactNumber] = useState(false)

    const handleKeyPress = e => {
        if (e.charCode === 13) {
            handleSave(e)
        }
    }

    const handleSave = async e => {
        e.preventDefault()
        if (newProfilePicture) {
            setUpdatingProfilePicture(true)
            const fileUploadData = {
                to_be_deleted_file_id: profile?.picture?.id,
                to_be_uploaded_buffer: newProfilePicture,
                purpose: FileUploadPurpose.PROFILE_PICTURE,
            }
            const fileUploadResponse = await handleFileUploadSingle(
                fileUploadData
            )
            if (fileUploadResponse) {
                await (
                    await axiosInstance()
                )
                    .patch(APIEndpoints.auth.me.updateMyProfile(), {
                        picture: fileUploadResponse.id,
                    })
                    .then(response => {
                        dispatch(fetchProfile)
                    })
                    .catch(err => {
                        displayError(err, theme)
                    })
            }
            setUpdatingProfilePicture(false)
        }
        if (
            newContactNumber &&
            newContactNumber.trim() !== profile.contact_number
        ) {
            setUpdatingContactNumber(true)
            ;(await axiosInstance())
                .patch(APIEndpoints.auth.me.updateMyProfile(), {
                    contact_number: newContactNumber.trim(),
                })
                .then(response => {
                    setUpdatingContactNumber(false)
                    dispatch(fetchProfile)
                })
                .catch(err => {
                    displayError(err, theme)
                    setUpdatingContactNumber(false)
                })
        }
        if (newFirstName && newFirstName.trim() !== profile.first_name) {
            setUpdatingFirstName(true)
            ;(await axiosInstance())
                .patch(APIEndpoints.auth.me.updateMyProfile(), {
                    first_name: newFirstName.trim(),
                })
                .then(response => {
                    setUpdatingFirstName(false)
                    dispatch(fetchProfile)
                })
                .catch(err => {
                    displayError(err, theme)
                    setUpdatingFirstName(false)
                })
        }
        if (newLastName && newLastName.trim() !== profile.last_name) {
            setUpdatingLastName(true)
            ;(await axiosInstance())
                .patch(APIEndpoints.auth.me.updateMyProfile(), {
                    last_name: newLastName.trim(),
                })
                .then(response => {
                    setUpdatingLastName(false)
                    dispatch(fetchProfile)
                })
                .catch(err => {
                    displayError(err, theme)
                    setUpdatingLastName(false)
                })
        }
        if (newEmailAddress && newEmailAddress.trim() !== profile.email) {
            setUpdatingEmail(true)
            ;(await axiosInstance())
                .patch(APIEndpoints.auth.me.updateMyProfile(), {
                    email: newEmailAddress.trim(),
                })
                .then(response => {
                    setUpdatingEmail(false)
                    dispatch(fetchProfile)
                })
                .catch(err => {
                    displayError(err, theme)
                    setUpdatingEmail(false)
                })
        }
    }

    return (
        <RightSideContent theme={theme}>
            <div className='main-content'>
                <div className='w-full d-flex align-items-center justify-content-between'>
                    <h6 className='heading'>Profile Settings</h6>
                    <span
                        onClick={() => closeProfile()}
                        className='close-profile'
                    >
                        X
                    </span>
                </div>

                {!isFetching || profile ? (
                    profile ? (
                        <>
                            {!isUpdatingProfilePicture ? (
                                <>
                                    <div className='d-flex align-items-center justify-content-between mt-5'>
                                        <div className='input-wrapper'>
                                            <div className='file-field input-field d-flex flex-column'>
                                                <div
                                                    className={`btn btn-custom-orange choose_pic_button waves-effect waves-light text-small btn-small ${
                                                        theme === 'dark' &&
                                                        'btn-custom-orange-dark'
                                                    }`}
                                                >
                                                    <span>Picture File</span>
                                                    <input
                                                        onKeyPress={
                                                            handleKeyPress
                                                        }
                                                        onChange={e =>
                                                            setNewProfilePicture(
                                                                e.target
                                                                    .files[0]
                                                            )
                                                        }
                                                        type='file'
                                                        className='enableonlyinabovesm'
                                                    />
                                                </div>
                                                <div className='file-path-wrapper'>
                                                    <input
                                                        onKeyPress={
                                                            handleKeyPress
                                                        }
                                                        className={`form-file-custom ${
                                                            theme === 'dark' &&
                                                            'form-file-custom'
                                                        } file-path choose_pic_path validate bd-buttom-orange-focus text-white enableonlyinabovesm`}
                                                        type='text'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='content'
                                            style={{
                                                borderRadius: '50%',
                                            }}
                                        >
                                            <div className='logo-wrapper account-image-wrapper'>
                                                <img
                                                    src={profile?.picture?.file}
                                                    alt=''
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='content mt-2'>
                                    <div className='logo-wrapper account-image-wrapper d-flex flex-column align-items-center justify-content-center'>
                                        <Spinner size={1.5} />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null
                ) : (
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <Spinner size={1.5} />
                    </div>
                )}

                {!isFetching || profile ? (
                    profile ? (
                        <>
                            <div className='grid-region'>
                                <div className='d-flex'>
                                    {!isUpdatingFirstName ? (
                                        <div className='dashpanel p-2 p-md-3'>
                                            <div className='title'>
                                                First Name
                                            </div>

                                            <div className='content px-3'>
                                                {profile.first_name || '-'}
                                            </div>

                                            <div className='input-wrapper'>
                                                <label htmlFor='firstname'>
                                                    New First Name
                                                </label>
                                                <input
                                                    onKeyPress={handleKeyPress}
                                                    value={newFirstName}
                                                    onChange={e =>
                                                        setNewFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                    type='text'
                                                    className={`form-control form-control-custom ${
                                                        theme === 'dark' &&
                                                        'form-control-custom-dark'
                                                    }`}
                                                    id='firstname'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='dashpanel card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center'>
                                            <Spinner size={1.5} />
                                        </div>
                                    )}
                                    {!isUpdatingLastName ? (
                                        <div className='dashpanel p-2 p-md-3'>
                                            <div className='title'>
                                                Last Name
                                            </div>

                                            <div className='content px-3'>
                                                {profile.last_name || '-'}
                                            </div>

                                            <div className='input-wrapper'>
                                                <label htmlFor='lastname'>
                                                    New Last Name
                                                </label>
                                                <input
                                                    onKeyPress={handleKeyPress}
                                                    value={newLastName}
                                                    onChange={e =>
                                                        setNewLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                    type='text'
                                                    className={`form-control form-control-custom ${
                                                        theme === 'dark' &&
                                                        'form-control-custom-dark'
                                                    }`}
                                                    id='lastname'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='dashpanel card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center'>
                                            <Spinner size={1.5} />
                                        </div>
                                    )}
                                </div>
                                <div className='d-flex'>
                                    {!isUpdatingEmail ? (
                                        <div className='dashpanel p-2 p-md-3'>
                                            <div className='title'>
                                                Email Address
                                            </div>

                                            <div className='content px-3'>
                                                {profile.email
                                                    ? profile.email
                                                    : '-'}
                                            </div>

                                            <div className='input-wrapper'>
                                                <label htmlFor='email'>
                                                    New Email Address
                                                </label>
                                                <input
                                                    onKeyPress={handleKeyPress}
                                                    value={newEmailAddress}
                                                    onChange={e =>
                                                        setNewEmailAddress(
                                                            e.target.value
                                                        )
                                                    }
                                                    type='email'
                                                    className={`form-control form-control-custom ${
                                                        theme === 'dark' &&
                                                        'form-control-custom-dark'
                                                    }`}
                                                    id='email'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='dashpanel card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center'>
                                            <Spinner size={1.5} />
                                        </div>
                                    )}
                                    <div className='dashpanel p-2 p-md-3'>
                                        <div className='title'>Username</div>
                                        <div className='content px-3'>
                                            {profile.username}
                                        </div>
                                    </div>
                                </div>
                                {!isUpdatingContactNumber ? (
                                    <div
                                        className='dashpanel p-2 p-md-3'
                                        style={{ width: '100%' }}
                                    >
                                        <div className='title'>
                                            Contact Number
                                        </div>

                                        <div className='content px-3'>
                                            {profile.contact_number || '-'}
                                        </div>

                                        <div className='input-wrapper'>
                                            <label htmlFor='contact_number'>
                                                New Contact Number
                                            </label>
                                            <input
                                                onKeyPress={handleKeyPress}
                                                value={newContactNumber}
                                                onChange={e =>
                                                    setNewContactNumber(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className={`form-control form-control-custom ${
                                                    theme === 'dark' &&
                                                    'form-control-custom-dark'
                                                }`}
                                                id='contact_number'
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='dashpanel card p-2 p-md-3 d-flex flex-column align-items-center justify-content-center'>
                                        <Spinner size={1.5} />
                                    </div>
                                )}
                            </div>
                            <div className='showpanelonlysmandbelow'>
                                <div className='w-100 d-flex justify-content-center mt-auto mb-2 '>
                                    <button
                                        type='submit'
                                        onClick={handleSave}
                                        className={` btn btn-custom-orange waves-effect waves-light px-5 ${
                                            theme === 'dark' &&
                                            'btn-custom-orange-dark'
                                        }`}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='d-flex justify-content-center align-items-center min-height-100vh'>
                            Error in loading profile
                        </div>
                    )
                ) : (
                    <div className='d-flex justify-content-center align-items-center min-height-100vh'></div>
                )}
                <div className='d-flex mt-5'>
                    <button
                        type='submit'
                        onClick={handleSave}
                        className={`enableonlyinabovesm btn btn-custom-orange waves-effect waves-light px-5 ${
                            theme === 'dark' && 'btn-custom-orange-dark'
                        }`}
                    >
                        Save
                    </button>
                </div>
            </div>
        </RightSideContent>
    )
}

export default ProfileView
