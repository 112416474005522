import '../../styles/account_auth.css'
import SendEmail from '../../static/svg/leftEmail.png'
import { useSelector } from 'react-redux'
import { ThemeComponent } from '../global/toggletheme'
import { DarkThemeAuth } from '../global/graphical/DarkThemeAuth'
import { useState } from 'react'
import axiosInstance from '../../config/axios'
import APIEndpoints from '../../config/api/endpoints'
import { useNavigate } from 'react-router-dom'
import displayError from '../../helpers/displayError'
import { displayMessage } from '../../helpers/displayMessage'

const ForgotPassword = () => {
    const { theme } = useSelector(state => state.themeReducer)
    const [isInitiating, setIsInitiating] = useState(false)
    const [btnText, setBtnText] = useState('Send Code')
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const handleSubmit = async () => {
        setIsInitiating(true)

        const requestPayload = {
            email: email,
        }
        ;(await axiosInstance())
            .post(APIEndpoints.auth.me.initiateForgotPassword(), requestPayload)
            .then(response => {
                displayMessage(response.data.msg)
                setBtnText('Verify Code')
                setIsInitiating(false)
            })
            .catch(err => {
                displayError(err, theme)
                setIsInitiating(false)
            })
    }

    return (
        <div
            id='body-2'
            style={
                theme === 'dark'
                    ? {
                          background: `linear-gradient(
to right bottom,
rgb(32, 45, 66),
rgb(22, 33, 49),
rgb(20, 45, 49)`,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
                    : {
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
        >
            <div className='toggleBtnAuth'>
                <ThemeComponent />
            </div>
            <DarkThemeAuth>
                <div
                    className={`main-frame-small ${
                        theme === 'dark' && 'main-frame-dark'
                    }`}
                >
                    <div className='form-wrapper' style={{ width: '100%' }}>
                        <form className='d-flex flex-column align-items-start w-100'>
                            <div
                                className='desktop mt-5'
                                style={{ width: '100px', height: '100px' }}
                            >
                                <img
                                    src={SendEmail}
                                    alt=''
                                    className='w-100 h-100,'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-5'>
                                <span
                                    className={`highlight-heading ${
                                        theme === 'dark' &&
                                        'highlight-heading-dark'
                                    } text-auth-title`}
                                >
                                    nettbox
                                </span>
                            </div>

                            <div className='mb-3 w-100'>
                                <label
                                    htmlFor='username'
                                    className={`form-label form-label-custom highlight-text ${
                                        theme === 'dark' &&
                                        'highlight-text-dark'
                                    }`}
                                >
                                    Email Address
                                </label>
                                <input
                                    type='email'
                                    onChange={e => setEmail(e.target.value)}
                                    className={`form-control form-control-custom mt-3 ${
                                        theme === 'dark' &&
                                        'form-control-custom-dark'
                                    }`}
                                    id='email'
                                    aria-describedby='emailHelp'
                                    placeholder='Your email here...'
                                />
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <button
                                    disabled={isInitiating}
                                    type='submit'
                                    onClick={e => {
                                        e.preventDefault()
                                        if (btnText === 'Verify Code') {
                                            navigate('/verify_code', {
                                                state: email,
                                            })
                                        } else {
                                            handleSubmit()
                                        }
                                    }}
                                    className={`btn btn-custom-orange waves-effect waves-light px-5 ${
                                        theme === 'dark' &&
                                        'btn-custom-orange-dark'
                                    }`}
                                >
                                    {btnText}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </DarkThemeAuth>
        </div>
    )
}

export default ForgotPassword
