import { APIEndpointsHelper } from '../../index'

export class APIEndpointInstanceStream {
    static getAll(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            '/instance/stream/',
            params
        )
    }

    static getAllInternetAvailable(params) {
        return APIEndpointsHelper.parseEndpointForQueryParams(
            '/instance/stream/internet_available/',
            params
        )
    }

    static get(id) {
        return `/instance/stream/${id}/`
    }

    static create() {
        return `/instance/stream/`
    }

    static update(id) {
        return `/instance/stream/${id}/`
    }

    static delete(id) {
        return `/instance/stream/${id}/`
    }

    static getHlsUrl(id) {
        return `/instance/stream/${id}/live_hls_url/`
    }
    static getBackupHlsUrl(id) {
        return `/instance/stream/${id}/archive_hls_url/`
    }
    static clearHlsUrl(id) {
        return `/instance/stream/${id}/clear_live_session/`
    }
}
