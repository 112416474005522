import axiosInstance from "../../../config/axios";
import { fetchProfile } from "../../../config/actions";
import displayError from "../../displayError";
import APIEndpoints from "../../../config/api/endpoints";
import { UserAccountType } from "../../../config/enums/user";
import { deleteFromLocalStorage, saveToLocalStorage } from "../../localStorage";
import { GlobalRoutes } from "../../../routes/GlobalRoutes";
import { ParentIdentities } from "../../../config/enums/auth/relation";

export class AuthUtilsHelper {
  static clearRelationAuth() {
    for (let identifier of ParentIdentities.values()) {
      window.localStorage.setItem(identifier, null);
    }
  }

  static async ifRelationExist(relationKey) {
    return !!(await AuthUtilsHelper.retrieveRelationProfile(relationKey));
  }

  static async retrieveRelationProfile(relationKey) {
    const parentID = window.localStorage.getItem(ParentIdentities[relationKey]);
    if (parentID && Number.parseInt(parentID) >= 1) {
      return await (await axiosInstance())
        .post(APIEndpoints.auth.relation.authenticateRelation(parentID))
        .then((response) => {
          return response.data;
        });
    }
  }

  static async transverseToRelationProfile({
    currentProfile,
    dispatch,
    navigate,
    userID,
  }) {
    (await axiosInstance())
      .post(APIEndpoints.auth.relation.authenticateRelation(userID))
      .then((response) => {
        const { refresh, access } = response.data.token;
        window.localStorage.setItem("access_token", access);
        window.localStorage.setItem("refresh_token", refresh);
        const userAccountType = response.data?.account_type;
        if (userAccountType === UserAccountType.SUB_USER) {
          saveToLocalStorage(
            ParentIdentities.SUB_ADMINISTRATOR,
            currentProfile.id
          );
        } else if (userAccountType === UserAccountType.SUB_ADMINISTRATOR) {
          deleteFromLocalStorage(ParentIdentities.SUB_ADMINISTRATOR);
          if (currentProfile.account_type === UserAccountType.ADMINISTRATOR) {
            saveToLocalStorage(
              ParentIdentities.ADMINISTRATOR,
              currentProfile.id
            );
          }
        } else {
          deleteFromLocalStorage(ParentIdentities.ADMINISTRATOR);
          deleteFromLocalStorage(ParentIdentities.SUB_ADMINISTRATOR);
        }
        dispatch(fetchProfile);
        setTimeout(() => {
          window.location.reload();
        }, 100);
        navigate(GlobalRoutes.pathDefinitions.account.dashboard, {
          replace: true,
        });
      })
      .catch((err) => {
        displayError(err);
      });
  }
}
