import React from "react";

const InfoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        fill='#254478'
        d='M20 .313C9.156.313.313 9.156.313 20S9.156 39.688 20 39.688 39.688 30.844 39.688 20 30.844.313 20 .313m0 36.875c-9.469 0-17.187-7.72-17.187-17.188S10.53 2.813 20 2.813 37.188 10.53 37.188 20 29.468 37.188 20 37.188'
      ></path>
      <path
        fill='#254478'
        d='M21.25 17.031h-2.5v12.5h2.5zM21.25 11.094h-2.5v2.5h2.5z'
      ></path>
    </svg>
  );
};

export default InfoIcon;
