import jwt_decode from "jwt-decode";
import moment from "moment";

export class JWTTokenUtilsHelper {
    static decode(token) {
        const decoded = jwt_decode(token)
        decoded['exp'] && (decoded['exp'] = moment.unix(decoded['exp']))
        decoded['iat'] && (decoded['iat'] = moment.unix(decoded['iat']))
        return decoded;
    }
}