import { deleteUploadedFile, singleFileUpload } from './index'

export const handleFileUploadSingle = async fileData => {
    const uploadResponse = await singleFileUpload({
        fileBuffer: fileData?.to_be_uploaded_buffer,
        purpose: fileData?.purpose,
    })
    if (uploadResponse) {
        fileData?.to_be_deleted_file_id &&
            (await deleteUploadedFile({
                fileID: fileData?.to_be_deleted_file_id,
            }))
    }
    return uploadResponse
}
