import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import styled from 'styled-components'
import Spinner from '../components/Spinner'
import { store } from '../index'
import { Provider } from 'react-redux'

const SpinnerWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const fireSpinnerAlert = ({ title }) => {
    const MySwal = withReactContent(Swal)
    const alertContent = (
        <SpinnerWrapper>
            <Provider store={store}>
                <Spinner size={1.8} />
            </Provider>
        </SpinnerWrapper>
    )

    MySwal.fire({
        title: <p className='alert-title'>{title}</p>,
        html: alertContent,
        showConfirmButton: false,
        closeOnClickOutside: false,
    })
    return MySwal
}

export default fireSpinnerAlert
