import React, { useState } from 'react'
import { createContext } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MainContentContainer } from './global/content/MainContentConainer'
import { NavigationContainer } from './global/navigation/Container'
import { MainContainer } from './MainContainer'
import SideBarLeft from './SidebarLeft'
import SideBarLeftMobile from './SidebarLeftMobile'

export const NavigationContext = createContext({})
const StyledContainer = styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    min-height: 100vh;
    align-items: stretch;
    width: 100vw;
    overflow: auto;
    height: 100vh;
    background: ${props =>
        props.theme === 'dark' ? 'rgb(23, 33, 50)' : 'rgb(243, 243, 243)'};
    @media (max-width: 800px) {
        ${'' /* flex-direction: column; */}
    }
    input {
        color: ${props =>
            props.theme === 'dark' ? 'white' : 'black'} !important;
    }
`

export const MainWrapper = ({ ...props }) => {
    const { theme } = useSelector(state => state.themeReducer)

    const { pageTitle } = props
    const [mobileSubNavEnabled, setMobileSubNavEnabled] = useState(false)

    const [mobileState, setMobileState] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <StyledContainer theme={theme}>
            <NavigationContext.Provider
                value={{
                    mobileSubNavEnabled,
                    setMobileSubNavEnabled,
                }}
            >
                {mobileState && (
                    <SideBarLeftMobile
                        parent={props.parent}
                        isFetching={props.isFetching}
                        profile={props.profile}
                        setMobileState={setMobileState}
                        theme={theme}
                    />
                )}

                <SideBarLeft
                    parent={props.parent}
                    isFetching={props.isFetching}
                    profile={props.profile}
                    theme={theme}
                />

                <MainContainer theme={theme}>
                    <NavigationContainer
                        pageTitle={pageTitle}
                        setMobileState={setMobileState}
                        theme={theme}
                    />
                    <MainContentContainer>
                        {props.children}
                    </MainContentContainer>
                </MainContainer>
            </NavigationContext.Provider>
        </StyledContainer>
    )
}
