export class APIEndpointUserAccountInstanceStatistics {
    static getPersonal() {
        return `/user/account/instance/statistics/personal/`
    }

    static getRelation(id) {
        return `/user/account/instance/statistics/relation/${id}/`
    }
}


export class APIEndpointUserAccountInstanceAssigned {

    static updateAssignedInstance(id) {
        return `/user/account/instance/assigned/${id}/`
    }
}