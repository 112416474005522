import { useContext } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalUserProfileContext } from "../../../../App";
import axiosInstance from "../../../../config/axios";
import displayError from "../../../../helpers/displayError";
import { APIUtils } from "../../../../helpers/utils/api";
import { SelectComponent } from "../SelectComponent";
import { GlobalVideoBackupContext } from "../../../general/features/video_backup/VideoBackup";

export const FilterByUserSiteCamera = ({
  setterCallback,
  liveSearch,
  backup = false,
}) => {
  const { filterData } = useContext(GlobalVideoBackupContext);
  const { theme } = useSelector((state) => state.themeReducer);
  const { profile } = useContext(GlobalUserProfileContext);

  const selectArray = ["SubUser", "Site", "Camera"];
  const [selectedOptions, setSelectedOptions] = useState({
    SubUser: "",
    Site: "",
    Camera: "",
  });

  const [optionData, setOptionData] = useState({
    SubUser: {
      data: [],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
    Site: {
      data: [],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
    Camera: {
      data: [],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
  });

  const fieldMappings = {
    SubUser: {
      label: "sub_user_id",
      value: "sub_user_name",
      count: "count",
    },
    Site: {
      label: "project_id",
      value: "project_name",
      count: "count",
    },
    Camera: {
      label: "stream_id",
      value: "stream_name",
    },
  };

  const fetchOptions = async (
    optionType,
    parentID = null,
    page = 1,
    scrolling = false
  ) => {
    try {
      backup = false;
      let endpoint = "";
      let pagination = `limit=${100}&offset=${(page - 1) * 100}`;
      if (optionType === "SubUser") {
        endpoint = `/instance/stream/filter/`;
      } else {
        if (optionType === "Site") {
          let parentIDs;
          if (!parentID || parentID?.length <= 0) {
            // Extract the labels (IDs) from parentID array
            parentIDs = [profile.id];
          } else {
            parentIDs = parentID.map((item) => item.label);
          }

          let queryParams = APIUtils.formatQueryFields(parentIDs, "subUser");
          if (backup) {
            endpoint = `/instance/stream/filter?${queryParams}&is_backup_enabled=true`;
          } else {
            endpoint = `/instance/stream/filter?${queryParams}`;
          }
        } else if (optionType === "Camera") {
          if (!parentID || parentID?.length <= 0) {
            return;
          } else {
            const parentIDs = parentID.map((item) => item.label);
            let SubUserIDs;
            if (
              !selectedOptions.SubUser ||
              selectedOptions.SubUser?.length <= 0
            ) {
              // Extract the labels (IDs) from parentID array
              SubUserIDs = [profile.id];
            } else {
              SubUserIDs = selectedOptions.SubUser.map((item) => item.label);
            }

            let queryParams = APIUtils.formatQueryFields(SubUserIDs, "subUser");
            let queryParamsSon = APIUtils.formatQueryFields(
              parentIDs,
              "projects"
            );
            if (backup) {
              endpoint = `/instance/stream/filter?${queryParams}&${queryParamsSon}&is_backup_enabled=true`;
            } else {
              endpoint = `/instance/stream/filter?${queryParams}&${queryParamsSon}`;
            }
          }
        }
      }

      let response;

      await (await axiosInstance()).get(endpoint).then((resp) => {
        response = resp;
      });

      if (
        (response.data.length <= 0 ||
          response.data.every((item) => item.count === 0)) &&
        optionType === "SubUser"
      ) {
        fetchOptions("Site");
      }

      const mappedData = response.data
        .filter(
          (item) =>
            item[fieldMappings[optionType].label] !== null &&
            item[fieldMappings[optionType].label] !== ""
        )
        .map((item) => ({
          label: item[fieldMappings[optionType].label],
          value: item[fieldMappings[optionType].value],
          count: item[fieldMappings[optionType].count],
        }));

      // console.log('before update', optionData)

      // console.log('Mapped Data', mappedData)

      // Check for duplicates and filter them out
      const uniqueData = mappedData.filter((newItem) => {
        return !optionData[optionType].data.some(
          (existingItem) => existingItem.label === newItem.label
        );
      });
      // console.log('Unique Data', uniqueData)

      // console.log(response.data)
      // console.log(response)
      let updatedOptionData;
      if (scrolling) {
        // Create a new object with updated data
        updatedOptionData = {
          ...optionData,
          [optionType]: {
            data: [...optionData[optionType].data, ...uniqueData],
            currentPage: page,
            hasMore: response.extraData.next !== null,
            isLoading: false,
          },
        };
      } else {
        // Create a new object with updated data
        updatedOptionData = {
          ...optionData,
          [optionType]: {
            data: mappedData,
            currentPage: page,
            hasMore: response.extraData.next !== null,
            isLoading: false,
          },
        };
      }

      setOptionData(updatedOptionData);
    } catch (err) {
      // console.log(err)
      displayError(err, theme);
    }
  };

  const handleOptionChange = (optionType, value) => {
    if (optionType === "SubUser") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        SubUser: value,
      }));

      const updatedValue = {
        ...optionData,
        Site: {
          data: [],
          currentPage: 1,
          hasMore: false,
          isLoading: value && value.length > 0,
        },
        Camera: {
          data: [],
          currentPage: 1,
          hasMore: false,
          isLoading: false,
        },
      };
      setOptionData(updatedValue);
      fetchOptions("Site", value);
    }
    if (optionType === "Site") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Site: value,
        Camera: value === null || value?.length <= 0 ? [] : prevData.Camera,
      }));
      const updatedValue = {
        ...optionData,
        Camera: {
          data: [],
          currentPage: 1,
          hasMore: false,
          isLoading: value && value.length > 0,
        },
      };
      setOptionData(updatedValue);
      fetchOptions("Camera", value);
    }
    if (optionType === "Camera") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Camera: value,
      }));
    }
  };

  useEffect(() => {
    if (liveSearch) {
      setterCallback(selectedOptions);
    }
  }, [selectedOptions]);

  const handleSearchParam = () => {
    setterCallback(selectedOptions);
  };

  const handleScroll = (optionType) => {
    if (optionData[optionType].hasMore) {
      if (optionType === "SubUser") {
        fetchOptions(optionType, optionData[optionType].currentPage + 1, true);
      } else if (optionType === "Site") {
        fetchOptions(
          optionType,
          selectedOptions.SubUser,
          optionData[optionType].currentPage + 1,
          true
        );
      } else if (optionType === "Camera") {
        fetchOptions(
          optionType,
          selectedOptions.Site,
          optionData[optionType].currentPage + 1,
          true
        );
      }
    }
  };

  useEffect(() => {
    fetchOptions("SubUser");
  }, []);

  useEffect(() => {
    setSelectedOptions((prevData) => ({
      ...prevData,
      Camera: [],
    }));
  }, [filterData?.from_time, filterData?.to_time]);

  return (
    <div className="SearchContainer">
      {selectArray?.map((optionType, index) => (
        <SelectComponent
          key={index}
          optionType={optionType}
          selectedOptions={selectedOptions}
          optionData={optionData}
          handleOptionChange={handleOptionChange}
          handleScroll={handleScroll}
        />
      ))}
      {!liveSearch && (
        <button
          className={`orange-text add-camera_btn ${
            theme === "dark" && "add-camera_btn-dark"
          }`}
          onClick={() => handleSearchParam()}
        >
          Show Results
        </button>
      )}
    </div>
  );
};
